angular.module('app').component('leftRaceMenu', {
    templateUrl: 'templates/_components/navLeftRaceComponent.html',
    controllerAs: 'ctrl',
    controller: [
        '$timeout', 'DataStoreSvc', 'UtilitySvc',
        function RaceMenuController($timeout, DataStoreSvc, UtilitySvc) {
            var ctrl = this;
            let localData = {
                oneAtATime: true
            };
            ctrl.pageData = localData;
            ctrl.session = DataStoreSvc;

            ctrl.$onInit = function () {
                ctrl.editNextLevel = (ctrl.session.user.role === 'Master' || ctrl.session.user.role === 'Marshal');
                ctrl.closeAll();
                ctrl.session.raceMenu.isRaceDetailsOpen = true;
            };

            ctrl.scrollToTop = function () {
                $timeout(function () {
                    UtilitySvc.scrollToTop(50);
                });
            };

            ctrl.closeAll = function () {
                ctrl.session.raceMenu.isRaceDetailsOpen = false;
                ctrl.session.raceMenu.isStartupEcoOpen = false;
                ctrl.session.raceMenu.isTreasuryOpen = false;
                ctrl.session.raceMenu.isAdmiraltyOpen = false;
                ctrl.session.raceMenu.isMessageCenterOpen = false;
                ctrl.session.raceMenu.isRaceRelationsOpen = false;
                ctrl.session.raceMenu.isTutorialsOpen = false;
            };

            ctrl.handleChevron = function (groupName) {
                let state = !ctrl.session.raceMenu[groupName];
                ctrl.closeAll();
                ctrl.session.raceMenu[groupName] = state;
            };
        }
    ]
});
