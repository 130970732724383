angular.module('app').controller('TutorialCtrl', [
    'DataStoreSvc',
    function (DataStoreSvc) {
        'use strict';

        var tc = this;
        var localData = {};
        tc.pageData = localData;
        tc.session = DataStoreSvc;

        var init = function () {
            console.log('TutorialCtrl (tc): ', tc);
        };

        init();
    }
]);
