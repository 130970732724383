angular.module('app').controller('TroopEditCtrl', ['$ismModalInstance', 'pcfTransaction', 'pcfaTransaction', 'pcfCost', 'maxPcf', 'populationsAvailable', 'bias', 'informationPopulation',
    function ($ismModalInstance, pcfTransaction, pcfaTransaction, pcfCost, maxPcf, populationsAvailable, bias, informationPopulation) {
        var tec = this;
        tec.bodyId = pcfTransaction.bodyId || pcfaTransaction.bodyId;
        tec.pcfTransaction = pcfTransaction;
        tec.pcfaTransaction = pcfaTransaction;
        tec.pcfCost = pcfCost;
        tec.pcfaCost = pcfCost * 1.25;
        tec.maxPcf = maxPcf;
        tec.bias = bias;
        tec.informationPopulation = informationPopulation;
        tec.populations = populationsAvailable;
        tec.popHash = populationsAvailable.reduce( (hash, pop) => (hash[pop.planetId] = pop, hash), {} );

        if (tec.maxPcf === 0 && tec.bodyId) {
            tec.maxPcf = (tec.popHash[tec.planetId] || {}).pu || 999;
        }

        tec.calcMaxPcf = function(bodyId) {
            tec.maxPcf = (tec.popHash[bodyId] || {}).pu || 999;
        };

        tec.calculateTotal = function() {
            tec.total = 0;
            tec.pcfTransaction.amount = 0;
            if (tec.pcfTransaction.quantity >= 0) {
                tec.pcfTransaction.amount = tec.pcfTransaction.quantity * tec.pcfCost;
                tec.total = tec.pcfTransaction.amount;
            }
            tec.pcfaTransaction.amount = 0;
            if (tec.pcfaTransaction.quantity >= 0) {
                tec.pcfaTransaction.amount = tec.pcfaTransaction.quantity * tec.pcfaCost;
                tec.total = tec.total + tec.pcfaTransaction.amount;
            }
        };

        tec.ok = function () {
            if (tec.bodyId) {
                tec.pcfTransaction.bodyId = tec.bodyId;
                tec.pcfaTransaction.bodyId = tec.bodyId;
            }
            $ismModalInstance.close({
                pcf: tec.pcfTransaction,
                pcfa: tec.pcfaTransaction
            });
        };
    
        tec.cancel = function () {
            $ismModalInstance.dismiss('cancel');
        };

        console.log('TroopEditCtrl: ', tec);
    }
]);
