angular.module('app').component('svgComponent', {
    templateUrl: ['$element', '$attrs', function ($element, $attrs) {
        let svgFile = $attrs.fileName || 'svgGear';
        return 'templates/_components/' + svgFile + '.html';
    }],
    bindings: {
        classString: '<'
    },
    controllerAs: 'ctrl',
    controller: function () {
        var ctrl = this;
    }
});