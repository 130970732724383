/* globals angular localStorage */
angular.module('app').controller('PopulationCtrl', [
    '$scope', 'DataStoreSvc', 'EconomySvc', 'InformationPopulationSvc', 'InformationPlanetSvc', '$ismModal',
    function ($scope, DataStoreSvc, EconomySvc, InformationPopulationSvc, InformationPlanetSvc, $ismModal) {
        'use strict';

        var pc = this;
        var localData = {
            startUpTitle: 'Population'
        };
        pc.pageData = localData;
        pc.session = DataStoreSvc;

        var init = function () {
            localData.race = DataStoreSvc.getRace();
            localData.readOnly = localData.race.turnActivated <= localData.race.readyForEconomicsTurn;

            let planetInfoPromise = InformationPlanetSvc.getInformationPlanetsForRaceAndStarSystem(localData.race._id, localData.race.starSystemId);

            let piPromise = InformationPopulationSvc.getInformationPopulationForRaceAndTurn(localData.race._id, localData.race.turnCreated).then(
                function (infos) {
                    localData.infoPops = infos;
                    localData.infoPops.forEach( function (infoPop) {
                        infoPop.pu = EconomySvc.ptuToPu(infoPop.ptu);
                    });
                    return localData.infoPops;
                }
            );

            let tPromise = EconomySvc.getTransactionData(localData.race._id, localData.race.turnActivated).then(
                function (transactions) {
                    let transactionsById = transactions[localData.startUpTitle];
                    localData.total = transactions[localData.startUpTitle + 'Total'] || { amount: 0 };;
                    localData.transactions = {};
                    for (const id in transactionsById) {
                        let transaction = transactionsById[id];
                        localData.transactions[transaction.bodyId] = transaction;
                    }
                    return localData.transactions;
                }
            );

            Promise.all([planetInfoPromise, piPromise, tPromise]).then(
                function(results) {
                    let infoPlanets = results[0];
                    let infoPopulations = results[1];
                    let transactions = results[2];

                    let planetDataHash = {};

                    let planetHash = infoPlanets.reduce(
                        function (hash, ip) {
                            hash[ip.bodyId] = ip;
                            return hash;
                        }, {}
                    );

                    let populationHash = infoPopulations.reduce(
                        function (hash, ip) {
                            hash[ip.planetId] = ip;
                            return hash;
                        }, {}
                    );

                    // match data up
                    for (const id in planetHash) {
                        let plHash = planetHash[id];
                        let popHash = populationHash[id];
                        let tHash = transactions[id];
                        if (popHash) {
                            Object.assign(plHash, popHash);
                            delete populationHash[id];
                        }
                        else {
                            let hd = plHash.habitDifference;
                            plHash.multiplier = (hd <= 10) ? 1 :
                                (hd <= 12) ?  0.7 : 0.6;
                            plHash.maxPu = (hd <= 2)  ? 3200 :
                                (hd < 10)  ?  800 :
                                (hd <= 10) ?  150 :
                                (hd <= 12) ?   50 :
                                (hd <= 18) ?   16 : 0;
                            if (plHash.locator.indexOf('(A)')>-1) {
                                let populationInfo = populationHash[id];
                                if (populationInfo) {
                                    plHash.maxPu = populationInfo.maxPu;
                                }
                                else if (plHash.radius) {
                                    let hexes = Math.ceil(plHash.radius / 12) * 6;
                                    // population centers allowed ===  5 per hex === 5 * hexes
                                    let popCenters = 5 * hexes;
                                    // max ptu per <population center> === 16 ptu (outpost)
                                    plHash.maxPu = popCenters * 16;
                                }
                                else {
                                    // get planet radius
                                }
                            }
                        }
                        if (tHash) {
                            Object.assign(plHash, {
                                quantity: tHash.quantity,
                                amount: tHash.amount
                            });
                            delete tHash[id];
                        }
                        planetDataHash[id] = plHash;
                    }

                    localData.planetHash = planetDataHash;
                    localData.bodyData = Object.values(planetHash);

                    $scope.$apply();
                }
            )
        };

        pc.editTransaction = function (bodyData, transaction) {
            localData.selectedBodyData = bodyData;
            if (transaction == null) {
                localData.transactionToEdit = {
                    campaignId: pc.session.getCampaign()._id,
                    raceId: pc.session.getRace()._id,
                    type: localData.startUpTitle,
                    turn: localData.race.turnActivated,
                    bodyId: bodyData.bodyId,
                    quantity: 0,
                    amount: 0
                };
            }
            else {    
                localData.transactionToEdit = angular.copy(transaction);
            }
            pc.open();
        };

        pc.deleteTransaction = function (transaction) {
            delete localData.transactions[transaction.bodyId];
            localData.planetHash[transaction.bodyId].quantity = 0;
            localData.planetHash[transaction.bodyId].amount = 0;
            localData.bodyData = Object.values(localData.planetHash);
            return EconomySvc.deleteTransaction(transaction);
        };

        pc.saveTransaction = function (transaction) {
            if (transaction.amount === 0) {
                pc.deleteTransaction(transaction);
            }
            else {
                EconomySvc.saveTransaction(transaction).then( pc.processSavedTransaction );
            }
        };

        pc.processSavedTransaction = function (transaction) {
            localData.transactions[transaction.bodyId] = transaction;
            let planetData = localData.planetHash[transaction.bodyId];
            planetData.quantity = transaction.quantity;
            planetData.amount = transaction.amount;
            localData.planetHash[transaction.bodyId] = planetData;
            localData.bodyData = Object.values(localData.planetHash);
        };

        localData.animationsEnabled = true;
        
        pc.open = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.startUpPopulation ' + parentSelector)) : undefined;

            var modalInstance = $ismModal.open({
                animation: localData.animationsEnabled,
                templateUrl: 'templates/suPopulationEdit.html',
                controller: 'PopulationEditCtrl',
                controllerAs: 'pec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    bodyData: function () {
                        return localData.selectedBodyData;
                    },
                    transaction: function () {
                        return localData.transactionToEdit;
                    },
                    cost: function () {
                        let cost;
                        let hd = localData.selectedBodyData.habitDifference;
                        let mult = (hd <= 2) ? 3 :
                            (hd < 10) ? 4 :
                            (hd === 10) ? 5 :
                            (hd <= 12) ? 6 : 7;
                        cost = mult * 5;
                        return cost;
                    }
                }
            });

            modalInstance.result.then(
                function (transaction) {
                    pc.saveTransaction(transaction);
                }
            ).catch(
                function (err) {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        init();
    }
]).controller('PopulationEditCtrl', function ($ismModalInstance, bodyData, transaction, cost) {
    var pec = this;
    pec.bodyData = bodyData;
    pec.transaction = transaction;
    pec.cost = cost;
    pec.maxPtu = pec.bodyData.maxPu - pec.bodyData.pu;

    pec.calculateTotal = function() {
        if (pec.transaction.quantity >= 0) {
            pec.transaction.amount = pec.transaction.quantity * pec.cost;
        }
    };

    pec.ok = function () {
      $ismModalInstance.close(pec.transaction);
    };
  
    pec.cancel = function () {
      $ismModalInstance.dismiss('cancel');
    };
});
