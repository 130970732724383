angular.module('app').controller('EncounterResolutionCtrl', [
    '$timeout', '$location', 'DataStoreSvc', 'EncounterSvc', 'EncounterStatusSvc', 'CShipSvc',
    function ($timeout, $location, DataStoreSvc, EncounterSvc, EncounterStatusSvc, CShipSvc) {
        'use strict';

        var erc = this;
        var localData = {
            loaded: 'starting...',
            reids: [],
            rids: [],
            shipsToSave: new Set(),
            shipsByCounterId: {}
        };

        erc.pageData = localData;
        erc.session = DataStoreSvc;

        var init = function () {
            // the encounter has raceEncounters and their counters are prepopulated
            localData.encounter = erc.session.selectedEncounter;

            // need to get the counters ships
            let cShipPromises = [];
            // let racePromises = [];
            for (let i = 0; i < localData.encounter.raceEncounters.length; i++) {
                let reid = localData.encounter.raceEncounters[i]._id;
                localData.reids.push(localData.encounter.raceEncounters[i]._id);
                // localData.rids.push(localData.encounter.raceEncounters[i].raceId);
                cShipPromises.push( CShipSvc.getShipsForRaceEncounterId(reid) );
            }

            let p1 = Promise.all(cShipPromises).then(
                function (shipArrays) {
                    let returnedShips = _.flattenDeep(shipArrays);
                    for (const ship of returnedShips) {
                        let ships = localData.shipsByCounterId[ship.counterId] || [];
                        ships.push(ship);
                        localData.shipsByCounterId[ship.counterId] = ships;
                    }
                    return 'Done!';
                }
            );

            let p2 = EncounterStatusSvc.getEncounterStatusForEncounterId(localData.encounter._id).then(
                function (encounterStatus) {
                    if (encounterStatus) {
                        localData.encounterStatus = encounterStatus;
                        localData.raceEncounterStatusByReid = {};
                        for (const res of encounterStatus.raceEncounterStatus) {
                            localData.raceEncounterStatusByReid[res.raceEncounterId] = res;
                        }
                    }
                    return 'Done!';
                }
            );

            return Promise.all([p1, p2]).then(
                function (results) {
                    return 'Done!';
                }
            );
        };

        erc.destroyShip = function (shipId) {
            // ship.destroyed = true;
            // document.activeElement.blur();
            localData.shipsToSave.add(shipId);
        };

        erc.destroyShipsForCounterId = function (counter) {
            counter.turnDeactivated = localData.encounterStatus.encounterTurn;
            let ships = localData.shipsByCounterId[counter._id];
            for (const ship of ships) {
                if (!ship.destroyed) {
                    ship.destroyed = true;
                    localData.shipsToSave.add(ship._id);
                }
            }
        };

        erc.saveDestroyedShips = function () {
            CShipSvc.destroyShips([...localData.shipsToSave]).then(
                function (result) {
                    localData.shipsToSave = new Set();
                    erc.reForm.$setPristine();
                    return result;
                }
            )
            .catch(
                function (error) {
                    console.log('error: ', error);
                }
            );
        };

        erc.terminate = function () {
            EncounterSvc.terminateEncounter(localData.encounter).then(
                function (result) {
                    $location.path('/prelimEM');
                }
            )
            .catch(
                function (error) {
                    console.log('error: ', error);
                }
            );
        };

        init().then(
            function (result) {
                $timeout(
                    function () {
                        localData.loaded = result;
                        console.log('erc:', erc);
                    }        
                );
            }
        );
    }
]);
