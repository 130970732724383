angular.module('app').component('icon', {
    template: '<div><img width={{$ctrl.width}} ng-src="/images/_icons/{{$ctrl.imageName}}"/></div>',
    bindings: {
        imageName: '@',
        width: '<'
    },
    controller: function IconController() {
        var ctrl = this;

    }
});