/* global angular localStorage */
angular.module('app').service('InformationPlanetSvc', function ($http, $location) {
    'use strict';

    var localData = {};
    var svc = this;

    svc.getAllInformationPlanetForRace = function (raceId) {
        return $http.get('/api/informationPlanet/raceId/' + raceId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getInformationPlanetsForRaceAndStarSystem = function (raceId, starSystemId) {
        return $http.get('/api/informationPlanet/raceId/' + raceId + '/starSystemId/' + starSystemId).then(
            function (response) {
                return response.data;
            }
        );
    };
});
