angular.module('app').component('racePageTitle', {
  templateUrl: 'templates/_components/racePageTitle.html',
  bindings: {
    title: '<',
    campaignName: '<',
    raceName: '<'
  },
  controllerAs: 'ctrl',
  controller: ['DataStoreSvc', function (DataStoreSvc) {
    var ctrl = this;
    ctrl.session = DataStoreSvc;
  }]
});
