/* globals angular localStorage */
angular.module('app').controller('StarSystemDisplayCtrl', [
    '$timeout', 'DataStoreSvc', 'StarSystemSvc', 'InformationPopulationSvc',
    function ($timeout, DataStoreSvc, StarSystemSvc, InformationPopulationSvc) {
        'use strict';

        var ssdc = this;
        var localData = {};
        ssdc.starSystemDisplayData = localData;
        ssdc.session = DataStoreSvc;

        let getStarSystemPromise = function (starSystemId) {
            let starSystemPromise = DataStoreSvc.getStarSystem();
            if (starSystemPromise == null) {
                starSystemPromise = StarSystemSvc.getStarSystemAdmin(starSystemId);
            }
            starSystemPromise.then( function (results) {
                $timeout( function () {
                    ssdc.assignStarSystem(results);
                    DataStoreSvc.setStarSystem(null);
                });
                return "Done";
            });
            return starSystemPromise;
        };

        let getPopulationPromise = function (starSystemId) {
            let populationPromise = InformationPopulationSvc.getAllInformationPopulationForStarSystemId(starSystemId);
            return populationPromise.then(function (populationData) {
                let populations = {};
                for (const population of populationData) {
                    let popArray = populations[population.planetId] || [];
                    popArray.push(population);
                    populations[population.planetId] = popArray;
                }
                $timeout(function () {
                    localData.populations = populations;
                });
                return "Done";
            })
        };

        let getRacePromise = function () {
            let races = JSON.parse(localStorage.getItem('races'));
            let racesPromise = (races) ? Promise.resolve(races) : RaceSvc.getAllRacesForCampaign(ssdc.session.campaign._id);
            racesPromise.then( function (races) {
                localData.raceHash = races.reduce(function(dictionary, race) {
                    dictionary[race._id] = race;
                    return dictionary;
                }, {});
            })
        };

        var init = function () {
            localData.campaign = DataStoreSvc.getCampaign();
            ssdc.changeStarSystemId(DataStoreSvc.getStarSystemId());
        };

        ssdc.changeStarSystemId = function (starSystemId) {
            localData.starSystemId = starSystemId;
            DataStoreSvc.setStarSystemId(starSystemId);
            let starSystemPromise = getStarSystemPromise(starSystemId);
            let populationPromise = getPopulationPromise(starSystemId);
            let racePromise = getRacePromise();
            Promise.all([starSystemPromise, populationPromise, racePromise]).then(
                function (results) {
                    $timeout(function () {
                        console.log('controller: ssdc: ', ssdc);
                    });
                }
            );
        };

        ssdc.assignStarSystem = function (results) {
            localData.starSystem = results;
            localData.starSystemId = results._id;
        };

        init();
    }
]);
