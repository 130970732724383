/* globals angular localStorage */ 
angular.module('app').controller('IndustrialExpansionCtrl', [
    '$timeout', '$ismModal', 'DataStoreSvc', 'StarSystemSvc', 'InformationPopulationSvc', 'InformationPlanetSvc', 'EconomySvc',
    function ($timeout, $ismModal, DataStoreSvc, StarSystemSvc, InformationPopulationSvc, InformationPlanetSvc, EconomySvc) {
        'use strict';

        var iec = this;
        var localData = {
            total: 0
        };
        iec.pageData = localData;
        iec.session = DataStoreSvc;

        var init = function () {
            localData.editNextLevel = iec.session.user.role === 'Master' || iec.session.user.role === 'Marshal';
            localData.race = iec.session.getRace();

            // get all known systems (with populations?)
            let p1 = StarSystemSvc.getStarSystemsForRace(localData.race._id).then(
                function (results) {
                    localData.starSystems = results;
                }
            );

            // get all informationPopulation
            let p2 = InformationPopulationSvc.getAllInformationPopulationForRace(localData.race._id).then(
                function (infoPops) {
                    localData.myPopulationInfo = infoPops;
                    localData.populationInformationMap = infoPops.reduce(function(ipMap, data) {
                        ipMap[data.planetId] = data;
                        return ipMap;
                    }, {});
                }
            );

            // get all informationPlanet
            let p3 = InformationPlanetSvc.getAllInformationPlanetForRace(localData.race._id).then(
                function (infoPops) {
                    localData.surveyedBodyInfo = infoPops;
                    localData.surveyedBodyInfoMap = infoPops.reduce( function (map, data) {
                        map[data.bodyId] = data;
                        return map;
                    }, {});
                    localData.systemsSet = new Set(
                        infoPops.map( info => info.starSystemId )
                    );
                }
            );

            let p4 = EconomySvc.getTransactionData(localData.race._id, iec.session.editTurn).then(
                function (transactions) {
                    localData.transactions = transactions;
                    localData.popIncomeTotal = (transactions['popIncomeTotal'] || {}).amount || 0;
                    localData.incomeTotal = 
                        localData.popIncomeTotal +
                        ((transactions['tradeIncomeTotal'] || {}).amount || 0) +
                        ((transactions['leaseIncomeTotal'] || {}).amount || 0) +
                        ((transactions['shipSaleTotal'] || {}).amount || 0) +
                        ((transactions['otherIncomeTotal'] || {}).amount || 0);
                    localData.expensesTotal = 
                        ((transactions['maintenanceTotal'] || {}).amount || 0) +
                        ((transactions['constructionTotal'] || {}).amount || 0) +
                        // ((transactions['industrialTotal'] || {}).amount || 0) +
                        ((transactions['colonizationTotal'] || {}).amount || 0) +
                        ((transactions['techLevelTotal'] || {}).amount || 0) +
                        ((transactions['techItemTotal'] || {}).amount || 0) +
                        ((transactions['otherExpenseTotal'] || {}).amount || 0);
                }
            );

            Promise.all( [ p1, p2, p3, p4 ] ).then(
                function () {
                    iec.setSelectedStarSystemId(localData.race.starSystemId);

                    console.log('ColonizationExpenseCtrl: ', iec);
                    $timeout();
                }
            );
        };

        iec.setSelectedStarSystemId = function (starSystemId) {
            localData.selectedStarSystemId = starSystemId;
            localData.selectedStarSystem = localData.starSystems.find( ss => ss._id === localData.selectedStarSystemId );
            localData.ssPopulationInfo = localData.myPopulationInfo.filter(
                infoPop => (infoPop.starSystemId === localData.selectedStarSystemId)
            );
            localData.starSystemsRestricted = localData.starSystems.filter( system => localData.systemsSet.has(system._id) );

            resetSummaryData();
            let ieData = Object.values(localData.transactions['industrial']||{});
            ieData.forEach( data => iec.processSavedData(data));

            $timeout();
        };

        let getEconomicsBase = function() {
            var newColonization = {
                campaignId: DataStoreSvc.getCampaign()._id,
                raceId: DataStoreSvc.getRace()._id,
                turn: iec.session.editTurn,
                type: 'industrial',
                quantity: 0,        // PTU (placements are positive, extraction is negative)
                amount: 0,          // actual cost (multi-month, if needed)
                locator: ''         // destination (or source)
            };
            return newColonization;
        };

        iec.createIndustrialUnit = function () {
            localData.industrialUnitToEdit = getEconomicsBase();
            localData.industrialUnitToEdit.description = localData.selectedStarSystem._id;
            iec.open();
        };

        iec.editIndustrialUnit = function (industrialUnit) {
            localData.industrialUnitToEdit = angular.copy(industrialUnit);
            iec.open();
        };

        iec.deleteIndustrialUnit = function (industrialUnit) {
            let industrialUnitTransactions = localData.transactions['industrial'] || {};
            delete industrialUnitTransactions[industrialUnit._id];
            localData.transactions['industrial'] = industrialUnitTransactions;

            delete localData.localSystemSold[industrialUnit._id];
            delete localData.localSystem[industrialUnit._id];

            localData.localTotal = localData.localTotal - industrialUnit.amount;
            localData.total = localData.total - industrialUnit.amount;

            return EconomySvc.deleteTransaction(industrialUnit);
        };

        let resetSummaryData = function () {
            localData.localSystem = {};
            localData.localSystemSold = {};
            localData.otherSystems = {};
            localData.otherSystemsSold = {};
            localData.transactions['processed'] = {};
        };

        iec.processSavedData = function (savedData) {
            let industrialTransactions = localData.transactions['processed'] || {};
            let originalTransaction = industrialTransactions[savedData._id];
            industrialTransactions[savedData._id] = savedData;
            localData.transactions['processed'] = industrialTransactions;

            if (originalTransaction) {
                localData.total = localData.total - originalTransaction.amount;
            }

            localData.total = localData.total + savedData.amount;

            if (savedData.description === localData.selectedStarSystemId) {
                if (originalTransaction) {
                    localData.localTotal = localData.localTotal - originalTransaction.amount;
                }
                localData.localTotal = localData.localTotal + savedData.quantity;

                if (savedData.quantity < 0) {
                    localData.localSystemSold[savedData._id] = savedData;
                }
                else {
                    localData.localSystem[savedData._id] = savedData;
                }
            }
            else {
                if (savedData.quantity < 0) {
                    localData.otherSystemsSold[savedData._id] = savedData;
                }
                else {
                    localData.otherSystems[savedData._id] = savedData;
                }
            }
            $timeout();
        };

        iec.animationsEnabled = true;

        iec.open = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.industrialExpansion ' + parentSelector)) : undefined;

            var modalInstance = $ismModal.open({
                animation: iec.animationsEnabled,
                templateUrl: 'templates/industrialExpansionEdit.html',
                controller: 'IndustrialUnitEdit',
                controllerAs: 'iuec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    industrialUnitToEdit: function () {
                        return localData.industrialUnitToEdit;
                    },
                    starSystems: function () {
                        return localData.starSystemsRestricted;
                    },
                    surveyedBodies: function () {
                        return localData.surveyedBodyInfo;
                    },
                    populationInformationMap: function () {
                        return localData.populationInformationMap;
                    }
                }
            });

            modalInstance.result.then(
                function (industrialUnit) {
                    return EconomySvc.saveTransaction(industrialUnit);
                }
            ).then(
                function( savedIndustrialUnit ) {
                    iec.processSavedData( savedIndustrialUnit );
                }
            ).catch(
                function () {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        init();
    }
]).controller('IndustrialUnitEdit', function ($ismModalInstance, industrialUnitToEdit, starSystems, surveyedBodies, populationInformationMap) {
    var iuec = this;

    var init = function() {
        iuec.industrialUnitToEdit = industrialUnitToEdit;

        // Star Systems with InformationPlanet records
        iuec.starSystems = starSystems;

        // Bodies with InformationPlanet records
        iuec.surveyedBodies = surveyedBodies;

        // InformationPopulation records
        iuec.populationInformationMap = populationInformationMap;

        if (iuec.industrialUnitToEdit.bodyId) {
            iuec.selectedBody = iuec.surveyedBodies.find( body => body.bodyId === iuec.industrialUnitToEdit.bodyId );
            iuec.selectedStarSystemId = iuec.selectedBody.starSystemId;
            iuec.setSelectedBodyId();
        }

        iuec.setStarSystemId();
    };

    iuec.setStarSystemId = function() {
        iuec.selectedSurveyedBodies = iuec.surveyedBodies.filter(
            function (body) {
                let include = body.starSystemId === iuec.selectedStarSystemId;
                return include;
            }
        );
    };

    iuec.setSelectedBodyId = function() {
        iuec.industrialUnitToEdit.locator = iuec.selectedBody.locator;
        iuec.selectedPopInfo = iuec.populationInformationMap[iuec.selectedBody.bodyId];
        iuec.updateQuantity();
    };

    iuec.updateQuantity = function() {
        if (iuec.selectedBody) {
            iuec.updateIndustrialUnit();
        }
    };

    iuec.updateIndustrialUnit = function() {
        iuec.industrialUnitToEdit.description = iuec.selectedStarSystemId;
        iuec.industrialUnitToEdit.bodyId = iuec.selectedBody.bodyId;
        // destination, if established
        iuec.industrialUnitToEdit.informationPopulationId = iuec.populationInformationMap[iuec.selectedBody.bodyId];
        iuec.industrialUnitToEdit.amount = iuec.industrialUnitToEdit.quantity * 30;
    };

    iuec.ok = function () {
        iuec.updateIndustrialUnit();
        $ismModalInstance.close(iuec.industrialUnitToEdit);
    };

    iuec.cancel = function () {
        $ismModalInstance.dismiss('cancel');
    };

    init();
});
