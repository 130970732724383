/* global angular localStorage */
angular.module('app').service('InformationPopulationSvc', function ($http, $location) {
    'use strict';

    var localData = {};
    var svc = this;

    svc.getAllInformationPopulationForRace = function (raceId) {
        return $http.get('/api/informationPopulation/raceId/' + raceId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getInformationPopulationForRaceAndTurn = function (raceId, turn) {
        return $http.get('/api/informationPopulation/raceId/' + raceId + '/turn/' + turn).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getInformationPopulationForRaceAndStarSystemId = function (raceId, starSystemId) {
        return $http.get('/api/informationPopulation/raceId/' + raceId + '/starSystem/' + starSystemId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getAllInformationPopulationForOtherRaces = function (raceId) {
        return $http.get('/api/informationPopulation/other/raceId/' + raceId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getAllInformationPopulationForOtherRacesAndStarSystemId = function (raceId, starSystemId) {
        return $http.get('/api/informationPopulation/other/raceId/' + raceId + '/starSystem/' + starSystemId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getAllInformationPopulationForStarSystemId = function (starSystemId) {
        return $http.get('/api/informationPopulation/starSystem/'+starSystemId).then(
            function (response) {
                return response.data;
            }
        );
    };
});
