angular.module('app').controller('EncounterTestCtrl', [
    'EncounterSvc', '$q', 'DataStoreSvc',
    function (EncounterSvc, $q, DataStoreSvc) {
        'use strict';

        var etc = this;
        var localData = {
            reids: []
        };

        etc.encounterData = localData;
        etc.session = DataStoreSvc;

        var init = function () {
            localData.encounter = etc.session.selectedEncounter;
            for (let i = 0; i < localData.encounter.raceEncounters.length; i++) {
                let reid = localData.encounter.raceEncounters._id;
                localData.reids.push(reid);
            }
        };

        init();
    }
]);
