/*global angular */
angular.module('app').controller('LoginCtrl', [
    '$location', '$timeout', 'UserSvc', 'DataStoreSvc',
    function ($location, $timeout, UserSvc, DataStoreSvc) {
        'use strict';

        var lc = this;

        lc.login = function (email, password, remember) {
            UserSvc.login(email, password, remember).then(
                function (user) {
                    lc.error = null;
                    $location.path('/');
                }
            )
            .catch(
                function (error) {
                    if (error.status === 401) {
                        lc.error = "User email or password are incorrect";
                    }
                    else {
                        lc.error = "An unexpected error occured; contact admin.";
                    }
                    $timeout( function () {
                        var elmnt = document.getElementById("error");
                        elmnt.scrollIntoView();
                    });
                }
            );
        };
    }
]);
