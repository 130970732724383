angular.module('app').service('WebSocketSvc', [
    '$rootScope', '$location',
    function ($rootScope, $location) {
        'use strict';

        let connection;
        let localData = {
            websocketUrl: ''
        };
        let svc = this;
        
        let init = function () {
            if (!connection) {
                // let host = "wss://" + location.host;
                let websocketUrl = "ws";
                let host = $location.host();
                let port = $location.port();
                let addPort = !port || (port !== 80 && port !== 443);
                let protocol = $location.protocol();
                if (protocol = "https") {
                    websocketUrl += "s://";
                }
                websocketUrl += host;
                if (addPort) {
                    websocketUrl += ":" + port;
                }
                localData.websocketUrl = websocketUrl;
                connection = new WebSocket(websocketUrl);

                connection.onopen = function () {
                    console.log('WebSocket connected');
                };

                connection.onclose = function (event) {
                    console.log('WebSocket closed: %s', JSON.stringify(event));
                    if (true) {
                        connection = new WebSocket(localData.websocketUrl);
                    }
                };

                connection.onmessage = async function (e) {
                    let data = await e.data.text();
                    var payload = JSON.parse(data);
                    console.log('broadcasting:', payload);
                    $rootScope.$broadcast(payload.channel, payload);
                };
            }
        };

        let reconnectIfNeeded = function () {
            if (connection.readyState != 1) {
                connection = new WebSocket(localData.websocketUrl);
            }

        };

        svc.registerUser = function (userId) {
            reconnectIfNeeded();
            if (connection.readyState === 1) {
                svc.sendMessage({
                    channel: 'registerUser',
                    userId: userId
                });
            }
        };

        svc.registerRace = function (raceId) {
            reconnectIfNeeded();
            if (connection.readyState === 1) {
                svc.sendMessage({
                    channel: 'registerRace',
                    raceId: raceId
                });
            }
        };

        svc.sendMessage = function (message) {
            let dataPackage = JSON.stringify(message);
            console.log('sending:', dataPackage);
            reconnectIfNeeded();
            if (connection.readyState === 1) {
                connection.send(dataPackage);
            }
        };

        init();
    }
]);
