/* global angular, localStorage */
angular.module('app').service('StarSystemSvc', function ($http, $location) {
    'use strict';

    var localData = {};
    var svc = this;

    svc.getFullStarSystems = function (campaign) {
        return $http.get('/api/starSystem/full/' + campaign._id).then(
            function (response) {
                let starSystems = response.data;
                for (const starSystem of starSystems) {
                    localStorage.setItem(starSystem._id, JSON.stringify(starSystem));
                }
                return starSystems;
            }
        );
    };

    svc.getAllStarSystems = function (campaign) {
        return $http.get('/api/starSystem/all/' + campaign._id).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getStarSystemsForRace = function (raceId) {
        return $http.get('/api/starSystem/race/' + raceId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getStarSystemsWithFleetsForRace = function (raceId) {
        return $http.get('/api/starSystem/fleet/race/' + raceId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.newStarSystem = function (campaign, race, starSystemNumber, overrides) {
        return $http.post('/api/starSystem', {
            campaign: campaign,
            race: race,
            starSystemNumber: starSystemNumber,
            overrides: overrides
        });
    };

    svc.getStarSystem = function (starSystemId) {
        let starSystemData = localStorage.getItem(starSystemId);
        if (starSystemData) {
            let starSystem = JSON.parse(starSystemData);
            delete starSystem.warpPoints;
            return Promise.resolve(starSystem);
        }

        return $http.get('/api/starSystem/id/' + starSystemId).then(
            function (response) {
                let starSystem = response.data;
                localStorage.setItem(starSystem._id, JSON.stringify(starSystem));
                return starSystem;
            }
        );
    };

    svc.getStarSystemAdmin = function (starSystemId) {
        let starSystemData = localStorage.getItem(starSystemId);
        if (starSystemData) {
            let starSystem = JSON.parse(starSystemData);
            if (starSystem.warpPoints) {
                return Promise.resolve(starSystem);
            }
        }

        return $http.get('/api/starSystem/withWarpPoints/id/' + starSystemId).then(
            function (response) {
                let starSystem = response.data;
                localStorage.setItem(starSystem._id, JSON.stringify(starSystem));
                return starSystem;
            }
        );
    };

    svc.getStarSystemsAndLinks = function (raceId) {
        return $http.get('/api/starSystem/minimumPlusLinks/race/' + raceId).then(
            function (response) {
                return response.data;
            }
        );
    };
});
