/* global angular localStorage */
angular.module('app').service('InformationRaceSvc', [
    '$http',
    function ($http) {
        'use strict';

        var localData = {};
        var svc = this;

        svc.getAllInformationRaceForRace = function (raceId) {
            return $http.get('/api/informationRace/race/' + raceId + '/withRace').then(
                function (response) {
                    let infoRaces = response.data;
                    infoRaces.forEach( infoRace => localData[infoRace.infoForRaceId + infoRace.infoAboutRaceId] = infoRace);
                    return infoRaces;
                }
            );
        };

        svc.getInformationRaceForRaceAndForeignRace = function (raceId, foreignRaceId) {
            let infoRace = localData[raceId + foreignRaceId];
            if (infoRace) {
                return infoRace;
            }
            return $http.get('/api/informationRace/race/' + raceId + '/' + foreignRaceId).then(
                function (response) {
                    let infoRaces = response.data;
                    infoRaces.forEach( infoRace => localData[infoRace.infoForRaceId + infoRace.infoAboutRaceId] = infoRace);
                    return infoRaces;
                }
            );
        };

        svc.getNonHostileRaces = function (raceId) {
            return $http.get('/api/informationRace/race/' + raceId + '/nonHostile').then(
                function (response) {
                    let infoRaces = response.data;
                    infoRaces.forEach( infoRace => localData[infoRace.infoForRaceId + infoRace.infoAboutRaceId] = infoRace);
                    return infoRaces;
                }
            );
        };

        svc.findOrCreateInformationRace = function (campaignId, raceId, otherRaceId, starSystemId, turn) {
            return $http.put('/api/informationRace/race/' + raceId + '/' + otherRaceId, {
                campaignId: campaignId,
                starSystemId: starSystemId,
                turn: turn
            }).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.saveInformationRace = function (informationRace) {
            let raceId = informationRace.infoForRaceId;
            let foreignRaceId = informationRace.infoAboutRaceId;
            localData[raceId + foreignRaceId] = informationRace;
            return $http.put('/api/informationRace/race/' + raceId + '/' + foreignRaceId, informationRace).then(
                function (response) {
                    return response.data;
                }
            );
        };
    }
]);
