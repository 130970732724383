angular.module('app').service('EconomySvc', [
    '$http', '$q', 'DataStoreSvc', 'InformationPopulationSvc',
    function ($http, $q, DataStoreSvc, InformationPopulationSvc) {
        'use strict';

        let svc = this;
        let localData = {
            turnData: {}
        };

        // Populations Units   Populations         Conversion Factor
        // 1-16 PU             1-16 PTU
        // 17-50 PU            17-50 PTU
        // 51-150 PU           51-150 PTU
        // 151-400 PU          153-800 PTU         PU = 2.6 PTU
        // 401-800 PU          818-8,000 PTU       PU = 18 PTU
        // 801-1,600 PU        8,090-80,000 PTU    PU = 90 PTU
        // 1,601-3,200 PU      80,450-800,000 PTU  PU = 450 PTU
        svc.ptuToPu = function (ptu) {
            let pu = 0;
            if (ptu >= 800000) {
                pu = 3200;
            }
            else if (ptu > 80000) {
                pu = 1600 + ((ptu - 80000) / 450);
            }
            else if (ptu > 8000) {
                pu =  800 + ((ptu - 8000) / 90);
            }
            else if (ptu > 800) {
                pu =  400 + ((ptu - 800) / 18);
            }
            else if (ptu > 150) {
                pu =  150 + ((ptu - 150) / 2.6);
            }
            else {
                pu = ptu;
            }
            return pu;
        };

        svc.puToPtu = function (pu) {
            var ptu = 0;
            if (pu >= 3200) {
                ptu = 800000;
            }
            else if (pu > 1600) {
                ptu = 80000 + (pu-1600)*450;
            }
            else if (pu > 800) {
                ptu = 8000 + (pu-800)*90;
            }
            else if (pu > 400) {
                ptu = 800 + (pu-400)*18;
            }
            else if (pu > 150) {
                ptu = 150 + Math.round((pu-150)*2.6);
            }
            else {
                ptu = pu;
            }
            return ptu;
        };

        svc.getOrBuildTransactions = function (race, turn) {
            if (turn <= race.economicsCompleteTurn) {
                return svc.getTransactionData(race._id, turn);
            }
            else {
                return svc.buildTransactionData(race, turn);
            }
        };

        svc.buildTransactionData = function (race, turn) {
            let transactionsPromise = svc.getTransactionData(race._id, turn);
            let incomePromise = getIncomeData(race._id, turn);
            let combinedTransactions = Promise.all([transactionsPromise, incomePromise])
            return combinedTransactions.then( function (response) {
                let hashedData = {};
                let transactions = response[0];
                let data = response[1].data;

                // income may change behind the scenes, this will update saved/processed transactions
                // with new income type data
                if (Array.isArray(data) && data.length > 0) {
                    hashedData = data.reduce( function( transactionHash, transaction ) {
                        return addOrUpdateTransactions(transactionHash, transaction);
                    }, transactions);
                }
                return transactions;
            });
        };

        let getIncomeData = function (raceId, turn) {
            let notCreated = (localData.turnData["incomeForTurn"] || -1) < turn;
            if (notCreated) {
                localData.turnData.incomeForTurn = turn;
                return $http.put('/api/economy/income/raceid/'+raceId+'/turn/'+turn);
            }
            return { data: [] };
        };

        let getCachedTransactions = function (raceId, turn) {
            if (raceId != localData.raceId) {
                localData.raceId = raceId;
                clearTransactionData();
            }
            return localData.turnData[turn];
        };

        let getCachedConstructionTransactions = function(raceId) {
            if (raceId != localData.raceId) {
                localData.raceId = raceId;
                clearTransactionData();
            }
            return localData.constructionHash;
        };

        let clearTransactionData = function () {
            localData.turnData = {};
            localData.constructionHash = {};
        };

        svc.getTransactionData = function (raceId, turn) {
            let transactions = getCachedTransactions(raceId, turn);
            if (transactions && transactions != {}) {
                return $q.when(transactions);
            }
            return $http.get('/api/economy/raceid/'+raceId+'/turn/'+turn).then( processTransactions );
        };

        svc.getSpecificTransactionData = function (raceId, turn, type) {
            let transactions = getCachedTransactions(raceId, turn);
            if (transactions && transactions != {}) {
                return $q.when(transactions);
            }
            return $http.get('/api/economy/raceid/'+raceId+'/turn/'+turn+'/type/'+type).then( processTransactions ).then(
                function (result) {
                    // this data should NOT be cached
                    localData.turnData[turn] = null;
                    return result;
                }
            );
        };

        let processTransactions = function (response) {
            let hashedData = {};
            let data = response.data;
            if (Array.isArray(data) && data.length > 0) {
                let sampleItem = data[0];
                let turn = sampleItem.turn;

                hashedData = data.reduce( function( transactionHash, transaction ) {
                    return addOrUpdateTransactions(transactionHash, transaction);
                }, {});
                localData.turnData[turn] = hashedData;
            }
            return hashedData;
        };

        svc.getConstructionData = function (raceId) {
            let returnVal;
            let transactions = getCachedConstructionTransactions(raceId);
            if (transactions && Object.keys(transactions).length > 0) {
                returnVal = $q.when(transactions);
            }
            else {
                returnVal = $http.get('/api/economy/construction/raceid/'+raceId).then( processConstructionTransactions );
            }
            return returnVal;
        };

        let processConstructionTransactions = function (response) {
            let data = response.data;
            if (Array.isArray(data) && data.length > 0) {
                localData.constructionHash = data.reduce( function( constructionHash, transaction ) {
                    constructionHash[transaction.turn + '-' + transaction.locator + '-' + transaction.sequence] = transaction;
                    return constructionHash;
                }, {});
            }
            return localData.constructionHash;
        };

        let addOrUpdateTransactions = function (transactionHash, transaction) {
            let key = transaction.type;
            if (transaction.locator === "FreeSY") {
                key = "Defenses";
                transactionHash[transaction.locator] = transaction;
            }
            let keyTotal = key + 'Total';

            let groupHash = transactionHash[key] || {};

            // old amount to remove
            let oldAmount = 0;
            if (groupHash[transaction._id] != null) {
                oldAmount = groupHash[transaction._id].amount;
            }
            groupHash[transaction._id] = transaction;
            transactionHash[key] = groupHash;

            let groupTotal = transactionHash[keyTotal] || {};
            // new amount to add
            let total = groupTotal['amount'] || 0;
            total = total - oldAmount + transaction.amount;

            groupTotal['amount'] = total;
            transactionHash[keyTotal] = groupTotal;

            return transactionHash;
        };

        let deleteFromTransactions = function (transactionHash, transaction) {
            let key = transaction.type;
            let keyTotal = transaction.type + 'Total';

            let groupTotal = transactionHash[keyTotal] || { amount: 0 };
            let total = groupTotal['amount'] || 0;
            total = total - transaction.amount;
            groupTotal['amount'] = total;
            transactionHash[keyTotal] = groupTotal;

            let groupHash = transactionHash[key] || {};
            delete groupHash[transaction._id];
            transactionHash[key] = groupHash;

            return transactionHash;
        };

        svc.saveTransaction = function (transaction) {
            let save;
            if (transaction._id == null) {
                save = $http.post('/api/economy', transaction);
            } else {
                save = $http.put('/api/economy/' + transaction._id, transaction);
            }
            return save.then( function (result) {
                var transaction = result.data;
                let transactions = localData.turnData[transaction.turn] || {};
                transactions = addOrUpdateTransactions(transactions, transaction);
                localData.turnData[transaction.turn] = transactions;
                return transaction;
            })
        };

        svc.deleteTransaction = function (transaction) {
            let transactions = localData.turnData[transaction.turn];
            transactions = deleteFromTransactions(transactions, transaction);
            localData.turnData[transaction.turn] = transactions;
            return $http.delete('/api/economy/' + transaction._id);
        };

        svc.rollbackStartup = function (raceId, turn) {
            clearTransactionData();
            return $http.post('/api/economy/rollbackStartup/race/' + raceId + '/turn/' + turn);
        };

        svc.performStartup = function (raceId, turn) {
            clearTransactionData();
            return $http.post('/api/economy/performStartup/race/' + raceId + '/turn/' + turn);
        };

        svc.findNextEconomicLevelResearchForRace = function (raceId) {
            return $http.put('/api/economy/research/' + raceId).then(
                function (result) {
                    return result.data;
                }
            );
        }
    }
]);
