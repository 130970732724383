/* globals angular localStorage */
angular.module('app').controller('OverridesCtrl', [
    '$ismModalInstance', 'TableSvc', 'DataStoreSvc', 'actionName', '$document',
    function ($ismModalInstance, TableSvc, DataStoreSvc, actionName, $document) {
        'use strict';

        var oc = this;
        var localData = {
            categories: {
                'systemTypes': {
                    heading: 'System Types',
                    table: 'systemTypes',
                    low: 1,
                    high: 100
                },
                'starTypes': {
                    heading: 'Star Types',
                    table: 'starTypes',
                    low: 1,
                    high: 100
                },
                'binaryDistances': {
                    heading: 'Binary Distances',
                    table: 'binaryDistances',
                    low: 1,
                    high: 100
                },
                'distance': {
                    heading: 'Added Binary Distance',
                    low: 1,
                    high: 100
                },
                'orbitDetermination': {
                    heading: 'Orbit Determination',
                    low: 1,
                    high: 8
                },
                // 'secondOrbit*': {
                //     heading: '',
                //     table: '',
                //     low: 1,
                //     high: 100
                // },
                'rockyPlanetTypes': {
                    heading: 'Rocky Planet Types',
                    table: 'rockyPlanetTypes',
                    low: 1,
                    high: 100
                },
                'moonCounts': {
                    heading: 'Moon Counts',
                    table: 'moonCounts',
                    low: 1,
                    high: 100
                },
                'warpPointNumbers': {
                    heading: 'Warp Point Numbers',
                    table: 'warpPointNumbers',
                    low: 1,
                    high: 100
                },
                // 'warpPointTypes': {
                //     heading: 'Warp Point Types',
                //     table: 'warpPointTypes',
                //     low: 1,
                //     high: 100
                // },
                'warpPointDistances': {
                    heading: 'Warp Point Distances',
                    table: 'warpPointDistances',
                    low: 1,
                    high: 100
                },
                'civilizationProbabilities': {
                    heading: 'Civilization Probabilities',
                    table: 'civilizationProbabilities',
                    low: 1,
                    high: 100
                },
                'populationSizes': {
                    heading: 'Population Sizes',
                    table: 'populationSizes',
                    low: 1,
                    high: 100
                },
                'systemExploitations': {
                    heading: 'System Exploitations',
                    table: 'systemExploitations',
                    low: 1,
                    high: 100
                },
                'newSystemNumber': {
                    heading: 'New System Number',
                    low: 1,
                    high: 100
                },
                'planetToAsteroid*': {
                    heading: 'Planet To Asteroid',
                    low: 1,
                    high: 100
                },
                'moonTideLockCheck*': {
                    heading: 'Moon Tide-Lock Check',
                    low: 1,
                    high: 100
                },
                'twinPlanetTypeDetermination*': {
                    heading: 'Twin Planet Type Determination',
                    low: 1,
                    high: 100
                },
                'terranHabitabilityIndex*': {
                    heading: 'Terran Habitability Index',
                    low: 1,
                    high: 10
                },
                'reiDetermination*': {
                    heading: 'REI Determination',
                    low: 1,
                    high: 10
                },
                'warpPointDistanceOffset*': {
                    heading: 'Warp Point Distance Offset',
                    low: 1,
                    high: 100
                },
                'techLevelDetermination*': {
                    heading: 'Tech Level Determination',
                    low: 1,
                    high: 6
                },
                'techLevelDirect*': {
                    heading: 'Tech Level Direct (Use same value!)',
                    low: -2,
                    high: 15
                }
            },
            overrides: {},
        };
        oc.pageData = localData;
        oc.session = DataStoreSvc;
        oc.actionName = actionName;

        var init = function () {
            localData.overrides = oc.session['overrides'] || {};
        };

        oc.onChange = function (key) {
            if (localData.categories[key].table) {
                TableSvc.getTableDataForType(localData.categories[key].table).then(
                    function (table) {
                        localData.table = table;
                    }
                );
            }
            else {
                localData.table = null;
            }
            let low = localData.categories[key].low;
            let high = localData.categories[key].high;
            localData.low = low;
            localData.high = high;
            let eLow = $document[0].getElementById('low')
            eLow.setAttribute('min',low);
            let eHigh = $document[0].getElementById('high');
            eHigh.setAttribute('max',high);
        };

        oc.add = function (key) {
            if (!localData.low) {
                localData.low = localData.categories[key].low;
            }
            if (!localData.high) {
                localData.high = localData.categories[key].high;
            }
            localData.overrides[key] = {
                low: localData.low,
                high: localData.high
            };
            oc.session['overrides'] = localData.overrides;
        };

        oc.remove = function (key) {
            delete localData.overrides[key];
            oc.session['overrides'] = localData.overrides;
        };

        oc.ok = function () {
            $ismModalInstance.close(localData.overrides);
        };
        
        oc.cancel = function () {
            $ismModalInstance.dismiss('cancel');
        };
  
        init();
    }
]);
