angular.module('app').controller('FleetCtrl', [
    'UserSvc', 'FleetSvc', 'DataStoreSvc', 'StarSystemSvc', '$ismModal', 'ShipSvc', 'FleetOrderSvc', 'TableSvc', '$location',
    function (UserSvc, FleetSvc, DataStoreSvc, StarSystemSvc, $ismModal, ShipSvc, FleetOrderSvc, TableSvc, $location) {
        'use strict';

        var fc = this;

        var localData = {
            oneAtATime: true,
            shipNames: {}
        };
        fc.fleetData = localData;
        fc.session = DataStoreSvc;

        var init = function () {
            return UserSvc.validateLoggedInUser().then( function (user) {
                localData.user = user;
                localData.editNextLevel = user.role === 'Master' || user.role === 'Marshal';
                localData.race = DataStoreSvc.getRace();
                let p1 = FleetSvc.getFleetsForRace(localData.race).then(function (fleets) {
                    fleets.sort( function (a, b) {
                        var x = a.fleetName.toLowerCase();
                        var y = b.fleetName.toLowerCase();
                        return (x < y) ? -1 : (x > y) ? 1 : 0;
                    });

                    localData.firstFleet = fleets[0];
                    localData.hashFleets = {};
                    for (var i = 0; i < fleets.length; i++) {
                        var fleet = fleets[i];
                        localData.hashFleets[fleet._id] = fleet;
                    }
                });
                let p2 = StarSystemSvc.getAllStarSystems(DataStoreSvc.campaign).then(
                    function (starSystems) {
                        localData.allKnownStarSystems = starSystems;
                        localData.hashStarSystems = {};
                        for (let i = 0; i < starSystems.length; i++) {
                            let starSystem = starSystems[i];
                            localData.hashStarSystems[starSystem._id] = starSystem;
                        }
                    }
                );
                let p3 = ShipSvc.getShipsForRace(DataStoreSvc.getRace()).then(function (ships) {
                    ships.forEach(function (ship, index, theShips) {
                        localData.shipNames[ship._id] = ship.shipName;
                    });
                });
                let p4 = FleetOrderSvc.getFleetOrdersForRaceId(localData.race._id).then(
                    function (fleetOrders) {
                        // console.log('fleetOrders.length: ' + fleetOrders.length);
                        localData.instructionsByFleetId = {};
                        for (let i = 0; i < fleetOrders.length; i++) {
                            let fleetOrder = fleetOrders[i];
                            let fleetId = fleetOrder.fleetId;
                            if (!localData.instructionsByFleetId[fleetId]) {
                                localData.instructionsByFleetId[fleetId] = [];
                            }
                            localData.instructionsByFleetId[fleetId].push(fleetOrder);
                            // console.log('selectFleet: fleetId: ' + fleetId + ' localData.instructionsByFleetId[fleetId].length = ' + localData.instructionsByFleetId[fleetId].length);
                        }
                        // console.log('fleetOrders finished');
                    }
                );
                let p5 = TableSvc.getTableType('instructions').then(
                    function (instructions) {
                        // console.log('instructions.length: ' + instructions.length);
                        localData.instructions = instructions;
                        localData.hashInstructions = {};
                        for (let i = 0; i < instructions.length; i++) {
                            let instruction = instructions[i];
                            localData.hashInstructions[instruction.code] = instruction;
                        }
                        // console.log('instructions finished');
                    }
                );
                Promise.all( [ p1, p2, p3, p4, p5 ] ).then(
                    function () {
                        console.log('FleetCtrl: ', fc);
                    }
                );
            }).catch(
                function () {
                    $location.path('/login');
                }
            );
        };

        fc.animationsEnabled = true;

        fc.createFleet = function () {
            var newFleet = {
                campaignId: DataStoreSvc.getCampaign()._id,
                raceId: DataStoreSvc.getRace()._id,
                fleetName: 'New Fleet',
                instanceIdentifier: 0,
                // commandShipId: 0000,
                turnMode: 4,
                status: 'active',
                // starSystemId: 0000,
                locationHex: '4232',
                heading: 0,
                speed: 0,
                screenDistance: 0,
                turnActivated: 0,
                turnDeactivated: 0
            };
            localData.fleetToEdit = newFleet;
            fc.open();
        };

        fc.editFleet = function (fleet) {
            localData.selectedFleet = fleet;
            // What will be displayed or edited - must go here!
            localData.fleetToEdit = {
                _id: fleet._id,
                fleetName: fleet.fleetName,
                starSystemId: fleet.starSystemId,
                locationHex: fleet.locationHex
            };
            fc.open();
        };

        fc.processSavedFleet = function (savedFleet) {
            localData.hashFleets[savedFleet._id] = savedFleet;
        };

        fc.open = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.fleet ' + parentSelector)) : undefined;

            var modalInstance = $ismModal.open({
                animation: fc.animationsEnabled,
                templateUrl: 'templates/fleetEdit.html',
                controller: 'FleetEdit',
                controllerAs: 'fec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    fleetToEdit: function () {
                        return localData.fleetToEdit;
                    },
                    fleetsInHex: function () {
                        return localData.hashFleets;
                    },
                    firstFleet: function () {
                        return localData.firstFleet;
                    },
                    allKnownStarSystems: function () {
                        return localData.allKnownStarSystems;
                    },
                    shipNames: function () {
                        return localData.shipNames;
                    }
                }
            });

            modalInstance.result.then(
                function (fleet) {
                    if (fleet._id == null) {
                        return FleetSvc.saveFleet(fleet);
                    } else {
                        return FleetSvc.saveFleet(fleet).then( function (fleet) {
                            localData.selectedFleet.fleetName = fleet.fleetName;
                            localData.selectedFleet.locationHex = fleet.locationHex;
                        });
                    }
                }
            ).then(
                function( savedFleet ) {
                    fc.processSavedFleet( savedFleet );
                }
            ).catch(
                function () {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        init();
    }
]).controller('FleetEdit', function ($ismModalInstance, fleetToEdit, fleetsInHex, firstFleet, allKnownStarSystems, shipNames) {
    var fec = this;

    fec.selected = null;
    fec.type1 = "col1";
    fec.types1 = ["col1"];
    fec.type2 = "col2";
    fec.types2 = ["col2"];

    fec.fleetsInHex = fleetsInHex;
    fec.fleetToEdit = fleetToEdit;
    fec.selectedFleet = firstFleet;
    fec.allKnownStarSystems = allKnownStarSystems;
    fec.shipNames = shipNames;

    var init = function() {};

    fec.ok = function () {
      $ismModalInstance.close(fec.fleetToEdit);
    };
  
    fec.cancel = function () {
      $ismModalInstance.dismiss('cancel');
    };

    fec.dropCol1 = function(index, item, external, type) {
	    fec.fleetToEdit.shipIds.push(item);
        return true;
    };

    fec.dropCol2 = function(index, item, external, type) {
	    fec.selectedFleet.shipIds.push(item);
        return true;
    };

    init();
});
