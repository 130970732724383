/* global angular localStorage */
angular.module('app').service('ShipSvc', ['$http', 'SsdParserSvc',
    function ($http, SsdParserSvc) {
        'use strict';

        var svc = this;
        var localData = {
            countedByClassId: {}
        };

        svc.getShipForId = function (shipId) {
            return $http.get('/api/ship/id/' + shipId).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getShipsForRace = function (race) {
            return $http.get('/api/ship/race/' + race._id).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getShipYardsForRace = function (race) {
            return $http.get('/api/ship/sy/race/' + race._id).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.saveShip = function (ship) {
            if (ship._id != null) {
                return $http.put('/api/ship/' + ship._id, ship).then(
                    function (response) {
                        return response.data;
                    }
                );
            } else {
                return svc.saveNewShip(ship);
            }
        };

        svc.saveNewShip = function (ship) {
            return $http.post('/api/ship', ship).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.saveNewShips = function (ships) {
            return $http.post('/api/ships', ships).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.deleteShipForId = function (shipId) {
            return $http.delete('/api/ship/id/' + shipId).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.countItems = function (ship, countedItems=[]) {
            let removeRequiredQH = false;
            let counted = localData.countedByClassId[ship.shipClassId];
            if (counted) {
                let validCount = 0;
                for (const key of countedItems) {
                    validCount = validCount + counted[key];
                }
                if (validCount >= 0) {
                    return counted;
                }
            }
            else {
                counted = {};
                for (const key of countedItems) {
                    counted[key] = 0;
                    removeRequiredQH = removeRequiredQH || (key === 'Q' || key === 'H')
                }
            }
            let items = SsdParserSvc.parseSsd(ship.ssd);
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (countedItems.indexOf(item) > -1) {
                    counted[item] = (counted[item] || 0) + 1;
                }
            }
            if (removeRequiredQH) {
                if (ship.actualSize > 7) {
                    if (counted['Hs'] > 0) {
                        counted['Hs'] = counted['Hs'] - 1;
                    }
                    else {
                        counted['H'] = counted['H'] - 1;
                    }
                }
                let qPoints = ship.actualSize;
                while (qPoints > 0 && counted['Qs'] > 0) {
                    qPoints = qPoints - 20;
                    counted['Qs'] = counted['Qs'] - 1;
                }
                while (qPoints > 0 && counted['Q'] > 0) {
                    qPoints = qPoints - 50;
                    counted['Q'] = counted['Q'] - 1;
                }
            }
            localData.countedByClassId[ship.shipClassId] = counted;
            return counted;
        };
    }
]);
