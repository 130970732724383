/* globals angular localStorage */
angular.module('app').controller('UserManagerCtrl', [
    '$location', 'UserSvc', 'DataStoreSvc',
    function ($location, UserSvc, DataStoreSvc) {
        'use strict';

        var umc = this;
        var localData = {};
        umc.pageData = localData;
        umc.session = DataStoreSvc;

        var init = function () {
            let p1 = UserSvc.getAllUsers().then(function (users) {
                localData.users = users;
                console.log('User Manager (umc): ', umc);
            });

            Promise.all([p1]).then(
                function (results) {
                    return 'Done!'
                }
            )
        };

        umc.editUser = function (editUser) {
            umc.session.setEditUser(editUser);
            // $location.path('/user');
        };

        init();
    }
]);
