/* globals angular localStorage */
angular.module('app').controller('TroopPurchaseCtrl', [
    '$scope', 'DataStoreSvc', 'EconomySvc', 'TableSvc', 'InformationPopulationSvc', '$ismModal',
    function ($scope, DataStoreSvc, EconomySvc, TableSvc, InformationPopulationSvc, $ismModal) {
        'use strict';

        let tpc = this;
        var localData = {
            title: 'Troops'
        };
        tpc.pageData = localData;
        tpc.session = DataStoreSvc;

        var init = function () {
            localData.race = DataStoreSvc.getRace();
            localData.readOnly = localData.race.turnActivated <= localData.race.readyForEconomicsTurn;

            let infoPopPromise = InformationPopulationSvc.getInformationPopulationForRaceAndTurn(localData.race._id, localData.race.turnCreated).then(
                function (infoPops) {
                    localData.infoPops = infoPops;
                    localData.puTotal = infoPops.reduce( (total, pop) => total + pop.pu, 0);
                    return localData.infoPops;
                }
            );

            let transactionsPromise = EconomySvc.getOrBuildTransactions(localData.race, tpc.session.editTurn).then(
                function (transactions) {
                    localData.transactionsById = transactions[localData.title] || {};
                    localData.total = transactions[localData.title + 'Total'] || { amount: 0 };
                    localData.pcfTransactions = {};
                    localData.pcfaTransactions = {};
                    for (const id in localData.transactionsById) {
                        let transaction = localData.transactionsById[id];
                        if (transaction.description === "PCF") {
                            localData.pcfTransactions[transaction.bodyId] = transaction;
                        }
                        else {
                            localData.pcfaTransactions[transaction.bodyId] = transaction;
                        }
                    }
                    return transactions;
                }
            );
    
            Promise.all([infoPopPromise, transactionsPromise]).then(
                function(results) {
                    let infoPopulations = results[0];
                    let transactions = results[1];

                    localData.infoPopsById = infoPopulations.reduce( (hash, ip) => (hash[ip.planetId] = ip, hash), {});
                    localData.bodyIds = new Set(Object.keys(localData.pcfTransactions).concat(Object.keys(localData.pcfaTransactions)));

                    localData.transactionsToDisplay = [...localData.bodyIds].map(bodyId => {
                        return createPcfData(bodyId);
                    });
                    localData.displayById = localData.transactionsToDisplay.reduce(
                        (hash, display) => (hash[display.planetId] = display, hash),
                        {}
                    );

                    console.log('TroopPurchaseCtrl: ', tpc);

                    $scope.$apply();
                }
            )
        };

        let createPcfData = function (bodyId) {
            let transaction = {
                planetId: bodyId,
                locator: localData.infoPopsById[bodyId].locator,
                pu: localData.infoPopsById[bodyId].pu,
                newPcf: (localData.pcfTransactions[bodyId] || {}).quantity || 0,
                newPcfa: (localData.pcfaTransactions[bodyId] || {}).quantity || 0,
                amount: ((localData.pcfTransactions[bodyId] || {}).amount || 0) +
                        ((localData.pcfaTransactions[bodyId] || {}).amount || 0)
            };
            return transaction;
        };

        let createNewPcfTransactionData = function (pcfType, bodyId) {
            let transaction = {
                campaignId: tpc.session.getCampaign()._id,
                raceId: tpc.session.getRace()._id,
                type: localData.title,
                turn: localData.race.turnActivated,
                description: pcfType,
                size: tpc.session.getRace().techLevel,
                quantity: 0,
                amount: 0
            };
            if (bodyId) {
                transaction.bodyId = bodyId;
            }
            return transaction;
        };

        tpc.editTransaction = function (planetId) {
            let transaction = localData.pcfTransactions[planetId];
            if (transaction == null) {
                localData.pcfTransactionToEdit = createNewPcfTransactionData('PCF', planetId);
            }
            else {    
                localData.pcfTransactionToEdit = angular.copy(transaction);
            }

            transaction = localData.pcfaTransactions[planetId];
            if (transaction == null) {
                localData.pcfaTransactionToEdit = createNewPcfTransactionData('PCFa', planetId);
            }
            else {    
                localData.pcfaTransactionToEdit = angular.copy(transaction);
            }

            tpc.open();
        };

        tpc.deleteTransaction = function (transaction) {
            let oldTransaction = localData.transactionsById[transaction._id] || {};
            localData.total.amount = localData.total.amount - (oldTransaction.amount || 0);
            delete localData.transactionsById[transaction._id];

            if (transaction.description === "PCF") {
                delete localData.pcfTransactions[transaction.bodyId];
            }
            else {
                delete localData.pcfaTransactions[transaction.bodyId];
            }

            return EconomySvc.deleteTransaction(transaction);
        };

        let savePcfTransaction = function (transaction) {
            let promise;
            if (transaction.amount === 0 && transaction._id) {
                promise = tpc.deleteTransaction(transaction);
            }
            else {
                let oldTransaction = localData.transactionsById[transaction._id];
                if (!oldTransaction || oldTransaction.quantity != transaction.quantity) {
                    promise = EconomySvc.saveTransaction(transaction).then( tpc.processSavedTransaction );
                }
                else {
                    promise = Promise.resolve("Done");
                }
            }
            return promise;
        };

        tpc.savePcfTransactions = function (pcfTransaction, pcfaTransaction) {
            let bodyId = pcfTransaction.bodyId || pcfaTransaction.bodyId;
            Promise.all([
                savePcfTransaction(pcfTransaction),
                savePcfTransaction(pcfaTransaction)
            ]).then(
                function () {
                    let displayLine = createPcfData(bodyId);
                    if (displayLine.amount > 0) {
                        localData.displayById[bodyId] = displayLine;
                    }
                    else {
                        delete localData.displayById[bodyId];
                    }
                    localData.transactionsToDisplay = Object.values(localData.displayById);
                    return "Done"; 
                }
            );
        };

        tpc.processSavedTransaction = function (transaction) {
            let oldTransaction = localData.transactionsById[transaction._id] || {};
            localData.total.amount = localData.total.amount - (oldTransaction.amount || 0);
            localData.total.amount = localData.total.amount + (transaction.amount || 0);

            localData.transactionsById[transaction._id] = transaction;
            if (transaction.description === "PCF") {
                localData.pcfTransactions[transaction.bodyId] = transaction;
            }
            else {
                localData.pcfaTransactions[transaction.bodyId] = transaction;
            }
        };

        tpc.newTransaction = function () {
            localData.pcfTransactionToEdit = createNewPcfTransactionData('PCF');
            localData.pcfaTransactionToEdit = createNewPcfTransactionData('PCFa');

            tpc.open();
        };

        localData.animationsEnabled = true;

        tpc.open = function (size, parentSelector) {
            let parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.troopPurchase ' + parentSelector)) : undefined;

            let bodyId = localData.pcfTransactionToEdit.bodyId || localData.pcfaTransactionToEdit.bodyId;
            let infoPop = localData.infoPopsById[bodyId];

            let modalInstance = $ismModal.open({
                animation: localData.animationsEnabled,
                templateUrl: 'templates/troopEditModal.html',
                controller: 'TroopEditCtrl',
                controllerAs: 'tec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    pcfTransaction: function () {
                        return localData.pcfTransactionToEdit;
                    },
                    pcfaTransaction: function () {
                        return localData.pcfaTransactionToEdit;
                    },
                    pcfCost: function () {
                        let cost = localData.race.techLevel + 3;
                        return cost;
                    },
                    maxPcf: function () {
                        return 0;
                    },
                    populationsAvailable: function () {
                        let populations = (Object.keys(localData.infoPopsById)).reduce(
                            (pops, _bodyId) => {
                                if (!localData.bodyIds.has(_bodyId) || (_bodyId === bodyId)) {
                                    pops.push(localData.infoPopsById[_bodyId]);
                                }
                                return pops;
                            },
                            []
                        );
                        return populations;
                    }
                }
            });

            modalInstance.result.then(
                function (transactions) {
                    tpc.savePcfTransactions(transactions.pcf, transactions.pcfa);
                }
            ).catch(
                function (err) {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        init();
    }
]);
