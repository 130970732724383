/* global angular, localStorage */
angular.module('app').service('WarpPointSvc', [
    '$http', '$location', 'DataStoreSvc',
    function ($http, $location, DataStoreSvc) {
        'use strict';
        let svc = this;

        svc.getWarpPointsForStarSystemId = function (starSystemId) {
            return $http.get('/api/warpPoint/starSystemId/'+starSystemId).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.exploreWarpPoint = function (warpPointId, starSystemNumber, overrides) {
            let campaign = DataStoreSvc.getCampaign();
            let race = DataStoreSvc.getRace();
            return $http.post('/api/warpPoint/explore/'+warpPointId, {
                campaign: campaign,
                race: race,
                starSystemNumber: starSystemNumber,
                overrides: overrides
            }).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.removeDestination = function (warpPointId) {
            return $http.put('/api/warpPoint/disconnect/'+warpPointId).then(
                function (response) {
                    return response.data;
                }
            );
        };
    }
]);
