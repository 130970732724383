angular.module('app').controller('ShipClassSharedCtrl', [
    '$timeout', 'DataStoreSvc', 'ShipClassSvc',
    function ($timeout, DataStoreSvc, ShipClassSvc) {
        'use strict';

        var scsc = this;
        var pageData = {};
        scsc.classDesignData = pageData;
        scsc.session = DataStoreSvc;

        var init = function () {
            var user = scsc.session.getUser();
            pageData.editNextLevel = user.role === 'Master' || user.role === 'Marshal';
            let p1 = ShipClassSvc.getSharedShipClasses().then(
                function (classes) {
                    classes.sort( function (a, b) {
                        var x = a.className.toLowerCase();
                        var y = b.className.toLowerCase();
                        return (x < y) ? -1 : (x > y) ? 1 : 0;
                    });
                    pageData.sharedShipClasses = classes;
                }
            );

            Promise.all([p1]).then(
                function () {
                    console.log('ClassDesignCtrl', scsc);
                }
            );
        };

        scsc.copyShipClass = function ( shipClass ) {
            scsc.session.iAmBusy();
            let newShipClass = ShipClassSvc.copyShipClass(shipClass, scsc.session.getRace());
            ShipClassSvc.saveNewShipClass(newShipClass).then(
                function (shipClass) {
                    scsc.message = 'New class added to design list!';
                    scsc.session.iAmNotBusy();
                }
            );
        };

        scsc.closeAlert = function () {
            scsc.message = null;
        };

        init();
    }
]);
