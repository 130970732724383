/* global angular localStorage */
angular.module('app').service('ShipClassSvc', function ($http, $location) {
    'use strict';

    var svc = this;
    var localData = {};

    svc.getShipClassForId = function (shipClassId) {
        return $http.get('/api/shipClass/id/' + shipClassId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.deleteShipClassForId = function (shipClassId) {
        return $http.delete('/api/shipClass/' + shipClassId).then(
            function (response) {
                return "Done";
            }
        );
    };

    svc.getShipClassesForRaceWithCount = function (race) {
        return $http.get('/api/shipClass/race/' + race._id + '/withCount').then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getShipClassesForRace = function (race) {
        return $http.get('/api/shipClass/race/' + race._id).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getSharedShipClasses = function (race) {
        return $http.get('/api/shipClass/shared').then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getFilteredShipClassesForRace = function (race, filter) {
        return svc.getShipClassesForRace(race).then(
            function (shipClasses) {
                if (typeof filter === 'function') {
                    return shipClasses.filter(filter);
                }
                return shipClasses;
            }
        );
    };

    svc.saveShipClass = function (shipClass) {
        if (shipClass._id != null) {
            return $http.put('/api/shipClass/' + shipClass._id, shipClass).then(
                function (response) {
                    return response.data;
                }
            );
        } else {
            return svc.saveNewShipClass(shipClass);
        }
    };

    svc.saveNewShipClass = function (shipClass) {
        return $http.post('/api/shipClass', shipClass).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.copyShipClass = function (shipClass, race) {
        let newShipClass = Object.assign({}, shipClass);
        delete newShipClass._id;
        delete newShipClass.__v;
        newShipClass.campaignId = race.campaignId;
        newShipClass.raceId = race._id;

        return newShipClass;
    }
});
