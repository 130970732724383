/* globals angular localStorage */
angular.module('app').controller('TechLevelResearchCtrl', [
    '$timeout', 'DataStoreSvc', 'EconomySvc', 'ProjectSvc',
    function ($timeout, DataStoreSvc, EconomySvc, ProjectSvc) {
        'use strict';

        var tlrc = this;
        var localData = {
            history: [],
            transaction: null,
            hasNextLevelActive: false,
            current: {}
        };
        tlrc.pageData = localData;
        tlrc.session = DataStoreSvc;

        var init = function () {
            localData.editNextLevel = tlrc.session.user.role === 'Master' || tlrc.session.user.role === 'Marshal';
            localData.race = tlrc.session.getRace();
            localData.campaign = DataStoreSvc.getCampaign();
            let p1 = ProjectSvc.getProjectLevelsForRace(localData.race._id).then(
                function (levels) {
                    for (let i = 0; i < levels.length; i++) {
                        const level = levels[i];
                        const tl = Number.parseInt(level.code.substring(0, level.code.length-2));
                        localData.hasNextLevelActive = (localData.hasNextLevelActive || (tl === localData.race.techLevel));
                        if (level.state != "completed") {
                            localData.current = level;
                        }
                        else {
                            localData.history.push(level);
                        }
                    }
                    return localData.current;
                }
            );

            let p2 = EconomySvc.getTransactionData(localData.race._id, tlrc.session.editTurn).then(
                function (transactions) {
                    localData.transactions = transactions;
                    localData.total = (transactions['economicLevelTotal'] || {}).amount || 0;
                    localData.incomeTotal = 
                        ((transactions['popIncomeTotal'] || {}).amount || 0) +
                        ((transactions['tradeIncomeTotal'] || {}).amount || 0) +
                        ((transactions['leaseIncomeTotal'] || {}).amount || 0) +
                        ((transactions['shipSaleTotal'] || {}).amount || 0) +
                        ((transactions['otherIncomeTotal'] || {}).amount || 0);
                    localData.expensesTotal = 
                        ((transactions['maintenanceTotal'] || {}).amount || 0) +
                        ((transactions['constructionTotal'] || {}).amount || 0) +
                        ((transactions['industrialTotal'] || {}).amount || 0) +
                        ((transactions['colonizationTotal'] || {}).amount || 0) +
                        // ((transactions['economicLevelTotal'] || {}).amount || 0) +
                        ((transactions['techItemTotal'] || {}).amount || 0) +
                        ((transactions['otherExpenseTotal'] || {}).amount || 0);
                    localData.techLevelTransactions = Object.values(localData.transactions['economicLevel']||{});
                    return localData.techLevelTransactions;
                }
            );

            let p3 = getNextTurnTransactions(localData.race._id, tlrc.session.editTurn + 1, 'economicLevel');

            Promise.all( [ p1, p2, p3 ] ).then(
                function (results) {
                    let project = results[0];
                    let transactions = results[1];
                    transactions.forEach( function (transaction) {
                        if (transaction.amount === project.startUpFee) {
                            localData.startUpTransaction = transaction;
                        }
                        else if (transaction.amount === (project.startUpFee / 2)) {
                            localData.startUpTransaction = transaction;
                        }
                        else if (transaction.amount === project.monthlyFee) {
                            localData.monthlyTransaction = transaction;
                        }
                        else {
                            localData.otherTransaction = transaction;
                        }
                    });
                    console.log('TechLevelResearchCtrl: ', tlrc);
                    $timeout();
                }
            );
        };

        let getNextTurnTransactions = function (raceId, turn, type) {
            return EconomySvc.getSpecificTransactionData(raceId, turn, type).then(
                function (transactions) {
                    let techLevelTransactions = Object.values(transactions['economicLevel']||{});
                    localData.techLevelTransactionsNextTurn = techLevelTransactions.find(transaction => (transaction.description.indexOf('Monthly') > -1));
                    return localData.techLevelTransactionsNextTurn;
                }
            );
        };

        tlrc.createNextLevel = function () {
            if (!localData.current) {
                EconomySvc.findNextEconomicLevelResearchForRace(localData.race._id).then(
                    function (rdEconomicLevel) {
                        localData.hasNextLevelActive = true;
                        localData.current = rdEconomicLevel;
                        $timeout();
                    }
                );
            }
        };

        let getNewEconomicData = function (code) {
            var newEconomicData = {
                campaignId: DataStoreSvc.getCampaign()._id,
                raceId: DataStoreSvc.getRace()._id,
                turn: tlrc.session.editTurn,
                type: 'economicLevel',
                quantity: 1,
                amount: 0,          // actual cost (to be replaced from table entry)
                locator: code,      // project code
                description: 'Cost to start economic level research'
            };
            return newEconomicData;
        };

        tlrc.start = function (code, startUpFactor = 1, monthlyFactor = 1) {
            let levelResearch = localData.current;
            levelResearch.turnStarted = tlrc.session.editTurn;
            let economicData = getNewEconomicData(code);
            economicData.amount = levelResearch.startUpFee * startUpFactor;

            return EconomySvc.saveTransaction(economicData).then(
                function ( savedTransaction ) {
                    localData.startUpTransaction = savedTransaction;
                    localData.total = (localData.total || 0) + savedTransaction.amount;
                    return ProjectSvc.updateProjectById(levelResearch._id, 
                        {
                            turnStarted: levelResearch.turnStarted,
                            turnProcessed: levelResearch.turnStarted
                        }
                    );
                }
            ).then(
                function ( savedProject ) {
                    localData.current = savedProject;
                    economicData = getNewEconomicData(code);
                    economicData.turn = economicData.turn + 1;
                    economicData.amount = levelResearch.monthlyFee * monthlyFactor;
                    economicData.description = 'Monthly cost of economic level research';
                    return EconomySvc.saveTransaction(economicData);
                }
            ).then(
                function ( savedTransaction ) {
                    $timeout();
                }
            ).catch(
                function (err) {
                    console.log('err: ' + err.stack);
                }
            );
        };

        tlrc.restart = function (code) {
            return tlrc.start(code, 0.5);
        };

        tlrc.stop = function (code) {
            return tlrc.cancel(code);
        };

        tlrc.cancel = function (code) {
            let levelResearch = localData.current;
            levelResearch.turnStarted = -1;
            return ProjectSvc.updateProjectById(levelResearch._id, 
                {
                    turnStarted: levelResearch.turnStarted,
                    turnProcessed: tlrc.session.editTurn
                }
            ).then(
                function (updatedProject) {
                    localData.current = updatedProject;
                    let originalTransaction = localData.startUpTransaction;
                    if (!originalTransaction) {
                        return "Done!";
                    }
                    localData.startUpTransaction = null;
                    localData.total = (localData.total || 0) - originalTransaction.amount;
                    return EconomySvc.deleteTransaction(originalTransaction);
                }
            ).then(
                function( savedEconomicData ) {
                    // YEAH! it's gone! (or wasn't here)
                    // Now get next month's monthly payment and delete it too!
                    if (localData.techLevelTransactionsNextTurn) {
                        return localData.techLevelTransactionsNextTurn;
                    }
                    return getNextTurnTransactions(localData.race._id, DataStoreSvc.editTurn + 1, 'economicLevel');
                }
            ).then(
                function (nextTurnTransaction) {
                    localData.techLevelTransactionsNextTurn = null;
                    return EconomySvc.deleteTransaction(nextTurnTransaction);
                }
            ).then(
                function( savedEconomicData ) {
                    // YEAH! it's gone! (or wasn't here)
                    return "Done";
                }
            ).catch(
                function (err) {
                    console.log('err: ' + err.stack);
                }
            );
        };

        init();
    }
]);
