/* globals angular localStorage */
angular.module('app').controller('ColonizationExpenseCtrl', [
    '$timeout', '$ismModal', 'DataStoreSvc', 'StarSystemSvc', 'InformationPopulationSvc', 'InformationPlanetSvc', 'EconomySvc', 'FleetSvc', 'ShipSvc', 'UtilitySvc',
    function ($timeout, $ismModal, DataStoreSvc, StarSystemSvc, InformationPopulationSvc, InformationPlanetSvc, EconomySvc, FleetSvc, ShipSvc, UtilitySvc) {
        'use strict';

        var cec = this;
        var localData = {
            total: 0,
            cfnHUsed: {'total': 0},
            cfnQUsed: {'total': 0},
            status: {}
        };
        cec.pageData = localData;
        cec.session = DataStoreSvc;

        var init = function () {
            localData.editNextLevel = cec.session.user.role === 'Master' || cec.session.user.role === 'Marshal';
            localData.race = cec.session.getRace();

            // get all known systems (with populations?)
            let p1 = StarSystemSvc.getStarSystemsForRace(localData.race._id).then(
                function (results) {
                    localData.allStarSystems = results;
                }
            );

            // get all informationPopulation
            let p2 = InformationPopulationSvc.getAllInformationPopulationForRace(localData.race._id).then(
                function (informationPopulations) {
                    localData.freePtu = {};
                    localData.ssPopulationInfo = {};
                    localData.myPopulationInfo = informationPopulations;
                    localData.populationInformationMap = informationPopulations.reduce(function(ipMap, data) {
                        ipMap[data.planetId] = data;
                        return ipMap;
                    }, {});
                    informationPopulations.forEach( informationPopulation => {
                        if (informationPopulation.pu > 400) {
                            let val = 0;
                            if (informationPopulation.pu >= 3200) {
                                val += 100;
                            }
                            else if (informationPopulation.pu > 1600) {
                                val += 10;
                            }
                            else if (informationPopulation.pu > 800) {
                                val += 4;
                            }
                            else {
                                val += 1;
                            }
                            informationPopulation.freePtu = val;
                            localData.freePtu['total'] = (localData.freePtu['total'] || 0) + val;
                            val = val + (localData.freePtu[informationPopulation.starSystemId] || 0);
                            localData.freePtu[informationPopulation.starSystemId] = val;
                        }
                        let informationPops = (localData.ssPopulationInfo[informationPopulation.starSystemId] || []);
                        informationPops.push(informationPopulation);
                        localData.ssPopulationInfo[informationPopulation.starSystemId] = informationPops;
                    });
                }
            );

            // get all informationPlanet
            let p3 = InformationPlanetSvc.getAllInformationPlanetForRace(localData.race._id).then(
                function (infoPlanets) {
                    localData.surveyedBodyInfo = infoPlanets;
                    localData.surveyedBodyByStarSystemNumber = {};
                    localData.surveyedBodyInfoMap = infoPlanets.reduce( function (map, surveyedBody) {
                        let locatorElements = surveyedBody.locator.split("-");
                        let starSystemNumber = locatorElements[0];
                        let bodyArray = (localData.surveyedBodyByStarSystemNumber[starSystemNumber] || []);
                        bodyArray.push(surveyedBody);
                        localData.surveyedBodyByStarSystemNumber[starSystemNumber] = bodyArray;

                        map.set(surveyedBody.bodyId, surveyedBody);
                        return map;
                    }, new Map());
                    localData.systemsSet = new Set(
                        infoPlanets.map( info => info.starSystemId )
                    );
                }
            );

            let p4 = EconomySvc.getTransactionData(localData.race._id, cec.session.editTurn).then(
                function (transactions) {
                    localData.transactions = transactions;
                    localData.popIncomeTotal = (transactions['popIncomeTotal'] || {}).amount || 0;
                    localData.incomeTotal = 
                        localData.popIncomeTotal +
                        ((transactions['tradeIncomeTotal'] || {}).amount || 0) +
                        ((transactions['leaseIncomeTotal'] || {}).amount || 0) +
                        ((transactions['shipSaleTotal'] || {}).amount || 0) +
                        ((transactions['otherIncomeTotal'] || {}).amount || 0);
                    localData.expensesTotal = 
                        ((transactions['maintenanceTotal'] || {}).amount || 0) +
                        ((transactions['constructionTotal'] || {}).amount || 0) +
                        ((transactions['industrialTotal'] || {}).amount || 0) +
                        // ((transactions['colonizationTotal'] || {}).amount || 0) +
                        ((transactions['techLevelTotal'] || {}).amount || 0) +
                        ((transactions['techItemTotal'] || {}).amount || 0) +
                        ((transactions['otherExpenseTotal'] || {}).amount || 0);
                    localData.baseCfnH = Math.floor(localData.popIncomeTotal / 5);
                    localData.baseCfnQ = Math.floor(localData.popIncomeTotal / 10);

                    localData.ssPopIncome = {};
                    localData.ssIncome = {};
                    localData.inSystemCfnPtu = {};
                    localData.ptuPlaced = {};
                    Object.values(localData.transactions['popIncome']||{}).forEach(
                        function (transaction) {
                            let locatorElements = transaction.locator.split("-");
                            let starSystemNumber = locatorElements[0];
                            let income = localData.ssIncome[starSystemNumber] || 0;
                            income += transaction.amount;
                            localData.ssIncome[starSystemNumber] = income;
                            // per 15.04.09b, a star system must have >= 200MC for an in-system CFN
                            localData.inSystemCfnPtu[starSystemNumber] = (income < 200) ? 0 : Math.floor(income / 100);
                        }
                    );
                }
            );

            let p5 = FleetSvc.getFreighterOverflowFleetsForRace(localData.race._id).then(
                function (fleets) {
                    localData.fleets = fleets;
                    for (const fleet of localData.fleets) {
                        let shipCounts = fleet.ships.map( ship => ShipSvc.countItems(ship, ['H', 'Hs', 'Q', 'Qs']));
                        if (shipCounts.length === 0) {
                            shipCounts = [{
                                'H': 0,
                                'Hs': 0,
                                'Q': 0,
                                'Qs': 0
                            }]
                        }
                        fleet.totalCounts = UtilitySvc.sumObjectsByKey(...shipCounts);
                    }
                    let overflowFleetsCounts = localData.fleets.map( fleet => fleet.totalCounts );
                    localData.overflowFleetsCounts = UtilitySvc.sumObjectsByKey(...overflowFleetsCounts);
                }
            );
    
            Promise.all( [ p1, p2, p3, p4, p5 ] ).then(
                function () {
                    localData.starSystemsRestricted = localData.allStarSystems.filter(
                        function (system) {
                            localData.status[system._id] = {isSystemOpen: false};
                            return localData.systemsSet.has(system._id);
                        }
                    );
                    resetColonizationDisplays();
                    Object.values(localData.transactions['colonization']||{}).forEach(
                        transaction => cec.processSavedColonization(transaction)
                    );
                    assessFreightFees();

                    console.log('ColonizationExpenseCtrl: ', cec);
                    $timeout();
                }
            );
        };

        cec.animationsEnabled = true;

        let getEconomicsBase = function() {
            var newColonization = {
                campaignId: DataStoreSvc.getCampaign()._id,
                raceId: DataStoreSvc.getRace()._id,
                turn: cec.session.editTurn,
                endTurn: cec.session.editTurn + 1,
                type: 'colonization',
                quantity: 0,        // PTU (placements are positive, extraction is negative)
                amount: 0,          // actual cost (multi-month, if needed)
                locator: '',        // destination (or source)
                hInUse: 0,
                qInUse: 0,
                cfnType: 'inSystem' // interstellar
            };
            return newColonization;
        };

        cec.createColonization = function (starSystem) {
            localData.selectedStarSystem = starSystem;
            localData.colonizationToEdit = getEconomicsBase();
            localData.colonizationToEdit.description = localData.selectedStarSystem._id;
            cec.open();
        };

        cec.editColonization = function (transaction, starSystem) {
            localData.selectedStarSystem = starSystem;
            localData.colonizationToEdit = angular.copy(transaction);
            cec.open();
        };

        cec.deleteColonization = function (transaction, starSystem) {
            localData.selectedStarSystem = starSystem;
            let starSystemNumber = starSystem.number;
            let colonizationTransactions = localData.transactions['colonization'] || {};
            delete colonizationTransactions[transaction._id];
            localData.transactions['colonization'] = colonizationTransactions;

            localData.transactionsExtraction[starSystemNumber] = removeTransaction(localData.transactionsExtraction[starSystemNumber], transaction._id);
            localData.transactionsOutsider[starSystemNumber] = removeTransaction(localData.transactionsOutsider[starSystemNumber], transaction._id);
            localData.transactionsInternalFree[starSystemNumber] = removeTransaction(localData.transactionsInternalFree[starSystemNumber], transaction._id);
            localData.transactionsInternal[starSystemNumber] = removeTransaction(localData.transactionsInternal[starSystemNumber], transaction._id);

            logNumbers();
            adjustTotalsForRemoval(transaction, starSystemNumber);
            assessFreightFees();

            return EconomySvc.deleteTransaction(transaction);
        };

        let resetColonizationDisplays = function () {
            localData.ptuAll = Object.assign({}, localData.freePtu);
            localData.ptuExtracted = {};
            localData.ptuPlaced = {};
            localData.ptuPlacedByBody = {};
            localData.ptuPlacedOutsider = {};
            localData.ptuPlacedInternal = {};
            localData.ptuPlacedInternalFree = {};
            localData.cfnQUsed = {'total': 0};
            localData.cfnHUsed = {'total': 0};
            localData.mcAmount = {};

            localData.transactionsExtraction = {};
            localData.transactionsOutsider = {};
            localData.transactionsInternalFree = {};
            localData.transactionsInternal = {};

            localData.transactions['processed'] = {};
        };

        let adjustTotals = function (totalsObject, valueChange, starSystemNumber) {
            totalsObject['total'] = (totalsObject['total'] || 0) + valueChange;
            totalsObject[starSystemNumber] = (totalsObject[starSystemNumber] || 0) + valueChange;
        };

        let logNumbers = function () {
            console.log("localData.ptuAll", localData.ptuAll);
            console.log("localData.ptuExtracted", localData.ptuExtracted);
            console.log("localData.ptuPlaced", localData.ptuPlaced);
            console.log("localData.ptuPlacedInternal", localData.ptuPlacedInternal);
            console.log("localData.ptuPlacedInternalFree", localData.ptuPlacedInternalFree);
            console.log("localData.ptuPlacedOutsider", localData.ptuPlacedOutsider);
            console.log("localData.cfnQUsed", localData.cfnQUsed);
            console.log("localData.cfnHUsed", localData.cfnHUsed);
            console.log("localData.mcAmount", localData.mcAmount);
        };

        let adjustTotalsForRemoval = function (transaction, starSystemNumber) {
            if (transaction.quantity < 0) {
                adjustTotals(localData.ptuAll, transaction.quantity, starSystemNumber);
                adjustTotals(localData.ptuExtracted, transaction.quantity, starSystemNumber);
            }
            else {
                adjustTotals(localData.ptuPlaced, -transaction.quantity, starSystemNumber);
                localData.ptuPlacedByBody[transaction.locator] = (localData.ptuPlacedByBody[transaction.locator] || 0) - transaction.quantity;
                if (transaction.cfnType === 'inSystem') {
                    if (transaction.qInUse === 0 && transaction.hInUse === 0) {
                        adjustTotals(localData.ptuPlacedInternalFree, -transaction.quantity, starSystemNumber);
                    }
                    else {
                        adjustTotals(localData.ptuPlacedInternal, -transaction.quantity, starSystemNumber);
                    }
                }
                else {
                    adjustTotals(localData.ptuPlacedOutsider, -transaction.quantity, starSystemNumber);
                    adjustTotals(localData.cfnQUsed, -transaction.qInUse, starSystemNumber);
                    adjustTotals(localData.cfnHUsed, -transaction.hInUse, starSystemNumber);
                }

                adjustTotals(localData.mcAmount, -transaction.amount, starSystemNumber);
            }
            logNumbers();
        };

        let replaceTransaction = function (transactions=[], transaction) {
            let index = transactions.findIndex(
                oldTransaction => oldTransaction._id === transaction._id
            );
            if (index === -1) {
                transactions.push(transaction);
            }
            else {
                transactions[index] = transaction;
            }
            return transactions;
        };

        let removeTransaction = function (transactions=[], transactionId) {
            let index = transactions.findIndex(
                oldTransaction => oldTransaction._id === transactionId
            );
            if (index >= 0) {
                transactions.splice(index, 1);
            }
            return transactions;
        };

        cec.processSavedColonization = function (transaction) {
            if (transaction.description === 'excess freight fees') {
                localData.excessFreightFee = transaction;
                return;
            }

            let locatorElements = transaction.locator.split("-");
            let starSystemNumber = locatorElements[0];

            let colonizationTransactions = localData.transactions['processed'] || {};
            let originalTransaction = colonizationTransactions[transaction._id];
            colonizationTransactions[transaction._id] = transaction;
            localData.transactions['processed'] = colonizationTransactions;

            logNumbers();
            if (originalTransaction) {
                adjustTotalsForRemoval(originalTransaction, starSystemNumber);
            }

            if (transaction.quantity < 0) {
                adjustTotals(localData.ptuAll, -transaction.quantity, starSystemNumber);
                adjustTotals(localData.ptuExtracted, -transaction.quantity, starSystemNumber);
                localData.transactionsExtraction[starSystemNumber] = replaceTransaction(localData.transactionsExtraction[starSystemNumber], transaction);
            }
            else {
                adjustTotals(localData.ptuPlaced, transaction.quantity, starSystemNumber);
                localData.ptuPlacedByBody[transaction.locator] = (localData.ptuPlacedByBody[transaction.locator] || 0) + transaction.quantity;
                if (transaction.cfnType==='inSystem') {
                    if (transaction.qInUse === 0 && transaction.hInUse === 0) {
                        adjustTotals(localData.ptuPlacedInternalFree, transaction.quantity, starSystemNumber);
                        localData.transactionsInternalFree[starSystemNumber] = replaceTransaction(localData.transactionsInternalFree[starSystemNumber], transaction);
                    }
                    else {
                        adjustTotals(localData.ptuPlacedInternal, transaction.quantity, starSystemNumber);
                        localData.transactionsInternal[starSystemNumber] = replaceTransaction(localData.transactionsInternal[starSystemNumber], transaction);
                    }
                }
                else {
                    adjustTotals(localData.ptuPlacedOutsider, transaction.quantity, starSystemNumber);
                    adjustTotals(localData.cfnQUsed, transaction.qInUse, starSystemNumber);
                    adjustTotals(localData.cfnHUsed, transaction.hInUse, starSystemNumber);
                    localData.transactionsOutsider[starSystemNumber] = replaceTransaction(localData.transactionsOutsider[starSystemNumber], transaction);
                }
    
                adjustTotals(localData.mcAmount, transaction.amount, starSystemNumber);
            }
            logNumbers();
        };

        let assessFreightFees = function() {
            if (!localData.excessFreightFee) {
                localData.excessFreightFee = getEconomicsBase();
                localData.excessFreightFee.description = 'excess freight fees';
            }
            let qInUse = (localData.cfnQUsed['total'] || 0) - localData.baseCfnQ;
            localData.excessFreightFee.qInUse = Math.max(qInUse, 0);
            let hInUse = (localData.cfnHUsed['total'] || 0) - localData.baseCfnH;
            localData.excessFreightFee.hInUse = Math.max(hInUse, 0);

            // Normal shipping cost is 1 / H & 1.5 / Q
            // Colonization is discounted / subsidized by 0.5 per H & Q
            // Any shipping OVER base capability is tripled!
            // excess fees are 2x the base rate, so 2 / H & 3 / Q
            localData.excessFreightFee.amount = ((2 * (localData.excessFreightFee.hInUse || 0))
                + (3 * (localData.excessFreightFee.qInUse || 0)));

            EconomySvc.saveTransaction(localData.excessFreightFee).then(
                function(savedTransaction) {
                    localData.excessFreightFee = savedTransaction;
                }
            );
        };

        cec.open = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.colonization ' + parentSelector)) : undefined;

            var modalInstance = $ismModal.open({
                animation: cec.animationsEnabled,
                templateUrl: 'templates/colonizationEdit.html',
                controller: 'ColonizationEdit',
                controllerAs: 'cec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    colonizationToEdit: function () {
                        return localData.colonizationToEdit;
                    },
                    starSystem: function () {
                        return localData.selectedStarSystem;
                    },
                    surveyedBodies: function () {
                        return localData.surveyedBodyByStarSystemNumber[localData.selectedStarSystem.number];
                    },
                    populationInformationMap: function () {
                        return localData.populationInformationMap;
                    },
                    availablePtu: function () {
                        return (localData.ptuAll['total'] || 0) - (localData.ptuPlaced['total'] || 0);
                    },
                    ptuFromLocal: function () {
                        return (localData.ptuExtracted[localData.selectedStarSystem.number] || 0) + (localData.freePtu[localData.selectedStarSystem._id] || 0);
                    },
                    ptuPlacedInternal: function () {
                        return (localData.ptuPlacedInternal[localData.selectedStarSystem.number] || 0);
                    },
                    ptuPlacedInternalFree: function () {
                        return (localData.ptuPlacedInternalFree[localData.selectedStarSystem.number] || 0);
                    },
                    ptuPlaced: function () {
                        return localData.ptuPlaced;
                    },
                    ptuPlacedByBody: function () {
                        return localData.ptuPlacedByBody;
                    },
                    inSystemCfnPtu: function () {
                        return (localData.inSystemCfnPtu[localData.selectedStarSystem.number] || 0);
                    },
                    shippingUsage: function () {
                        return {
                            'baseCfnQ': localData.baseCfnQ,
                            'excessCfnQ': localData.overflowFleetsCounts.Q,
                            'cfnQUsed': (localData.cfnQUsed['total'] || 0),
                            'baseCfnH': localData.baseCfnH,
                            'excessCfnH': localData.overflowFleetsCounts.H,
                            'cfnHUsed': (localData.cfnHUsed['total'] || 0)
                        }
                    }
                }
            });

            modalInstance.result.then(
                function (colonization) {
                    cec.session.iAmBusy();
                    return EconomySvc.saveTransaction(colonization);
                }
            ).then(
                function( savedColonization ) {
                    cec.processSavedColonization( savedColonization );
                    assessFreightFees();
                    cec.session.iAmNotBusy();
                    $timeout();
                }
            ).catch(
                function () {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        cec.createExtraction = function (starSystem) {
            localData.selectedStarSystem = starSystem;
            var newExtraction = {
                campaignId: DataStoreSvc.getCampaign()._id,
                raceId: DataStoreSvc.getRace()._id,
                turn: cec.session.editTurn,
                type: 'colonization',
                quantity: 0,        // PTU (extraction will be negative)
                locator: '',        // source
                // source locator - star system number
                description: localData.selectedStarSystem._id,
                amount: 0
            };
            localData.extractionToEdit = newExtraction;
            cec.openExtraction();
        };

        cec.editExtraction = function (extraction, starSystem) {
            localData.selectedStarSystem = starSystem;
            localData.extractionToEdit = angular.copy(extraction);
            cec.openExtraction();
        };

        cec.openExtraction = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.colonization ' + parentSelector)) : undefined;

            var modalInstance = $ismModal.open({
                animation: cec.animationsEnabled,
                templateUrl: 'templates/extractionEdit.html',
                controller: 'ExtractionEdit',
                controllerAs: 'eec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    extractionToEdit: function () {
                        return localData.extractionToEdit;
                    },
                    starSystem: function () {
                        return localData.selectedStarSystem;
                    },
                    surveyedBodies: function () {
                        return localData.surveyedBodyByStarSystemNumber[localData.selectedStarSystem.number];
                    },
                    populationInformation: function () {
                        return localData.ssPopulationInfo[localData.selectedStarSystem._id];
                    }
                }
            });

            modalInstance.result.then(
                function (colonization) {
                    cec.session.iAmBusy();
                    return EconomySvc.saveTransaction(colonization);
                }
            ).then(
                function( savedColonization ) {
                    cec.processSavedColonization( savedColonization );
                    assessFreightFees();
                    cec.session.iAmNotBusy();
                    $timeout();
                }
            ).catch(
                function () {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        init();
    }
]).controller('ColonizationEdit', function ($ismModalInstance, colonizationToEdit, starSystem, surveyedBodies, populationInformationMap, availablePtu, ptuFromLocal, ptuPlacedInternal, ptuPlacedInternalFree, ptuPlaced, ptuPlacedByBody, inSystemCfnPtu, shippingUsage) {
    let cec = this;

    let setTransactionBase = function (transaction) {
        var transactionBase = {
            campaignId: transaction.campaignId,
            raceId: transaction.raceId,
            turn: transaction.turn,
            endTurn: transaction.turn + 1,
            type: 'colonization'
        };
    };

    let init = function() {
        // transaction
        cec.colonizationToEdit = colonizationToEdit;
        cec.turnsInTransit = cec.colonizationToEdit.endTurn - cec.colonizationToEdit.turn;

        cec.starSystem = starSystem;

        // Bodies with InformationPlanet records
        cec.surveyedBodies = surveyedBodies;

        // InformationPopulation records
        cec.populationInformationMap = populationInformationMap;

        // how much PTU CAN be used
        cec.maxPtu = availablePtu; // re-calced later
        cec.availablePtu = availablePtu;

        // how much came from THIS star system
        cec.ptuFromLocal = ptuFromLocal;

        // how much came from THIS star system and placed here with paid shipping
        cec.ptuPlacedInternal = ptuPlacedInternal;

        // how much came from THIS star system and placed here with free shipping
        cec.ptuPlacedInternalFree = ptuPlacedInternalFree;

        // how much placed in total
        cec.ptuPlaced = ptuPlaced;
        cec.ptuPlacedByBody = ptuPlacedByBody;

        // how much free placement is there for this system
        cec.inSystemCfnPtu = inSystemCfnPtu;

        cec.shippingUsage = shippingUsage;
        cec.hMultiplier = 1;

        if (cec.colonizationToEdit.quantity > 0) {
            cec.availablePtu += cec.colonizationToEdit.quantity;
            if (cec.colonizationToEdit.qInUse === 0 && cec.colonizationToEdit.hInUse === 0) {
                cec.ptuPlacedInternalFree -= cec.colonizationToEdit.quantity;
            }
            else {
                cec.ptuPlacedInternal -= cec.colonizationToEdit.quantity;
            }
        }

        if (cec.ptuFromLocal > 0) {
            if (cec.ptuPlacedInternalFree < cec.inSystemCfnPtu) {
                cec.mode = 1;
                cec.modeText = "Free placement via in-system CFN";
                cec.modePtuMax = Math.min(cec.availablePtu, cec.inSystemCfnPtu - cec.ptuPlacedInternalFree);
            }
            else {
                cec.mode = 2;
                cec.modeText = "Placement via in-system CFN";
                cec.modePtuMax = Math.min(cec.availablePtu, cec.ptuFromLocal - (cec.ptuPlacedInternalFree + cec.ptuPlacedInternal));
            }
        }
        else {
            cec.mode = 3;
            cec.modeText = "Placement via CFN";
            cec.modePtuMax = cec.availablePtu;
        }

        cec.costChangeQ = cec.shippingUsage.baseCfnQ
            - cec.shippingUsage.cfnQUsed + cec.colonizationToEdit.qInUse;
        cec.availableQ = cec.shippingUsage.baseCfnQ + cec.shippingUsage.excessCfnQ
            - cec.shippingUsage.cfnQUsed + cec.colonizationToEdit.qInUse;
        cec.costChangeH = cec.shippingUsage.baseCfnH
            - cec.shippingUsage.cfnHUsed + cec.colonizationToEdit.hInUse;
        cec.availableH = cec.shippingUsage.baseCfnH + cec.shippingUsage.excessCfnH
            - cec.shippingUsage.cfnHUsed + cec.colonizationToEdit.hInUse;

        if (cec.colonizationToEdit.bodyId) {
            cec.selectedBody = cec.surveyedBodies.find( body => body.bodyId === cec.colonizationToEdit.bodyId );
            cec.selectedStarSystemId = cec.selectedBody.starSystemId;
            cec.setSelectedBodyId();
        }

        if (cec.ptuFromLocal >= (cec.ptuPlacedInternal + cec.ptuPlacedInternalFree + cec.colonizationToEdit.quantity)) {
            cec.colonizationToEdit.cfnType = 'inSystem';
            cec.useFreePlacement = (cec.inSystemCfnPtu >= (cec.ptuPlacedInternalFree + cec.colonizationToEdit.quantity));
            if (cec.useFreePlacement) {
                cec.colonizationToEdit.qInUse = 0;
                cec.colonizationToEdit.hInUse = 0;
            }
        }
        else {
            cec.useFreePlacement = false;
            cec.colonizationToEdit.cfnType = 'interstellar';
        }
    };

    cec.setSelectedBodyId = function() {
        cec.colonizationToEdit.bodyId = cec.selectedBody.bodyId;
        cec.colonizationToEdit.locator = cec.selectedBody.locator;
        cec.colonizationToEdit.informationPopulationId = cec.populationInformationMap[cec.selectedBody.bodyId];
        let hd = cec.selectedBody.habitDifference;
        // Benign
        cec.hMultiplier = 1;
        // Extreme
        if (hd === 18) {
            cec.hMultiplier = 5;
        }
        // Desolate
        else if (hd === 12) {
            cec.hMultiplier = 4;
        }
        // Hostile
        else if (hd === 10) {
            cec.hMultiplier = 3;
        }
        // Harsh
        else if (hd < 10 && hd > 2) {
            cec.hMultiplier = 2;
        }

        let bodyMax = cec.populationInformationMap[cec.selectedBody.bodyId].maxPu
            - cec.populationInformationMap[cec.selectedBody.bodyId].pu
            - (cec.ptuPlacedByBody[cec.selectedBody.locator] || 0)
            + cec.colonizationToEdit.quantity;
        cec.maxPtuByH = Math.floor(cec.availableH / cec.hMultiplier / 10);
        cec.maxPtuByQ = Math.floor(cec.availableQ / 10);
        if (cec.mode === 3) {
            cec.maxPtu = Math.min(bodyMax, cec.modePtuMax, cec.maxPtuByQ, cec.maxPtuByH);
        }
        else {
            cec.maxPtu = Math.min(bodyMax, cec.modePtuMax);
        }

        if (cec.colonizationToEdit.quantity > cec.maxPtu) {
            cec.colonizationToEdit.quantity = cec.maxPtu;
        }

        cec.updatePtu();
    };

    cec.updatePtu = function() {
        cec.useFreePlacement = (cec.inSystemCfnPtu >= (cec.ptuPlacedInternalFree + cec.colonizationToEdit.quantity));
        cec.colonizationToEdit.cfnType = 'inSystem';
        if (cec.useFreePlacement) {
            cec.colonizationToEdit.qInUse = 0;
            cec.colonizationToEdit.hInUse = 0;
        }
        else {
            if (cec.ptuFromLocal < (cec.ptuPlacedInternalFree + cec.ptuPlacedInternal + cec.colonizationToEdit.quantity)) {
                cec.colonizationToEdit.cfnType = 'interstellar';
            }
            cec.colonizationToEdit.qInUse = cec.colonizationToEdit.quantity * 10;
            cec.colonizationToEdit.hInUse = cec.colonizationToEdit.quantity * cec.hMultiplier * 10;
        }

        cec.colonizationToEdit.amount = (cec.colonizationToEdit.quantity * (5 * (2 + cec.hMultiplier)) 
            + (cec.turnsInTransit * ((0.5 * cec.colonizationToEdit.hInUse) + cec.colonizationToEdit.qInUse)));
    };

    cec.updateColonization = function() {
        // destination, if established
        if (cec.colonizationToEdit.cfnType==='inSystem' && cec.useFreePlacement
                && cec.colonizationToEdit.quantity <= cec.inSystemCfnPtu) {
            cec.colonizationToEdit.qInUse = 0;
            cec.colonizationToEdit.hInUse = 0;
        }
        cec.colonizationToEdit.amount = (cec.colonizationToEdit.quantity * (5 * (2 + cec.hMultiplier)) 
            + (cec.turnsInTransit * ((0.5 * cec.colonizationToEdit.hInUse) + cec.colonizationToEdit.qInUse)));
    };

    cec.ok = function () {
        $ismModalInstance.close(cec.colonizationToEdit);
    };

    cec.cancel = function () {
        $ismModalInstance.dismiss('cancel');
    };

    init();
}).controller('ExtractionEdit', function ($ismModalInstance, EconomySvc, extractionToEdit, starSystem, surveyedBodies, populationInformation) {
    var eec = this;

    var init = function() {
        eec.extractionToEdit = extractionToEdit;
        eec.starSystem = starSystem;
        eec.surveyedBodies = surveyedBodies;
        eec.populationInformation = populationInformation.filter( popInfo => (popInfo.pu > 0) );
        if (eec.extractionToEdit.bodyId) {
            eec.selectedPopulation = eec.populationInformation.find(popInfo => (popInfo.planetId === eec.extractionToEdit.bodyId));
        }
        if (eec.extractionToEdit.quantity < 0) {
            let prelimPu = EconomySvc.ptuToPu(eec.selectedPopulation.ptu - eec.extractionToEdit.quantity);
            eec.selectedPu = - (eec.selectedPopulation.pu - prelimPu);
        }
        console.log('ExtractionEdit: ', eec);
    };

    eec.setSelectedPopulation = function() {
        eec.extractionToEdit.locator = eec.selectedPopulation.locator;
        eec.extractionToEdit.informationPopulationId = eec.selectedPopulation._id;
        eec.extractionToEdit.bodyId = eec.selectedPopulation.planetId;
        if (eec.selectedPu) {
            eec.setSelectedPu();
        }
    };

    eec.setSelectedPu = function() {
        eec.extractionToEdit.quantity = 0;
        if (eec.selectedPopulation) {
            let prelimPtu = EconomySvc.puToPtu( eec.selectedPopulation.pu + eec.selectedPu );
            eec.extractionToEdit.quantity = Math.min(eec.selectedPopulation.ptu - prelimPtu, 0);
        }
    };

    eec.ok = function () {
        $ismModalInstance.close(eec.extractionToEdit);
    };

    eec.cancel = function () {
        $ismModalInstance.dismiss('cancel');
    };

    init();
});
