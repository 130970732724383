angular.module('app').component('starSystemAdmin', {
    templateUrl: 'templates/starSystemComponentAdmin.html',
    bindings: {
        starSystem: '<',
        displayMoons: '<',
        populations: '<',
        races: '<',
        onTransitWarpPoint: '&'
    },
    controllerAs: 'ssac',
    controller: [
        'DataStoreSvc', 'StarSystemSvc', 'WarpPointSvc',
        function (DataStoreSvc, StarSystemSvc, WarpPointSvc) {
            let localData = {};
            let ssac = this;

            const passStarSystem = function (response) {
                localData.newStarSystem = response;
                DataStoreSvc.setStarSystemId(localData.newStarSystem._id);
                ssac.onTransitWarpPoint({ starSystemId: localData.newStarSystem._id });
            };

            ssac.$onInit = function () {
                console.log('ssac: ', ssac);
            };

            ssac.transitWarpPoint = function (starSystemId) {
                StarSystemSvc.getStarSystemAdmin(starSystemId).then(
                    passStarSystem
                );
            };

            ssac.exploreWarpPoint = function (warpPointId, starSystemNumber) {
                localStorage.removeItem('races');
                WarpPointSvc.exploreWarpPoint(warpPointId, starSystemNumber).then(
                    passStarSystem
                );
            };

            ssac.removeDestination = function (warpPoint) {
                WarpPointSvc.removeDestination(warpPoint._id).then(
                    function (results) {
                        delete warpPoint.destination;
                        delete warpPoint.destinationWarpPoint;
                        delete warpPoint.destinationStarSystem;
                        // console.log(results);
                    }
                );
            };
        }
    ]
});
