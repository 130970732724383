/* globals angular localStorage */
angular.module('app').controller('EconomicSummaryCtrl', [
    '$scope', '$timeout', 'DataStoreSvc', 'EconomySvc', 'TableSvc', 'InformationPopulationSvc', 'ProjectSvc', 'ShipClassSvc', 'RaceSvc',
    function ($scope, $timeout, DataStoreSvc, EconomySvc, TableSvc, InformationPopulationSvc, ProjectSvc, ShipClassSvc, RaceSvc) {
        'use strict';

        let esc = this;
        let localData = {
            busy: false,
            xValid: true,
            syValid: true,
            transactions: {},
            validFinalSubmit: false,
            userCanChangeMind: false,
            canFinalize: false,
            canReset: false,
            canSeeEconomicButtons: false,
            canSubmit: false,
            userCanChangeMind2: false
        };
        esc.summaryData = localData;
        esc.session = DataStoreSvc;

        let init = function () {
            esc.session.iAmBusy();
            console.log('esc', esc);
            localData.editNextLevel = esc.session.user.role === 'Master' || esc.session.user.role === 'Marshal';
            localData.race = esc.session.getRace();
            localData.isPlayerRace = localData.race.playerRace;
            localData.campaign = esc.session.getCampaign();
            localData.currentTurn = localData.campaign.turn;

            let turnActivated = localData.race.turnActivated || localData.race.turnCreated;
            if (esc.session.minTurn === turnActivated+1) {
                esc.summaryData.altMinTurn = esc.session.minTurn - 1;
            }

            let promises = [
                esc.updateTurn(esc.session.editTurn)
            ];
            if (esc.session.isFirstTurn) {
                if (!localData.government) {
                    localData.govPromise = esc.getGovernment();
                    promises.push(localData.govPromise);
                }
                if (!localData.infoPopPrimary) {
                    localData.popPromise = InformationPopulationSvc.getAllInformationPopulationForRace(localData.race._id).then(
                        function (infos) {
                            localData.infoPopPrimary = infos.find(
                                function (info) {
                                    return info.planetId === localData.race.homePlanetId;
                                }
                            )
                            return localData.infoPopPrimary;
                        }
                    )
                    promises.push(localData.popPromise);
                }
                if (!localData.project) {
                    let code = '';
                    if (localData.race.techLevel === -1 || localData.race.techLevel === 0) {
                        code = localData.race.techLevel === -1 ? '-0199' : '0099';
                    }
                    // if (localData.race.techLevel >= 0) {
                    else {
                        code = '0113';
                    }
                    localData.projPromise = ProjectSvc.getProjectByRaceAndCode(localData.race._id, code).then(
                        function (project) {
                            localData.project = project
                            return project;
                        }
                    )
                    promises.push(localData.projPromise);
                }
            }
            return Promise.all(promises).then(
                function(results) {
                    $timeout( function () {
                        esc.session.iAmNotBusy();
                    });
                    return "Done";
                }
            )
        };

        let processTransactionData = function (transactions) {
            localData.transactions = transactions;
            if (esc.session.isFirstTurn) {
                if (!localData.govPromise) {
                    localData.govPromise = esc.getGovernment();
                }
                return localData.govPromise.then( function (gov) {
                    let income = (transactions['popIncomeTotal'] || {}).amount;
                    let warshipsTotal = (transactions['WarshipsTotal'] || {}).amount || 0;
                    let defensesTotal = (transactions['DefensesTotal'] || {}).amount || 0;
                    let freightersTotal = (transactions['FreightersTotal'] || {}).amount || 0;
                    let populationTotal = (transactions['PopulationTotal'] || {}).amount || 0;
                    let iuCost = localData.race.techLevel > 0 ? income * gov.industrial / 100 : 0;
                    let grandTotal = warshipsTotal + defensesTotal + freightersTotal + populationTotal + iuCost;
                    transactions['GrandTotal'] = {
                        amount: grandTotal,
                        remainder: income - grandTotal
                    };
                    if (localData.race.techLevel > 0) {
                        // Segments are valid if they are within 10% of the budget
                        localData.warshipAllocation = Math.max((income * gov.warships / 100), (income * 0.10));
                        localData.validWarships = localData.isPlayerRace || Math.abs(1 - (warshipsTotal / localData.warshipAllocation)) < 0.10;

                        localData.defenseAllocation = Math.max((income * gov.defense / 100), (income * 0.10));
                        localData.validDefenses = localData.isPlayerRace || Math.abs(1 - (defensesTotal / localData.defenseAllocation)) < 0.10;

                        localData.freighterAllocation = Math.max((income * gov.freighters / 100), (income * 0.10));
                        localData.validFreighters = localData.isPlayerRace || Math.abs(1 - ((freightersTotal + populationTotal) / localData.freighterAllocation)) < 0.10;
                    }
                    else {
                        localData.validWarships = true;
                        localData.validFreighters = true;
                        localData.validDefenses = (1 - (defensesTotal / (income * 0.05))) < 0.05;
                    }
                    let finalBalancePercent = (grandTotal / income);
                    localData.validFinalBalance = localData.isPlayerRace ?
                        (finalBalancePercent > 0.975) && (finalBalancePercent < 1) :
                        grandTotal < income;
                    setStartupTurnFlags();
                    return "Done";
                });
            }
            else {
                localData.incomeTotal = 
                    ((transactions['popIncomeTotal'] || {}).amount || 0) +
                    ((transactions['tradeIncomeTotal'] || {}).amount || 0) +
                    ((transactions['leaseIncomeTotal'] || {}).amount || 0) +
                    ((transactions['shipSaleTotal'] || {}).amount || 0) +
                    ((transactions['otherIncomeTotal'] || {}).amount || 0);
                localData.expensesTotal = 
                    ((transactions['maintenanceTotal'] || {}).amount || 0) +
                    ((transactions['constructionTotal'] || {}).amount || 0) +
                    ((transactions['industrialTotal'] || {}).amount || 0) +
                    ((transactions['colonizationTotal'] || {}).amount || 0) +
                    ((transactions['techLevelTotal'] || {}).amount || 0) +
                    ((transactions['techItemTotal'] || {}).amount || 0) +
                    ((transactions['otherExpenseTotal'] || {}).amount || 0);
                localData.remainder = localData.incomeTotal - localData.expensesTotal;
                setStandardEconomicFlags();
                return "Done";
            }
        };

        let updateRace = function (raceChange) {
            RaceSvc.saveRace(raceChange).then(
                function (results) {
                    $timeout(function () {
                        esc.session.iAmNotBusy();
                        return "Done";
                    });
                }
            );
        };
        
        esc.getGovernment = function() {
            return TableSvc.getGovernmentById(localData.race.governmentId).then(
                function (government) {
                    localData.government = government;
                    return government;
                }
            );
        };

        esc.updateEconomicTurn = function (newTurn) {
            esc.session.iAmBusy();
            return esc.updateTurn(newTurn).then(
                function (result) {
                    $timeout( function () {
                        esc.session.iAmNotBusy();
                    });
                    return "Done";
                }
            );
        };

        esc.updateTurn = function (newTurn) {
            console.log('newTurn', newTurn);
            localData.transactions = null;
            return EconomySvc.getOrBuildTransactions(localData.race, newTurn).then( processTransactionData );
        };

        esc.validateStartUp = function () {
            esc.session.iAmBusy();
            let classes = ShipClassSvc.getShipClassesForRaceWithCount(localData.race).then(
                function (shipClasses) {
                    return shipClasses.reduce(function(dictionary, shipClass) {
                        dictionary[shipClass._id] = shipClass;
                        return dictionary;
                    }, {});
                }
            );
            Promise.all([classes]).then(
                function (results) {
                    localData.xValid = true;
                    localData.syValid = true;
                    let shipClasses = results[0];
                    let transactions = Object.values(localData.transactions['Warships'] || {}).concat(
                        Object.values(localData.transactions['Defenses'] || {}),
                        Object.values(localData.transactions['Freighters'] || {})
                    );
                    if (!localData.isPlayerRace) {
                        let xTotal = 0;
                        let syTotal = 0;
                        let freeSY = 0;
                        for (let i = 0; i < transactions.length; i++) {
                            let transaction = transactions[i];
                            if (transaction.locator === "FreeSY") {
                                freeSY = transaction.quantity;
                            }
                            else {
                                let shipClass = shipClasses[transaction.shipClassId];
                                xTotal += (transaction.quantity * shipClass.xCount);
                                syTotal += (transaction.quantity * shipClass.syCount);
                            }
                        }
                        localData.xValid = (localData.race.maxX >= xTotal);
                        localData.syValid = (freeSY <= syTotal);
                    }
                    let raceChange;
                    if (localData.xValid && localData.syValid) {
                        localData.race.readyForEconomicsTurn = localData.currentTurn;
                        raceChange = {
                            _id: localData.race._id,
                            readyForEconomicsTurn: localData.currentTurn
                        };
                    }

                    setStartupTurnFlags();

                    if (!raceChange) {
                        return null;
                    }
                    return updateRace(raceChange);
                }
            );
        };

        let setStartupTurnFlags = function () {
            localData.validFinalSubmit = localData.validWarships && localData.validDefenses &&
                localData.validFreighters && localData.validFinalBalance &&
                (localData.race.readyForEconomicsTurn < localData.currentTurn);
            localData.userCanChangeMind = (localData.race.readyForEconomicsTurn === localData.currentTurn) &&
                (localData.race.economicsCompleteTurn < localData.currentTurn);
            localData.canFinalize = localData.editNextLevel &&
                (localData.race.readyForEconomicsTurn === localData.currentTurn) &&
                (localData.race.economicsCompleteTurn < localData.currentTurn);
            localData.canReset = localData.editNextLevel && 
                (localData.race.economicsCompleteTurn === localData.currentTurn);
        };

        esc.changedMyMind = function () {
            esc.session.iAmBusy();
            localData.race.readyForEconomicsTurn--;
            setStartupTurnFlags();
            let raceChange = {
                _id: localData.race._id,
                readyForEconomicsTurn: localData.race.readyForEconomicsTurn
            };

            return updateRace(raceChange);
        };

        esc.finalizeStartUp = function () {
            esc.session.iAmBusy();
            return EconomySvc.performStartup(localData.race._id, localData.currentTurn).then(
                function (result) {
                    esc.session.setRace(result.data);
                    localData.race = result.data;
                    return esc.updateTurn(localData.currentTurn);
                }
            ).then(
                function (result) {
                    $timeout( function () {
                        esc.session.iAmNotBusy();
                    });
                    return "Done";
                }
            );
        };

        esc.rollbackStartUp = function () {
            esc.session.iAmBusy();
            return EconomySvc.rollbackStartup(localData.race._id, localData.currentTurn).then(
                function (result) {
                    esc.session.setRace(result.data);
                    localData.race = result.data;
                    return esc.updateTurn(localData.currentTurn);
                }
            ).then(
                function (result) {
                    $timeout( function () {
                        esc.session.iAmNotBusy();
                    });
                    return "Done";
                }
            );
        };

        esc.reset = function () {
            esc.session.iAmBusy();
            localData.race.readyForEconomicsTurn = localData.currentTurn - 1;
            localData.race.economicsCompleteTurn = localData.currentTurn - 1;
            setStartupTurnFlags();
            let raceChange = {
                _id: localData.race._id,
                readyForEconomicsTurn: localData.race.readyForEconomicsTurn,
                economicsCompleteTurn: localData.race.economicsCompleteTurn
            };
            return updateRace(raceChange);
        };

        let setStandardEconomicFlags = function () {
            localData.canSeeEconomicButtons = esc.session.editTurn <= localData.currentTurn + 1;
            localData.canSubmit = localData.race.readyForEconomicsTurn < localData.currentTurn;
            localData.userCanChangeMind2 = (localData.race.readyForEconomicsTurn === localData.currentTurn) &&
                (localData.race.economicsCompleteTurn < localData.currentTurn);
        };

        esc.economicsReady = function () {
            esc.updateReady(localData.currentTurn);
        };

        esc.economicsNotReady = function (readyTurn) {
            esc.updateReady(localData.race.readyForEconomicsTurn - 1);
        };

        esc.updateReady = function (readyTurn) {
            esc.session.iAmBusy();
            localData.race.readyForEconomicsTurn = readyTurn;
            setStandardEconomicFlags();
            let raceChange = {
                _id: localData.race._id,
                readyForEconomicsTurn: localData.race.readyForEconomicsTurn
            };
            return updateRace(raceChange);
        };

        init();
    }
]);
