/* global angular, localStorage */
angular.module('app').service('UserSvc', [
    '$http', '$location', '$cookies', '$q', 'DataStoreSvc',
    function ($http, $location, $cookies, $q, DataStoreSvc) {
        'use strict';

        var svc = this;
        svc.session = DataStoreSvc;

        var redirectToLogin = function () {
            $location.path('/login');
        };

        svc.getUser = function () {
            return $http.get('/api/user').then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getUserById = function (id) {
            return $http.get('/api/user/id/'+id).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getAllUsers = function () {
            return $http.get('/api/user/all').then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getSkyMarshals = function () {
            return $http.get('/api/user/marshals').then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.login = function (email, password, remember) {
            return $http.post('/api/session', {
                email: email,
                password: password
            }).then(function (result) {
                var user = result.data;
                var options = {};
                if (remember) {
                    var later = new Date();
                    later.setDate(later.getDate()+7);
                    options = {'expires' : later, 'samesite':'lax' };
                    $cookies.put('uid', user._id, options);
                }
                svc.session.setUser(user);
                return user;
            });
        };

        svc.createUser = function (first, last, email, password) {
            return $http.post('/api/user', {
                first: first,
                last: last,
                email: email,
                password: password
            }).then(function (data) {
                return svc.login(email, password);
            });
        };

        svc.updateUser = function (user_id, first, last, email, role, password) {
            var updatedUser = {
                first: first,
                last: last,
                email: email,
                role: role
            };
            if (password) {
                updatedUser.password = password;
            }
            return $http.put('/api/user/id/' + user_id, updatedUser);
        };

        svc.resetUserPassword = function (oldPassword, newPassword) {
            return $http.put('/api/user/' + svc.session.user._id + '/newPassword', {
                oldPassword: oldPassword,
                newPassword: newPassword
            })
            .then(function (result) {
                console.log('svc.resetUserPassword - result: ', result);
                let user = result.data;
                return svc.login(user.email, newPassword);
            });
        };

        svc.validateLoggedInUser = function () {
            // if no user of record, get (and set/return) the user
            if (svc.session.getUser() == null) {
                var userId = $cookies.get('uid');
    
                // if no userId - user must login
                if (userId == null) {
                    redirectToLogin();
                }
    
                return svc.getUserById(userId);
            }

            // We have a user, return it!
            return $q.when(svc.session.getUser());
        };
    }
]);
