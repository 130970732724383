/* globals angular localStorage */
angular.module('app').controller('RaceDisplayCtrl', [
    '$location', 'UserSvc', 'DataStoreSvc', 'RaceSvc', 'TableSvc',
    function ($location, UserSvc, DataStoreSvc, RaceSvc, TableSvc) {
        'use strict';

        var rdc = this;
        var localData = {};
        rdc.raceDisplayData = localData;
        rdc.session = DataStoreSvc;

        var init = function () {
            localData.editNextLevel = rdc.session.user.role === 'Master' || rdc.session.user.role === 'Marshal';
            localData.race = Object.assign({}, rdc.session.getRace());
            let p1 = TableSvc.getGovernmentById(localData.race.governmentId).then(
                function (government) {
                    localData.government = government;
                }
            );
            let p2 = UserSvc.getAllUsers().then(function (users) {
                localData.users = users;
                console.log('Race Display (rdc): ', rdc);
            });

            Promise.all([p1, p2]).then(
                function (results) {
                    return 'Done!'
                }
            )
        };

        rdc.saveRace = function () {
            RaceSvc.saveRace(localData.race).then(
                function (state) {
                    $location.path('/campaignDisplay');
                },
                function (err) {
                    localData.error = 'Unable to save race, please contact admin.';
                    $location.hash('#error');
                    $anchorScroll();
                }
            );
        };

        init();
    }
]);
