/* global angular localStorage */
angular.module('app').service('RaceSvc', function ($http, $location) {
    'use strict';

    var svc = this;

    svc.getAllRacesForCampaign = function (campaign) {
        let cachedRaces = localStorage.getItem('races');
        if (cachedRaces) {
            return Promise.resolve(JSON.parse(cachedRaces));
        }

        return $http.get('/api/race/campaign/' + campaign._id).then(
            function (response) {
                let races = response.data;
                localStorage.setItem('races', JSON.stringify(races));
                for (const race of races) {
                    localStorage.setItem(race._id, JSON.stringify(race));
                }
                return races;
            }
        );
    };

    svc.getRacesInCampaignForUser = function (campaign, user) {
        return $http.get('/api/race/' + campaign._id + '/' + user._id).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getControlledRaces = function (user) {
        return $http.get('/api/race/user/' + user._id).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.saveRace = function (race) {
        return $http.put('/api/race/' + race._id, race).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.deleteRace = function (raceId) {
        return $http.delete('/api/race/' + raceId).then(
            function (response) {
                return response.data;
            }
        );
    };
});
