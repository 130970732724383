angular.module('app').controller('ProjectListEdit', function ($ismModalInstance, projects, smallCraft, xoProjects, outfitToEdit, xoToEdit, shipClass, mode) {
    let plec = this;
    plec.projects = [...projects];
    plec.smallCraft = [...smallCraft];
    plec.xoProjects = [...xoProjects];
    plec.outfitToEdit = Object.assign({}, outfitToEdit);
    plec.xoToEdit = Object.assign({}, xoToEdit);
    // plec.shipClass = shipClass;
    plec.xoMountCount = shipClass.xo;
    plec.mgCount = shipClass.mgCount;
    plec.vCount = shipClass.vCount;
    plec.boatBayPoints = shipClass.boatBayPoints;
    plec.mode = mode;

    plec.pointsUsed = 0;
    plec.outfitPoints = 0;
    plec.boatBayPointsUsed = 0;
    plec.xoUsed = 0;

    let init = function () {
        plec.outfitPoints = plec.vCount;
        if (plec.mode === 'S') {
            plec.outfitPoints = (plec.mgCount || 0) * 200;
        }
        plec.onChange();
        plec.onBoatBayChange();
        plec.onXoChange();
    };

    let valueForNotationType = function (project, type) {
        let value = 0;
        let notations = project.notations.split(',').join('=').split('=');
        let index = notations.indexOf(type);
        if (index > -1) {
            value = notations[index + 1];
        }
        return value;
    };

    let calcProjectMax = function () {
        for (const project of plec.projects) {
            let cargoPointsPer = project.cargoPoints || 1;
            let allPointsNotUsed = plec.outfitPoints - plec.pointsUsed;
            let projectPointsUsed = ( plec.outfitToEdit[project.symbol] || 0 ) * cargoPointsPer;
            project.max = Math.trunc(( allPointsNotUsed + projectPointsUsed ) / cargoPointsPer );
        }
    };

    let calcSmallCraftMax = function () {
        for (const project of plec.smallCraft) {
            let value = valueForNotationType(project, 'tSC');
            project.max = Math.trunc(( plec.boatBayPoints - plec.boatBayPointsUsed + ( ( plec.outfitToEdit[project.symbol] || 0 ) * value)) / value );
        }
    };

    let calcXoProjectMax = function () {
        for (const project of plec.xoProjects) {
            project.xoMax = Math.trunc(( plec.xoMountCount - plec.xoUsed + ( ( plec.xoToEdit[project.symbol] || 0 ) * ( project.xoMountPoints || 0 ))) / ( project.xoMountPoints || 1 ));
        }
    };

    let isChangeValid = function (project, editObject) {
        let isChangeValid = true;
        if (project) {
            console.log('projectUnderReview', project);
            const count = editObject[project.symbol];
            isChangeValid = (!!count || count === 0);
        }
        return isChangeValid;
    };

    plec.onChange = function (projectUnderReview) {
        if (!isChangeValid(projectUnderReview, plec.outfitToEdit)) {
            return;
        }

        plec.pointsUsed = 0;
        for (const symbol in plec.outfitToEdit) {
            if (Object.hasOwnProperty.call(plec.outfitToEdit, symbol)) {
                const count = plec.outfitToEdit[symbol];
                let project = plec.projects.find(proj => proj.symbol === symbol);
                if (project) {
                    plec.pointsUsed += count * (project.cargoPoints || 1);
                }
                if (!count) {
                    delete plec.outfitToEdit[symbol];
                }
            }
        }
        calcProjectMax();
    };

    plec.onBoatBayChange = function (projectUnderReview) {
        if (!isChangeValid(projectUnderReview, plec.outfitToEdit)) {
            return;
        }

        plec.boatBayPointsUsed = 0;
        for (const symbol in plec.outfitToEdit) {
            if (Object.hasOwnProperty.call(plec.outfitToEdit, symbol)) {
                const count = plec.outfitToEdit[symbol];
                let project = plec.smallCraft.find(proj => proj.symbol === symbol);
                if (project) {
                    let value = valueForNotationType(project, 'tSC');
                    plec.boatBayPointsUsed += count * value;
                }
                if (!count) {
                    delete plec.outfitToEdit[symbol];
                }
            }
        }
        calcSmallCraftMax();
    };

    plec.onXoChange = function (projectUnderReview) {
        if (!isChangeValid(projectUnderReview, plec.xoToEdit)) {
            return;
        }

        plec.xoUsed = 0;
        let newEdit = {};
        for (const symbol in plec.xoToEdit) {
            if (Object.hasOwnProperty.call(plec.xoToEdit, symbol)) {
                const count = plec.xoToEdit[symbol];
                let project = plec.xoProjects.find(proj => proj.symbol === symbol);
                if (project) {
                    plec.xoUsed += count * (project.xoMountPoints || 1);
                }
                if (count) {
                    newEdit[symbol] = count;
                }
            }
        }
        plec.xoToEdit = newEdit;
        calcXoProjectMax();
    };

    plec.ok = function () {
        plec.onXoChange();
        let keys = Object.keys(plec.xoToEdit);
        if (keys.length > 0) {
            plec.outfitToEdit['XO'] = plec.xoToEdit;
        }
        $ismModalInstance.close(plec.outfitToEdit);
    };

    plec.cancel = function () {
        $ismModalInstance.dismiss('cancel');
    };

    init();
});
