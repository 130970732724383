/* globals angular localStorage */
angular.module('app').controller('TroopCtrl', [
    '$scope', '$timeout', 'DataStoreSvc', 'EconomySvc', 'TableSvc', 'InformationPopulationSvc', '$ismModal',
    function ($scope, $timeout, DataStoreSvc, EconomySvc, TableSvc, InformationPopulationSvc, $ismModal) {
        'use strict';

        let tc = this;
        var localData = {
            startUpTitle: 'Troops'
        };
        tc.pageData = localData;
        tc.session = DataStoreSvc;

        var init = function () {
            localData.race = DataStoreSvc.getRace();
            localData.readOnly = localData.race.turnActivated <= localData.race.readyForEconomicsTurn;

            // let planetInfoPromise = InformationPlanetSvc.getInformationPlanetsForRaceAndStarSystem(localData.race._id, localData.race.starSystemId);

            let infoPopPromise = InformationPopulationSvc.getInformationPopulationForRaceAndTurn(localData.race._id, localData.race.turnCreated).then(
                function (infoPops) {
                    localData.infoPops = infoPops;
                    localData.puTotal = infoPops.reduce( (total, pop) => total + pop.pu, 0);
                    return localData.infoPops;
                }
            );

            let tPromise = EconomySvc.getOrBuildTransactions(localData.race, localData.race.turnActivated).then(
                function (transactions) {
                    localData.transactionsById = transactions[localData.startUpTitle] || {};
                    localData.total = transactions[localData.startUpTitle + 'Total'] || { amount: 0 };
                    localData.pcfTransactions = {};
                    localData.pcfaTransactions = {};
                    for (const id in localData.transactionsById) {
                        let transaction = localData.transactionsById[id];
                        if (transaction.description === "PCF") {
                            localData.pcfTransactions[transaction.bodyId] = transaction;
                        }
                        else {
                            localData.pcfaTransactions[transaction.bodyId] = transaction;
                        }
                    }
                    return localData.transactions;
                }
            );

            let governmentPromise = TableSvc.getGovernmentById(localData.race.governmentId);
    
            Promise.all([infoPopPromise, tPromise, governmentPromise]).then(
                function(results) {
                    let infoPopulations = results[0];
                    let transactions = results[1];
                    localData.government = results[2];
                    let pcfMultiplier = localData.government.pcf / 100 * (localData.race.techLevel >= 1 ? 0.75 : 1);
                    let pcfaMultiplier = localData.government.pcf / 100 * (localData.race.techLevel >= 1 ? 0.25 : 0);

                    localData.pcfMax = Math.floor(localData.puTotal * (1 - (localData.government.ptu / 100)));

                    let populationHash = infoPopulations.reduce(
                        function (hash, ip) {
                            ip.freePcf = Math.round(ip.pu * pcfMultiplier);
                            ip.freePcfa = Math.round(ip.pu * pcfaMultiplier);
                            hash[ip.planetId] = ip;
                            return hash;
                        }, {}
                    );

                    localData.bodyData = Object.values(populationHash);
                    localData.populationHash = populationHash;

                    console.log('TroopCtrl: ', tc);

                    $scope.$apply();
                }
            )
        };

        let createNewPcfTransactionData = function (pcfType, bodyId) {
            return {
                campaignId: tc.session.getCampaign()._id,
                raceId: tc.session.getRace()._id,
                type: localData.startUpTitle,
                turn: localData.race.turnActivated,
                description: pcfType,
                size: tc.session.getRace().techLevel,
                bodyId: bodyId,
                quantity: 0,
                amount: 0
            };
        };

        tc.editTransaction = function (planetId, bias=0) {
            localData.selectedPcfTransaction = localData.pcfTransactions[planetId];
            if (localData.selectedPcfTransaction == null) {
                localData.pcfTransactionToEdit = createNewPcfTransactionData('PCF', planetId);
            }
            else {    
                localData.pcfTransactionToEdit = angular.copy(localData.selectedPcfTransaction);
            }

            localData.selectedPcfaTransaction = localData.pcfaTransactions[planetId];
            if (localData.selectedPcfaTransaction == null) {
                localData.pcfaTransactionToEdit = createNewPcfTransactionData('PCFa', planetId);
            }
            else {    
                localData.pcfaTransactionToEdit = angular.copy(localData.selectedPcfaTransaction);
            }

            if (bias === 0) {
                bias = localData.pcfTransactionToEdit.quantity + localData.pcfaTransactionToEdit.quantity;
            }
            localData.bias = bias
            tc.open();
        };

        tc.deleteTransactions = function (planetId) {
            let saves = [];
            let selectedTransaction = localData.pcfTransactions[planetId];
            if (selectedTransaction) {
                saves.push(tc.deleteTransaction(selectedTransaction));
            }

            selectedTransaction = localData.pcfaTransactions[planetId];
            if (selectedTransaction) {
                saves.push(tc.deleteTransaction(selectedTransaction));
            }

            Promise.all(saves).then(
                function (results) {
                    $timeout( function () {
                        return "Done";
                    })
                }
            )
        };

        tc.deleteTransaction = function (transaction) {
            let oldTransaction = localData.transactionsById[transaction._id] || {};
            localData.total.amount = localData.total.amount - (oldTransaction.amount || 0);
            delete localData.transactionsById[transaction._id];

            if (transaction.description === "PCF") {
                delete localData.pcfTransactions[transaction.bodyId];
            }
            else {
                delete localData.pcfaTransactions[transaction.bodyId];
            }

            return EconomySvc.deleteTransaction(transaction);
        };

        let savePcfTransaction = function (transaction) {
            let promise;
            if (transaction._id && transaction.quantity === 0 && transaction.amount === 0) {
                promise = tc.deleteTransaction(transaction);
            }
            else {
                let oldTransaction = localData.transactionsById[transaction._id];
                if (!oldTransaction || oldTransaction.quantity != transaction.quantity) {
                    promise = EconomySvc.saveTransaction(transaction).then( tc.processSavedTransaction );
                }
                else {
                    promise = Promise.resolve("Done");
                }
            }
            return promise;
        };

        tc.savePcfTransactions = function (pcfTransaction, pcfaTransaction) {
            Promise.all([
                savePcfTransaction(pcfTransaction),
                savePcfTransaction(pcfaTransaction)
            ]).then(
                function () { return "Done"; }
            );
        };

        tc.processSavedTransaction = function (transaction) {
            let oldTransaction = localData.transactionsById[transaction._id] || {};
            localData.total.amount = localData.total.amount - (oldTransaction.amount || 0);
            localData.total.amount = localData.total.amount + (transaction.amount || 0);

            localData.transactionsById[transaction._id] = transaction;
            if (transaction.description === "PCF") {
                localData.pcfTransactions[transaction.bodyId] = transaction;
            }
            else {
                localData.pcfaTransactions[transaction.bodyId] = transaction;
            }
        };

        localData.animationsEnabled = true;

        tc.open = function (size, parentSelector) {
            let parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.startUpTroops ' + parentSelector)) : undefined;

            let bodyId = localData.pcfTransactionToEdit.bodyId;
            let infoPop = localData.populationHash[bodyId];

            let modalInstance = $ismModal.open({
                animation: localData.animationsEnabled,
                templateUrl: 'templates/troopEditModal.html',
                controller: 'TroopEditCtrl',
                controllerAs: 'tec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    pcfTransaction: function () {
                        return localData.pcfTransactionToEdit;
                    },
                    pcfaTransaction: function () {
                        return localData.pcfaTransactionToEdit;
                    },
                    pcfCost: function () {
                        let cost = localData.race.techLevel + 3;
                        return cost;
                    },
                    maxPcf: function () {
                        return infoPop.pu - infoPop.freePcf - infoPop.freePcfa;
                    },
                    populationsAvailable: function () {
                        return [];
                    },
                    bias: function () {
                        return localData.bias;
                    },
                    informationPopulation: function () {
                        return infoPop;
                    }
                }
            });

            modalInstance.result.then(
                function (transactions) {
                    tc.savePcfTransactions(transactions.pcf, transactions.pcfa);
                }
            ).catch(
                function (err) {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        init();
    }
]);
