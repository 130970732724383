/* globals angular localStorage */
angular.module('app').controller('NegotiationCtrl', [
    '$scope', 'DataStoreSvc', 'RaceSvc', 'UserSvc', 'InformationRaceSvc',
    function ($scope, DataStoreSvc, RaceSvc, UserSvc, InformationRaceSvc) {
        'use strict';

        var nc = this;
        var localData = {
        };
        nc.pageData = localData;
        nc.session = DataStoreSvc;

        var init = function () {
            let p1 = InformationRaceSvc.getAllInformationRaceForRace(nc.session.race._id).then(
                function (informationRaces) {
                    localData.informationRacesAll = informationRaces;
                    localData.informationRacesNonComm = informationRaces.filter(
                        infoRace => infoRace.communicationState != "Full" && !infoRace.politicalState
                    );
                    localData.informationRacesComm = informationRaces.filter(
                        infoRace => infoRace.communicationState === "Full"
                    );
                    localData.informationRacesTickedOff = informationRaces.filter(
                        infoRace => infoRace.communicationState === "Impossible"
                    );
                }
            );

            let p2 = RaceSvc.getAllRacesForCampaign(DataStoreSvc.campaign).then(
                function (results) {
                    localData.allRaces = results;
                    localData.hashRaces = localData.allRaces.reduce((hash,race)=>(hash[race._id] = race, hash), {})
                }
            );

            return Promise.all([p1, p2]);
        }

        // nc.func = function () {
        // };

        init().then( function () {
            console.log('NegotiationCtrl: ', localData);
        });
    }
]);
