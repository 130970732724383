angular.module('app').component('cship', {
    templateUrl: 'templates/cShipComponent.html',
    bindings: {
        cship: '<',
        // cShipSave: '&?'
    },
    controllerAs: 'csc',
    controller: [
        '$timeout', '$http', 'SsdParserSvc',
        function CShipController($timeout, $http, SsdParserSvc) {
            let csc = this;
            let localData = {
                damage: []
            };
            csc.pageData = localData;

            csc.$onInit = function() {
            };

            csc.$onChanges = function(changes) {
                if (changes.cship && changes.cship.currentValue) {
                    $timeout( function () {
                        localData.items = SsdParserSvc.parseSsd(csc.cship.ssd);
                        let damageOverlay = csc.cship.damageOverlay;
                        if (damageOverlay) {
                            localData.damage = damageOverlay.split('');
                        }
                        else {
                            localData.damage.length = localData.items.length
                            localData.damage.fill('.');
                            localData.damage[2]='/';
                        }
                        // if (localData.items.length != localData.damage.length) {
                        // }
                    });
                }
            };

            var init = function () {
                console.log('csc:', csc);
            };

            // function identifyStarSurveyElements (star) {
            // };

            init();
        }
    ]
});