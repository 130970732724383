angular.module('app').provider('StartUpOptions', function StartUpOptionsProvider() {
    this.warships = "Warships";
    this.freighters = "Freighters";
    this.defenses = "Defenses";

    this.warshipFilter = function (shipClass) {
        let freighterFlag = shipClass.freighterFlag||0;
        return (shipClass.hullClass.indexOf('BS') === -1) &&
                (shipClass.hullClass != 'AF') &&
                (shipClass.hullClass != 'SS') &&
                (shipClass.hullClass.indexOf('FT') === -1) &&
                (freighterFlag != 1);
    };

    this.freighterFilter = function (shipClass) {
        return (shipClass.hullClass.indexOf('FT') > -1) ||
               (shipClass.freighterFlag||0 === 1);
    };

    this.defenseFilter = function (shipClass) {
        return (shipClass.hullClass.indexOf('BS') > -1) ||
               (shipClass.hullClass === 'AF') ||
               (shipClass.hullClass === 'SS');
    };

    this.$get = function() {
        return this;
    };

    return this;
});
