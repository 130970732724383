/* global angular, localStorage */
angular.module('app').controller('CampaignCtrl', [
    '$location', '$timeout', 'UserSvc', 'CampaignSvc', 'DataStoreSvc', 'TableSvc', 'UtilitySvc',
    function ($location, $timeout, UserSvc, CampaignSvc, DataStoreSvc, TableSvc, UtilitySvc) {
        'use strict';
        let cc = this;

        let onRegisterApi = function (gridApi) {
            gridApi.core.registerColumnsProcessor(hideTableTypeColumn);
            cc.gridApi = gridApi;
      
            function hideTableTypeColumn(columns) {
                columns.forEach(function(column) {
                  if (column.field === 'tableType') {
                    column.visible=false;
                  }
                });
                return columns;
            }
        };

        let localData = {
            systemTypesGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                onRegisterApi: onRegisterApi,
                data: []
            },
            starTypesGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                columnDefs: [
                    { name: 'low', displayName: 'Low', type: 'number', width: 70, headerCellClass: 'text-center', cellClass: 'text-center' },
                    { name: 'high', displayName: 'High', type: 'number', width: 70, headerCellClass: 'text-center', cellClass: 'text-center' },
                    { name: 'type', displayName: 'Type', type: 'string', width: 150, headerCellClass: 'text-center' },
                    { name: 'diameter', displayName: 'Dia.', headerTooltip: 'Diameter', type: 'number', width: 70, headerCellClass: 'text-center', cellClass: 'text-center' },
                    { name: 'wpModifier', displayName: 'WPMod', headerTooltip: 'Warp Point Modifier', type: 'number', width: 120, cellClass: 'text-center' },
                    { name: 'rockyMax', displayName: 'Rocky Max', type: 'number', width: 120, headerCellClass: 'text-center', cellClass: 'text-center' },
                    { name: 'gasMin', displayName: 'GMin', headerTooltip: 'Gas Minimum', type: 'number', width: 100, headerCellClass: 'text-center', cellClass: 'text-center' },
                    { name: 'gasMax', displayName: 'GMax', headerTooltip: 'Gas Maximum', type: 'number', width: 100, headerCellClass: 'text-center', cellClass: 'text-center' },
                    { name: 'iceMin', displayName: 'IMin', headerTooltip: 'Ice Minimum', type: 'number', width: 100, headerCellClass: 'text-center', cellClass: 'text-center' },
                    { name: 'biosphereMin', displayName: 'BioMin', headerTooltip: 'Biosphere Minimum', type: 'number', width: 140, headerCellClass: 'text-center', cellClass: 'text-center' },
                    { name: 'biosphereMax', displayName: 'BioMax', headerTooltip: 'Biosphere Maximum', type: 'number', width: 140, headerCellClass: 'text-center', cellClass: 'text-center' },
                    { name: 'tideLockMax', displayName: 'TMax', headerTooltip: 'Tidelock Maximum', type: 'number', width: 130, headerCellClass: 'text-center', cellClass: 'text-center' },
                    { name: 'maxOrbit', displayName: 'Max Orbit', type: 'number', width: 110, headerCellClass: 'text-center', cellClass: 'text-center' },
                    { name: 'info', displayName: 'Info', type: 'string', width: 150, headerCellClass: 'text-left', cellClass: 'text-left' },
                ],
                data: []
            },
            binaryDistancesGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                onRegisterApi: onRegisterApi,
                data: []
            },
            rockyPlanetTypesGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                onRegisterApi: onRegisterApi,
                data: []
            },
            planetMoonCountModifiersGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                onRegisterApi: onRegisterApi,
                data: []
            },
            moonCountsGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                onRegisterApi: onRegisterApi,
                data: []
            },
            warpPointNumbersGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                onRegisterApi: onRegisterApi,
                data: []
            },
            warpPointTypesGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                onRegisterApi: onRegisterApi,
                data: []
            },
            warpPointDistancesGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                columnDefs: [
                    { name: 'low', displayName: 'Low', type: 'number', width: 100 },
                    { name: 'high', displayName: 'High', type: 'number', width: 100 },
                    { name: 'hexDistance', displayName: 'Hex Distances []', type: 'string' }
                ],
                data: []
            },
            civilizationProbabilitiesGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                onRegisterApi: onRegisterApi,
                data: []
            },
            populationSizesGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                onRegisterApi: onRegisterApi,
                data: []
            },
            governmentGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                columnDefs: [
                    { name: 'governmentId', displayName: 'ID', type: 'number', enableCellEdit: false, width: 65 },
                    { name: 'statLookup', displayName: 'STAT', type: 'string', enableCellEdit: false, width: 70 },
                    { name: 'low', displayName: 'Low', type: 'number', width: 65 },
                    { name: 'high', displayName: 'High', type: 'number', width: 70 },
                    { name: 'name', displayName: 'Name', type: 'string', width: 150 },
                    { name: 'contact', displayName: 'Contact', type: 'number', width: 90 },
                    { name: 'trade', displayName: 'Trade', type: 'number', width: 75 },
                    { name: 'military', displayName: 'Mil', type: 'number', width: 65 },
                    { name: 'partnership', displayName: 'Part', type: 'number', width: 65 },
                    { name: 'amalgation', displayName: 'Amal', type: 'number', width: 75 },
                    { name: 'warships', displayName: 'Warships', type: 'number', width: 100 },
                    { name: 'defense', displayName: 'Defense', type: 'number', width: 95 },
                    { name: 'industrial', displayName: 'IU', type: 'number', width: 65 },
                    { name: 'freighters', displayName: 'Freighters', type: 'number', width: 105 },
                    { name: 'pcf', displayName: 'PCF', type: 'number', width: 65 },
                    { name: 'grade', displayName: 'Grade', type: 'string', width: 80 },
                    { name: 'shipyard', displayName: 'SY', type: 'number', width: 65 }
                ],
                data: []
            },
            systemExploitationsGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                onRegisterApi: onRegisterApi,
                data: []
            },
            toHitGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                columnDefs: [
                    { name: 'name', displayName: 'Name', type: 'string', enableCellEdit: false, width: 150 },
                    { name: 'toHit', displayName: 'To Hit Array', type: 'string', enableCellEdit: true },
                ],
                data: []
            },
            economicLevelResearchGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                columnDefs: [
                    { name: 'code', displayName: 'Code', type: 'string', enableCellEdit: false, width: 80 },
                    { name: 'cost', displayName: 'Cost', width: 80 },
                    { name: 'developmentCost', displayName: 'Dev Cost', type: 'number', width: 100 },
                    { name: 'developmentPoints', displayName: 'Dev Points', type: 'number', width: 120 },
                    { name: 'description', displayName: 'Description', type: 'string', width: 300 },
                    { name: 'symbol', displayName: 'Symbol', type: 'string', width: 100 },
                    { name: 'techLevel', displayName: 'Tech Level', type: 'number', width: 120 },
                    { name: 'notations', displayName: 'Notations', type: 'string', width: 120 }
                ],
                data: []
            },
            damageGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                columnDefs: [
                    { name: 'name', displayName: 'Name', type: 'string', enableCellEdit: false, width: 150 },
                    { name: 'damage', displayName: 'Damage Array', enableCellEdit: true },
                ],
                data: []
            },
            projectItemsGridOptions: {
                enableSorting: false,
                enableColumnMenus: false,
                fastWatch: true,
                columnDefs: [
                    { name: 'code', displayName: 'Code', type: 'string', enableCellEdit: false, width: 80 },
                    { name: 'cost', displayName: 'Cost', width: 80 },
                    { name: 'hullSpace', displayName: 'Hull Space', type: 'number', width: 110 },
                    { name: 'developmentCost', displayName: 'Dev Cost', type: 'number', width: 100 },
                    { name: 'developmentPoints', displayName: 'Dev Points', type: 'number', width: 120 },
                    { name: 'description', displayName: 'Description', type: 'string', width: 300 },
                    { name: 'symbol', displayName: 'Symbol', type: 'string', width: 100 },
                    { name: 'techLevel', displayName: 'Tech Level', type: 'number', width: 120 },
                    { name: 'notations', displayName: 'Notations', type: 'string', width: 120 }
                ],
                data: []
            },
            pages: {}
        };
        cc.pageData = localData;
        cc.session = DataStoreSvc;

        var init = function () {
            UserSvc.getSkyMarshals().then(function (users) {
                localData.users = users;
            });
            localData.user = cc.session.getUser();
            localData.editNextLevel = localData.user.role === 'Master' || localData.user.role === 'Marshal';
            localData.campaign = cc.session.getCampaign();
            let dataLoading = [];
            if (localData.campaign == null) {
                localData.name = "New Campaign";
                localData.skymarshals = [];
                localData.turn = 0;
                localData.growthTurn = 12;
                localData.systemSize = 5000;
                localData.playerIncome = 50000;
                localData.playerMultiplier = 6;
                localData.freeSyPerMc = 300;
                localData.nprIncome = 30000;
                localData.nprMultiplier = 4;
                localData.perTurns = 6;
                localData.maximum = 10;
             }
             else {
                localData.id = localData.campaign._id;
                localData.name = localData.campaign.name;
                localData.skymarshals = localData.campaign.skyMarshals;
                localData.turn = localData.campaign.turn;
                localData.growthTurn = localData.campaign.growthTurn;
                localData.systemSize = localData.campaign.systemSize;
                localData.playerIncome = localData.campaign.playerIncome;
                localData.playerMultiplier = localData.campaign.playerStartupMultiplier;
                localData.freeSyPerMc = localData.campaign.freeSyPerMc;
                localData.nprIncome = localData.campaign.nprIncome;
                localData.nprMultiplier = localData.campaign.nprStartupMultiplier;
                localData.perTurns = localData.campaign.nprStartupBonusPerTurns;
                localData.maximum = localData.campaign.nprStartupMultiplierMax;

                dataLoading.push(
                    TableSvc.getTableDataForType('systemTypes').then(function (results) {
                        localData.systemTypesGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('starTypes').then(function (results) {
                        localData.starTypesGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('binaryDistances').then(function (results) {
                        localData.binaryDistancesGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('rockyPlanetTypes').then(function (results) {
                        localData.rockyPlanetTypesGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('planetMoonCountModifiers').then(function (results) {
                        localData.planetMoonCountModifiersGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('moonCounts').then(function (results) {
                        localData.moonCountsGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('warpPointNumbers').then(function (results) {
                        localData.warpPointNumbersGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('warpPointTypes').then(function (results) {
                        localData.warpPointTypesGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('warpPointDistances').then(function (results) {
                        localData.warpPointDistancesGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('civilizationProbabilities').then(function (results) {
                        localData.civilizationProbabilitiesGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('populationSizes').then(function (results) {
                        localData.populationSizesGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('governments').then(function (results) {
                        localData.governmentGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('systemExploitations').then(function (results) {
                        localData.systemExploitationsGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('toHit').then(function (results) {
                        localData.toHitGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('damage').then(function (results) {
                        localData.damageGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('projectItems').then(function (results) {
                        localData.projectItemsGridOptions.data = results;
                    }),
                    TableSvc.getTableDataForType('economicItems').then(function (results) {
                        localData.economicLevelResearchGridOptions.data = results;
                    })
                )
            }
            Promise.all(dataLoading).then(
                function (results) {
                    $timeout( function () {
                        localData.pages = {
                            1: {
                                title: "System Types",
                                gridOptions: localData.systemTypesGridOptions,
                                tableType: 'systemTypes'
                            },
                            2: {
                                title: "Star Types",
                                gridOptions: localData.starTypesGridOptions,
                                tableType: 'starTypes'
                            },
                            3: {
                                title: "Binary Distances",
                                gridOptions: localData.binaryDistancesGridOptions,
                                tableType: 'binaryDistances'
                            },
                            4: {
                                title: "Rocky Planet Types",
                                gridOptions: localData.rockyPlanetTypesGridOptions,
                                tableType: 'rockyPlanetTypes'
                            },
                            5: {
                                title: "Planet Moon Count Modifiers",
                                gridOptions: localData.planetMoonCountModifiersGridOptions,
                                tableType: 'planetMoonCountModifiers'
                            },
                            6: {
                                title: "Moon Counts",
                                gridOptions: localData.moonCountsGridOptions,
                                tableType: 'moonCounts'
                            },
                            7: {
                                title: "Warp Point Numbers",
                                gridOptions: localData.warpPointNumbersGridOptions,
                                tableType: 'warpPointNumbers'
                            },
                            8: {
                                title: "Warp Point Types",
                                gridOptions: localData.warpPointTypesGridOptions,
                                tableType: 'warpPointTypes'
                            },
                            9: {
                                title: "Warp Point Distances",
                                gridOptions: localData.warpPointDistancesGridOptions,
                                tableType: 'warpPointDistances'
                            },
                            10: {
                                title: "Civilization Probabilities",
                                gridOptions: localData.civilizationProbabilitiesGridOptions,
                                tableType: 'civilizationProbabilities'
                            },
                            11: {
                                title: "Population Sizes",
                                gridOptions: localData.populationSizesGridOptions,
                                tableType: 'populationSizes'
                            },
                            12: {
                                title: "Governments",
                                gridOptions: localData.governmentsGridOptions,
                                tableType: 'governments'
                            },
                            13: {
                                title: "System Exploitations",
                                gridOptions: localData.systemExploitationsGridOptions,
                                tableType: 'systemExploitations'
                            },
                            14: {
                                title: "Economic Level Research",
                                gridOptions: localData.economicLevelResearchGridOptions,
                                tableType: 'economicItems'
                            },
                            15: {
                                title: "To Hit",
                                gridOptions: localData.toHitGridOptions,
                                tableType: 'toHit'
                            },
                            16: {
                                title: "Damage",
                                gridOptions: localData.damageGridOptions,
                                tableType: 'damage'
                            },
                            17: {
                                title: "Project Items",
                                gridOptions: localData.projectItemsGridOptions,
                                tableType: 'projectItems'
                            }
                        }
                        console.log('Campaign (cc): ', cc);
                    });
                }
            )
        };

        cc.saveCampaign = function () {
            var campaignPromise;
            if (localData.id == null) {
                campaignPromise = CampaignSvc.createCampaign(localData.name, localData.skymarshals, localData.turn, localData.growthTurn, localData.systemSize, localData.playerIncome, localData.playerMultiplier, localData.freeSyPerMc, localData.nprIncome, localData.nprMultiplier, localData.perTurns, localData.maximum);
            }
            else {
                campaignPromise = CampaignSvc.updateCampaign(localData.id, localData.name, localData.skymarshals, localData.turn, localData.growthTurn, localData.systemSize, localData.playerIncome, localData.playerMultiplier, localData.freeSyPerMc, localData.nprIncome, localData.nprMultiplier, localData.perTurns, localData.maximum);
            }
            campaignPromise.then(
                function (campaign) {
                    $location.path('/');
                }
            ).catch(
                function (err) {
                    localData.error = 'Unable to save, please contact admin.';
                    $timeout(function () {
                        var elmnt = document.getElementById("error");
                        elmnt.scrollIntoView();
                    });
                }
            );
        };

        cc.saveTableDataForType = function (tableData, tableType) {
            TableSvc.saveTableDataForType(tableData, tableType).then(
                function (result) {
                    // clear dirty flag
                    tableData[0].tableType = tableType;
                }
            )
        };

        // TODO: [FNG-29] Add Delete-AreYouSureDialog for ONLY customised tables
        cc.deleteTableDataForType = function (tableType) {
            TableSvc.deleteTableDataForType(tableType).then(
                function (result) {
                    localData[tableType + 'GridOptions'].data = result;
                }
            )
        };

        cc.revertChangesForTableType = function (tableType) {
            TableSvc.getTableDataForType(tableType).then(
                function (result) {
                    localData[tableType + 'GridOptions'].data = result;
                }
            )
        };

        cc.scrollToTop = function () {
            $timeout(function () {
                UtilitySvc.scrollToTop(50);
            });
        };

        init();
    }
]);
