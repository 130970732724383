angular.module('app').controller('ResetPasswordCtrl', [
    '$scope', '$location', '$timeout', 'UserSvc',
    function ($scope, $location, $timeout, UserSvc) {
        'use strict';
        var rpc = this;
        var localData = {
            oldPassword: null,
            newPassword: null,
            newPassword2: null
        };
        rpc.pageData = localData;

        rpc.resetUserPassword = function () {
            delete localData.error;
            UserSvc.resetUserPassword(localData.oldPassword, localData.newPassword).then(
                function (user) {
                    $scope.$emit('login', user);
                    $location.path('/');
                }
            )
            .catch(
                function (err) {
                    console.log('error: ', err)
                    localData.error = err.data.errmsg || 'Unable to save, please contact admin.';
                    $timeout( function () {
                        var elmnt = document.getElementById("error");
                        elmnt.scrollIntoView();
                    });
                }
            );
        };
    }
]);
