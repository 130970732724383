/* globals angular localStorage */
angular.module('app').controller('TechItemResearchCtrl', [
    '$timeout', 'DataStoreSvc', 'EconomySvc', 'ProjectSvc',
    function ($timeout, DataStoreSvc, EconomySvc, ProjectSvc) {
        'use strict';

        var tirc = this;
        var localData = {
            history: [],
            started: [],
            unstarted: [],
            itemHash: {},
            total: 0
        };
        tirc.pageData = localData;
        tirc.session = DataStoreSvc;

        let init = function () {
            localData.editNextLevel = tirc.session.user.role === 'Master' || tirc.session.user.role === 'Marshal';
            localData.race = tirc.session.getRace();
            let p1 = ProjectSvc.getProjectItemsForRace(localData.race._id).then(
                function (items) {
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        if (item.state === "completed") {
                            localData.history.push(item);
                        }
                        else if (item.state === "started") {
                            localData.started.push(item);
                            localData.itemHash[item.code] = item;
                        }
                        else {
                            localData.unstarted.push(item);
                            localData.itemHash[item.code] = item;
                        }
                    }
                }
            );

            let p2 = EconomySvc.getTransactionData(localData.race._id, tirc.session.editTurn).then(
                function (transactions) {
                    localData.transactions = transactions;
                    localData.techItemTotal = (transactions['techItemTotal'] || {}).amount || 0;
                    localData.incomeTotal = 
                        ((transactions['popIncomeTotal'] || {}).amount || 0) +
                        ((transactions['tradeIncomeTotal'] || {}).amount || 0) +
                        ((transactions['leaseIncomeTotal'] || {}).amount || 0) +
                        ((transactions['shipSaleTotal'] || {}).amount || 0) +
                        ((transactions['otherIncomeTotal'] || {}).amount || 0);
                    localData.expensesTotal = 
                        ((transactions['maintenanceTotal'] || {}).amount || 0) +
                        ((transactions['constructionTotal'] || {}).amount || 0) +
                        ((transactions['industrialTotal'] || {}).amount || 0) +
                        ((transactions['colonizationTotal'] || {}).amount || 0) +
                        ((transactions['techLevelTotal'] || {}).amount || 0) +
                        // ((transactions['techItemTotal'] || {}).amount || 0) +
                        ((transactions['otherExpenseTotal'] || {}).amount || 0);
                    let ieData = Object.values(localData.transactions['techItem']||{});
                    ieData.forEach( data => tirc.processSavedData(data));
                }
            );

            Promise.all( [ p1, p2 ] ).then(
                function () {
                    console.log('TechItemResearchCtrl: ', tirc);
                    $timeout();
                }
            );
        };

        let getNewEconomicData = function (code) {
            var newEconomicData = {
                campaignId: DataStoreSvc.getCampaign()._id,
                raceId: DataStoreSvc.getRace()._id,
                turn: tirc.session.editTurn,
                type: 'techItem',
                quantity: 1,
                amount: 0,          // actual cost (from table entry)
                locator: code,        // project code
                description: 'Cost to develop a technical item'
            };
            return newEconomicData;
        };

        tirc.start = function (code) {
            let item = localData.itemHash[code];
            item.turnStarted = tirc.session.editTurn;
            let economicData = getNewEconomicData(code);
            economicData.amount = item.startUpFee;

            return EconomySvc.saveTransaction(economicData).then(
                function( savedEconomicData ) {
                    tirc.processSavedData( savedEconomicData );
                    return ProjectSvc.updateProjectById(item._id, {turnStarted: item.turnStarted});
                }
            ).then(
                function( savedProject ) {
                    return savedProject;
                }
            ).catch(
                function (err) {
                    console.log('err: ' + err.stack);
                }
            );
        };

        tirc.delete = function (code) {
            let item = localData.itemHash[code];
            item.turnStarted = 0;
            return ProjectSvc.updateProjectById(item._id, {turnStarted: item.turnStarted}).then(
                function (updatedProject) {
                    let originalTransaction = localData.transactions['processed'][updatedProject.code];
                    delete localData.transactions['processed'][updatedProject.code];
                    localData.total = (localData.total || 0) - originalTransaction.amount;
                    return EconomySvc.deleteTransaction(originalTransaction);
                }
            ).then(
                function( savedEconomicData ) {
                    // YEAH! it's gone!
                }
            ).catch(
                function (err) {
                    console.log('err: ' + err.stack);
                }
            );
        };

        tirc.processSavedData = function (savedData) {
            let transactions = localData.transactions['processed'] || {};
            let originalTransaction = transactions[savedData.locator];
            transactions[savedData.locator] = savedData;
            localData.transactions['processed'] = transactions;

            if (originalTransaction) {
                localData.total = (localData.total || 0) - originalTransaction.amount;
            }

            localData.total = (localData.total || 0) + savedData.amount;
        };

        init();
    }
]);
