angular.module('app').directive('scrollToHere', [
    '$location', '$anchorScroll', '$timeout',
    function($location, $anchorScroll, $timeout) {
        return {
            restrict: 'A',
            link: function(scope, element, attr) {
                element.on('click', function() {
                    $location.hash(attr['id']);
                    $timeout( function () {
                        $anchorScroll();
                    });
                });
            }
        }
    }
]);
