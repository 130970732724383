angular.module('app').controller('UserCtrl', [
    '$location', '$timeout', 'UserSvc', 'DataStoreSvc',
    function ($location, $timeout, UserSvc, DataStoreSvc) {
        'use strict';
        let uc = this;
        let localData = {};
        uc.userData = localData;
        uc.session = DataStoreSvc;

        let init = function () {
            localData.editUser = Object.assign({}, DataStoreSvc.getEditUser());
        };

        uc.updateUser = function (first, last, email, role) {
            UserSvc.updateUser(localData.editUser._id, first, last, email, role).then(
                function (result) {
                    localData.success = "User saved successfully!";
                    $timeout(
                        function () {
                            localData.success = "";
                        },
                        5000
                    )
                },
                function (err) {
                    localData.error = err.data.errmsg || 'Unable to save, please contact admin.';
                    if (err.data.errmsg && err.data.errmsg.indexOf('duplicate key') > -1) {
                        localData.error = 'Email already registered.';
                    }
                    $timeout( function () {
                        let elmnt = document.getElementById("error");
                        elmnt.scrollIntoView();
                    });
                }
            );
        };

        init();
    }
]);