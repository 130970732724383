angular.module('app').filter('jsonKeyValue', function () {
    let stringFromObject = function (hashObj) {
        let separation = '';
        let out = '';
        let keys = Object.keys(hashObj);
        let key;
        for (var i = 0; i < keys.length; i++) {
            key = keys[i];
            let value = hashObj[key];
            if (angular.isObject(value)) {
                out = out + separation + key + ': {' + stringFromObject(value) + '}';
            }
            else {
                out = out + separation + key + ': ' + value;
            }
            separation = ', ';
        }
        return out;
    };

    return function (input) {
        let parsed = JSON.parse(input || '{}');
        let out = stringFromObject(parsed);
        return out;
    };
})
