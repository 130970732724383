angular.module('app')
    .constant('ismAccordionConfig', {
        closeOthers: true,
        openFirstLink: false
    })
    .controller('IsmAccordionController', ['$scope', '$attrs', '$element', 'ismAccordionConfig', '$timeout', function ($scope, $attrs, $element, accordionConfig, $timeout) {
        let ctrl = this;

        // This array keeps track of the accordion groups
        ctrl.groups = [];

        // Ensure that all the groups in this accordion are closed, unless close-others explicitly says not to
        ctrl.closeOthers = function (openGroup) {
            let closeOthers = angular.isDefined($attrs.closeOthers) ?
                $scope.$eval($attrs.closeOthers) : accordionConfig.closeOthers;
            if (closeOthers) {
                angular.forEach(ctrl.groups, function (group) {
                    if (group !== openGroup) {
                        group.isOpen = false;
                    }
                });
            }
        };

        // This is called from the accordion-group directive to add itself to the accordion
        ctrl.addGroup = function (groupScope) {
            var that = this;
            ctrl.groups.push(groupScope);

            groupScope.$on('$destroy', function (event) {
                that.removeGroup(groupScope);
            });
        };

        // This is called from the accordion-group directive when to remove itself
        ctrl.removeGroup = function (group) {
            var index = ctrl.groups.indexOf(group);
            if (index !== -1) {
                ctrl.groups.splice(index, 1);
            }
        };

        // scrollTo
        ctrl.scrollTo = function (index) {
            if (ctrl.scrollerElement && ctrl.perIndex > 0) {
                let topPos = (index * ctrl.perIndex) + ctrl.offset;
                ctrl.scrollerElement.scrollTo({ top: topPos, behavior: 'smooth'});
                $timeout ( function () {
                    if (ctrl.scrollerElement.scrollTop != topPos || ctrl.scrollerElement.scrollY != topPos) {
                        ctrl.scrollerElement.scrollTo({ top: topPos, behavior: 'smooth'});
                    }
                });
            }
        };
    }])

    // The accordion directive simply sets up the directive controller
    .directive('ismAccordionGroup', function () {
        return {
            controller: 'IsmAccordionController',
            controllerAs: 'accordion',
            replace: true,
            transclude: true,
            templateUrl: function (element, attrs) {
                return attrs.templateUrl || 'templates/_directives/ismAccordion/ismAccordionGroup.html';
            },
            link: function (scope, element, attrs, ctrl) {
                let scrollerId = attrs['scrollerId'];
                ctrl.scrollerElement = document.getElementById(scrollerId) || window;
                if (ctrl.scrollerElement) {
                    ctrl.perIndex = parseInt(attrs['perIndex'] || 0);
                    ctrl.offset = parseInt(attrs['offset'] || 0);
                }
            }
        };
    })

    // The accordion-group directive indicates a block of html that will expand and collapse in an accordion
    .directive('ismAccordion', ['$window', '$timeout', 'ismAccordionConfig', function ($window, $timeout, accordionConfig) {
        return {
            require: '^ismAccordionGroup',   // We need this directive to be inside an accordion
            replace: true,
            transclude: true,   // It transcludes the contents of the directive into the template
            restrict: 'A',
            templateUrl: function (accordion, attrs) {
                return attrs.templateUrl || 'templates/_directives/ismAccordion/ismAccordion.html';
            },
            scope: {
                heading: '@',   // Interpolate the heading attribute onto this scope
                isOpen: '=?',
                summaryClass: "<?",
                detailClass: "<?"
            },
            controller: function () {
                this.setHeading = function (element) {
                    this.heading = element;
                };
            },
            link: function (scope, element, attrs, accordionCtrl) {
                accordionCtrl.addGroup(scope);

                scope.$watch('isOpen', function (value) {
                    if (value) {
                        accordionCtrl.closeOthers(scope);
                        let indexId = parseInt(attrs['indexId'] || -1);
                        if (indexId >= 0) {
                            accordionCtrl.scrollTo(indexId);
                        }
                        let openFirstLink = angular.isDefined(attrs.openFirstLink) ? scope.$eval(attrs.openFirstLink) : accordionConfig.openFirstLink;
                        if (openFirstLink) {
                            let myLink = element.find("a")[0];
                            if (myLink) {
                                $timeout( function () {
                                    myLink.click();
                                });
                            }
                        }
                    }
                    element[0].open = value;
                });

                var id = 'ism_accordion-' + scope.$id + '-' + Math.floor(Math.random() * 10000);
                scope.headingId = id + '-tab';
                scope.panelId = id + '-panel';

                let MutationObserver = $window.MutationObserver || $window.WebKitMutationObserver || $window.MozMutationObserver;

                let accordionObserver = new MutationObserver(function (mutations) {
                    mutations.forEach(function (mutation) {
                        if (mutation.type == "attributes") {
                            $timeout( function () {
                                scope.isOpen = mutation.target.open;
                            });
                        }
                    });
                });

                accordionObserver.observe(element[0], {
                    attributes: true, //configure it to listen to attribute changes
                    attributeNamespace: ['open'] // filter your attributes
                });
            }
        };
    }])

    // Use accordion-heading below an accordion-group to provide a heading containing HTML
    .directive('ismAccordionHeading', function () {
        return {
            require: '^ismAccordion',
            transclude: true,   // Grab the contents to be used as the heading
            replace: true,
            template: '',       // In effect remove this element!
            link: function (scope, element, attrs, accordionGroupCtrl, transclude) {
                // Pass the heading to the accordion-group controller
                // so that it can be transcluded into the right place in the template
                // [The second parameter to transclude causes the elements to be cloned so that they work in ng-repeat]
                accordionGroupCtrl.setHeading(transclude(scope, angular.noop));
            }
        };
    })

    // Use in the accordion-group template to indicate where you want the heading to be transcluded
    // You must provide the property on the accordion-group controller that will hold the transcluded element
    .directive('ismAccordionTransclude', function () {
        return {
            require: '^ismAccordion',
            link: function (scope, element, attrs, controller) {
                scope.$watch(
                    function () {
                        return controller[attrs.ismAccordionTransclude];
                    },
                    function (heading) {
                        if (heading) {
                            var elem = angular.element(element[0].querySelector(getHeaderSelectors()));
                            elem.replaceWith(heading);
                        }
                    }
                );
            }
        };

        function getHeaderSelectors() {
            return 'ism-accordion-header,' +
                'data-ism-accordion-header,' +
                'x-ism-accordion-header,' +
                'ism\\:accordion-header,' +
                '[ism-accordion-header],' +
                '[data-ism-accordion-header],' +
                '[x-ism-accordion-header]';
        }
    });
