angular.module('app').component('fleetOrdersComponent', {
    templateUrl: 'templates/fleetOrdersComponent.html',
    bindings: {
        hashInstructions: '<',
        fleetOrder: '<',
        fleet: '<',
        targetFleet: '<',
        determineProbePath: '&'
    },
    controllerAs: 'foc',
    controller: ['DataStoreSvc', function fleetOrdersController(DataStoreSvc) {
        var foc = this;

        foc.$onInit = function () {
            if (foc.fleetOrder) {
                foc.shipHash = foc.fleet.ships.reduce(
                    function (hash, ship) {
                        hash[ship._id] = ship;
                        return hash;
                    }, {}
                );
                foc.fleetOrder.localString = buildString(foc.fleetOrder);
            }
        };

        foc.editOrder = function (fleetOrder) {
            return foc.determineProbePath({fleetOrder: fleetOrder})
        };

        let getShipNames = function (shipIds) {
            let shipNamesArray = shipIds.map(shipId => foc.shipHash[shipId].shipName);
            let shipNames = shipNamesArray.join(', ');
            return shipNames;
        }

        let buildString = function (fleetOrder) {
            let pending = fleetOrder.complete === 0;
            let selectedInstruction = foc.hashInstructions[fleetOrder.code];
            let fleetOrderString = (pending ? '-- ' : '') + selectedInstruction.message + ' ';

            if (fleetOrder.code === 111 || fleetOrder.code === 411 || fleetOrder.code === 501) {
                if (foc.targetFleet) {
                    fleetOrderString += foc.targetFleet.fleetName + ' ';
                }
                else {
                    fleetOrderString += 'a fleet ';
                }
            }

            if (fleetOrder.code === 102 || fleetOrder.code === 103 || fleetOrder.code === 104 || fleetOrder.code === 111) {
                fleetOrderString += 'at hex ';
            }
        
            if (fleetOrder.code === 111) {
                fleetOrderString += foc.targetFleet.locationHex + ' ';
            }
            else if (fleetOrder.code <= 131) {
                fleetOrderString += fleetOrder.hex + ' ';
            }

            if (fleetOrder.code === 181 || fleetOrder.code === 191) {
                if (pending) {
                    let shipNames = getShipNames(fleetOrder.things);
                    fleetOrderString += '(' + shipNames;
                }
                else {
                    fleetOrderString += '(' + fleetOrder.things.length + ' ships';
                }
                fleetOrderString += ') as Fleet ' + fleetOrder.note + ' and transit Warp Point at hex ' + fleetOrder.hex + ' ';
            }

            if (fleetOrder.code === 401) {
                if (pending) {
                    let shipNames = getShipNames(fleetOrder.things);
                    fleetOrderString += '(' + shipNames;
                }
                else {
                    fleetOrderString += '(' + fleetOrder.things.length + ' ships';
                }
                fleetOrderString += ') as new fleet ' + fleetOrder.note;
            }

            if (selectedInstruction.options === "binary" || selectedInstruction.options === "binaryPlus") {
                fleetOrderString += 'for ' + fleetOrder.option + 
                    (fleetOrder.option === 'Both' ? ' stars ' : ' star ');
            }
            else if (selectedInstruction.options === "survey" || selectedInstruction.options === "loiterType") {
                fleetOrderString += fleetOrder.option + ' ';
            }

            if (fleetOrder.code === 511 && fleetOrder.option && fleetOrder.hex) {
                fleetOrderString += fleetOrder.hex + ' hours';
            }

            if (fleetOrder.code != 201 && fleetOrder.code < 401) {
                fleetOrderString += 'using speed ' + fleetOrder.speed + ', stage: ' + fleetOrder.stage;
            }

            if ((fleetOrder.code === 161 || fleetOrder.code === 181) && fleetOrder.stage >= 3) {
                fleetOrderString += ', Probe Route: ' + fleetOrder.hex;
            }

            if (fleetOrder.estimate) {
                fleetOrderString += ', estimate: ' + fleetOrder.estimate + ' hours';
            }

            return fleetOrderString;
        }
    }]
});
