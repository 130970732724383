angular.module('app').controller('CampaignManagementCtrl', [
    '$timeout', 'DataStoreSvc', 'RaceSvc', '$http',
    function ($timeout, DataStoreSvc, RaceSvc, $http) {
        'use strict';

        var cmc = this;
        var localData = {
            oneAtATime: true,
            raceEconomicStates: ['startUpReady', 'startUpPending', 'economicsReady', 'economicsPending'],
            raceState: {},
            loopCount: 0,
            overrideState: false,
            stopProcessLoop: true
        };
        cmc.campaignManagementData = localData;
        cmc.session = DataStoreSvc;

        var init = function () {
            cmc.session.iAmBusy();
            localData.campaign = cmc.session.getCampaign();
            let workingTurn = localData.campaign.turn;
            let races = JSON.parse(localStorage.getItem('races'));
            let racesPromise = (races) ?
                Promise.resolve(races) :
                RaceSvc.getAllRacesForCampaign(localData.campaign);
            racesPromise.then(
                function (races) {
                    for (let i = 0; i < races.length; i++) {
                        const race = races[i];
                        if (race.activated && race.turnActivated <= workingTurn) {
                            let refString;
                            if (race.turnActivated === workingTurn) {
                                if ((race.readyForEconomicsTurn || 0) === workingTurn) {
                                    refString = 'startUpReady';
                                }
                                else {
                                    refString = 'startUpPending';
                                }
                                addRaceToRaceState(race, refString);
                            }
                            if ((race.economicsCompleteTurn || 0) <= workingTurn) {
                                if ((race.readyForEconomicsTurn || 0) === (workingTurn + 1)) {
                                    refString = 'economicsReady';
                                }
                                else {
                                    refString = 'economicsPending';
                                }
                                addRaceToRaceState(race, refString);
                            }
                        }
                    }
                }
            );
            let taskPromise = cmc.experimentStatus(localData.campaign);
            Promise.all([racesPromise, taskPromise]).then( function (results) {
                $timeout( function () {
                    cmc.session.iAmNotBusy();
                });
            });
        };

        let addRaceToRaceState = function (race, state) {
            let raceArray = localData.raceState[state] || [];
            raceArray.push( race );
            localData.raceState[state] = raceArray;
        };

        let processTask = function(result) {
            localData.task = result.data;
            if ((localData.task.state === 'inProcess' || localData.overrideState) && !localData.stopProcessLoop) {
                localData.overrideState = false;
                $timeout(function () {
                    cmc.experimentStatusLoop();
                }, 2000);
            }
            else {
                $timeout( function () {
                    localData.stopProcessLoop = true;
                });
            }
        }

        cmc.experiment = function (campaign) {
            localData.loopCount = 0;
            localData.stopProcessLoop = false;
            localData.overrideState = true;
            $http.put('/api/task/fleetOrders/'+campaign._id).then( processTask );
        };

        cmc.experimentStatusLoop = function () {
            localData.loopCount++;
            cmc.experimentStatus(localData.campaign).then( processTask );
        };

        cmc.stopStatusLoop = function () {
            localData.stopProcessLoop = true;
        };

        cmc.experimentStatus = function (campaign) {
            return $http.get('/api/task/fleetOrders/'+campaign._id).then(
                function(result) {
                    localData.task = result.data;
                    return result;
                }
            );
        };

        cmc.resetTaskById = function (taskId) {
            $http.put('/api/task/reset/'+taskId).then(
                function (result) {
                    localData.loopCount = 0;
                    return cmc.experimentStatus(localData.campaign);
                }
            )
        };

        init();
    }
]);
