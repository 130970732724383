/* globals angular localStorage */
angular.module('app').controller('OtherExpenseCtrl', [
    'DataStoreSvc',
    function (DataStoreSvc) {
        'use strict';

        var oec = this;
        var localData = {};
        oec.pageData = localData;
        oec.session = DataStoreSvc;

        var init = function () {
            localData.editNextLevel = oec.session.user.role === 'Master' || oec.session.user.role === 'Marshal';
            localData.race = oec.session.getRace();
        };

        init();
    }
]);
