/* global angular localStorage */
angular.module('app').service('InformationStarSystemSvc', function ($http, $location) {
    'use strict';

    var localData = {};
    var svc = this;

    svc.getInformationStarSystemForRaceId = function (raceId) {
        return $http.get('/api/informationStarSystem/raceId/' + raceId).then(
            function (response) {
                return response.data;
            }
        );
    };
});
