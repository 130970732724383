angular.module('app').controller('ShipYardCtrl', [
    'UserSvc', 'DataStoreSvc', 'ShipClassSvc', 'ShipSvc', 'FleetSvc', '$ismModal', '$document', '$location', '$timeout',
    function (UserSvc, DataStoreSvc, ShipClassSvc, ShipSvc, FleetSvc, $ismModal, $document, $location, $timeout) {
        'use strict';

        let syc = this;

        let localData = {
            baseNumber: 1,
            quantity: 1,
            selectedClassFilter: {},
            createNewShip: { open: false },
            filterActive: { open: false }
        };
        syc.shipYardData = localData;
        syc.session = DataStoreSvc;

        let init = function () {
            return UserSvc.validateLoggedInUser().then(function (user) {
                localData.user = user;
                localData.editNextLevel = user.role === 'Master' || user.role === 'Marshal';
                localData.campaign = DataStoreSvc.getCampaign();
                localData.race = DataStoreSvc.getRace();
                let p1 = ShipClassSvc.getShipClassesForRace(localData.race).then(function (classes) {
                    localData.classes = classes;
                });
                let p2 = ShipSvc.getShipsForRace(localData.race).then(function (ships) {
                    localData.shipList = ships;
                });
                let p3 = FleetSvc.getFleetsForRace(localData.race).then(function (fleets) {
                    fleets.sort(function (a, b) {
                        let x = a.fleetName.toLowerCase();
                        let y = b.fleetName.toLowerCase();
                        return (x < y) ? -1 : (x > y) ? 1 : 0;
                    });

                    localData.hashFleets = {};
                    localData.hashShipFleets = {};
                    for (let i = 0; i < fleets.length; i++) {
                        let fleet = fleets[i];
                        localData.hashFleets[fleet._id] = fleet;
                        for (let j = 0; j < fleet.shipIds.length; j++) {
                            let shipId = fleet.shipIds[j];
                            localData.hashShipFleets[shipId] = fleet;
                        }
                    }
                });
                Promise.all([p1, p2, p3]).then(
                    function () {
                        console.log('ShipYardCtrl', syc);
                    }
                );
            }).catch(
                function () {
                    $location.path('/login');
                }
            );
        };

        syc.createNewShip = function () {
            if (localData.selectedShipClass != null && localData.quantity > 0 && localData.selectedFleet != null) {
                let newShipBase = Object.assign({}, localData.selectedShipClass);
                newShipBase.shipClassId = localData.selectedShipClass._id;
                newShipBase.shipName = localData.selectedShipClass.className + '0000';
                newShipBase.grade = 'A';
                newShipBase.state = 'active';
                newShipBase.tacticalHex = '3232';
                newShipBase.heading = 0;
                newShipBase.movementExpended = 0;
                newShipBase.datalink = 0;
                newShipBase.battlesFought = 0;
                newShipBase.shipYardPoints = 0;
                newShipBase.turnActivated = localData.campaign.turn + 1;
                newShipBase.turnDeactivated = 0;
                newShipBase.maintenanceCost = 0;
                newShipBase.maintenanceOnBoard = 0;
                delete newShipBase._id;
                delete newShipBase.__v;

                let newShips = [];
                let baseName = localData.baseName || localData.selectedShipClass.className;
                let number = localData.baseNumber || 1;
                let jsonShip = JSON.stringify(newShipBase);
                for (let i = 0; i < localData.quantity; i++) {
                    let newShip = JSON.parse(jsonShip);
                    if (baseName != null && number > 0) {
                        newShip.shipName = baseName + ' - ' + ('0000' + number++).slice(-4);
                    }
                    newShips.push(newShip);
                }
                ShipSvc.saveNewShips(newShips).then(
                    function (newShips) {
                        localData.shipList = localData.shipList.concat(newShips);
                        let newShipIds = newShips.map(
                            function (ship) {
                                return ship._id;
                            },
                            function (error) {
                                console.log('error while saving ship', error);
                            }
                        );
                        return newShipIds;
                    }
                ).then(
                    function (newShipIds) {
                        let shipIds = localData.selectedFleet.shipIds.concat(newShipIds);
                        let update = {
                            _id: localData.selectedFleet._id,
                            shipIds: shipIds
                        };
                        localData.selectedFleet.shipIds = shipIds;
                        return FleetSvc.saveFleet(update).then(
                            function () {
                                console.log('newShipIds have been saved');
                                return newShipIds.length;
                            }
                        ).catch(
                            function (error) {
                                console.log('error while saving fleet', error);
                            }
                        );
                    }
                ).then(
                    function (newShipCount) {
                        syc.session.setMessageAndType(newShipCount + " new " + localData.selectedShipClass.className + " class ship" + (newShipCount > 1 ? "s have" : " has") + " been created and placed in the fleet " + localData.selectedFleet.fleetName + ".", "successMessage");
                    }
                );
            }
            else {
                // Need to select a class
            }
        };

        syc.forceAlert = function () {
            $timeout(function () {
                syc.session.setMessageAndType("message...", "successMessage");
            });
        };

        syc.noFilter = function () {
            delete localData.shipClassFilter;
            localData.selectedClassFilter = {};
            localData.filterActive.open = false;
        };

        syc.filterForClass = function (shipClass) {
            localData.shipClassFilter = shipClass;
            localData.selectedClassFilter.shipClassId = shipClass._id;
            localData.filterActive.open = false;
        };

        syc.editShip = function (ship) {
            localData.selectedShip = ship;
            localData.shipToEdit = angular.copy(ship);
            syc.open();
        };

        syc.deleteShip = function (ship) {
            let index = localData.shipList.indexOf(ship);
            // add areYouSure modal
            localData.shipList.splice(index, 1);
            return ShipSvc.deleteShipForId(ship._id);
        };

        syc.animationsEnabled = true;

        syc.open = function (size, parentSelector) {
            let parentElem = parentSelector ?
                angular.element($document[0].querySelector('.shipYard ' + parentSelector)) : undefined;

            let modalInstance = $ismModal.open({
                animation: syc.animationsEnabled,
                templateUrl: 'templates/shipEdit.html',
                controller: 'ShipEdit',
                controllerAs: 'sec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    shipToEdit: function () {
                        return localData.shipToEdit;
                    }
                }
            });

            modalInstance.result.then(
                function (ship) {
                    return ShipSvc.saveShip(ship).then(function (ship) {
                        localData.selectedShip.shipName = ship.shipName;
                        syc.session.setMessageAndType(ship.shipName + " has been saved.", "successMessage");
                    });
                }
            ).catch(
                function () {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        init();
    }
]).controller('ShipEdit', function ($ismModalInstance, shipToEdit) {
    let sec = this;
    sec.shipToEdit = shipToEdit;

    sec.ok = function () {
        $ismModalInstance.close(sec.shipToEdit);
    };

    sec.cancel = function () {
        $ismModalInstance.dismiss('cancel');
    };
});
