angular.module('app').controller('RaceEncounterCtrl', [
    'DataStoreSvc', 'EncounterSvc',
    function (DataStoreSvc, EncounterSvc) {
        'use strict';

        var rec = this;
        var localData = {};

        rec.pageData = localData;
        rec.session = DataStoreSvc;

        var init = function () {
            localData.campaign = DataStoreSvc.getCampaign();
            localData.race = DataStoreSvc.getRace();
            localData.user = DataStoreSvc.getUser();
            localData.editNextLevel = DataStoreSvc.editNextLevel;

            let p1 = EncounterSvc.getEncountersForRaceId(localData.race._id).then(function (encounters) {
                localData.hashEncounters = {};
                for (var i = 0; i < encounters.length; i++) {
                    var encounter = encounters[i];
                    localData.hashEncounters[encounter._id] = encounter;
                }
            });

            return Promise.all([p1]).then(
                function (results) {
                    console.log('rec: ', rec);
                    return 'Done';
                }
            );
        };

        init();
    }
]);
