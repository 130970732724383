/* global angular localStorage */
angular.module('app').service('MessageSvc', ['$http', 'WebSocketSvc', 'DataStoreSvc',
    function ($http, WebSocketSvc, DataStoreSvc) {
        'use strict';

        let localData = {};
        let svc = this;
        let session = DataStoreSvc;

        // all: channel
        // notification: channel, raceId (from && to)
        // player: channel, userid/userId2 ((from && to) || (to && from))
        // race: channel, raceid/raceId2 ((from && to) || (to && from))
        // negotiation: channel, negotiationId
        // encounter: channel, encounterId

        let buildQueryString = function (channel, id, id2, page, offset) {
            let queryString = '?campaignId=' + session.campaign._id + '&channel=' + channel;
            switch (channel) {
                // Notification messages should not come through here!
                case 'Notification':
                    queryString += '&id=' + id;
                    break;
                case 'All':
                    break;
                case 'Player':
                    queryString += '&id=' + id + '&id2=' + id2;
                    break;
                case 'Race':
                    queryString += '&id=' + id + '&id2=' + id2;
                    break;
                case 'Negotiation':
                    queryString += '&id=' + id;
                    break;
                case 'Encounter':
                    queryString += '&id=' + id;
                    break;
                default:
            };
            if (page) {
                queryString += '&page=' + page
            }
            if (offset) {
                queryString += '&offset=' + offset
            }
            return queryString;
        };

        svc.getMessages = function (channel, id, id2, page, offset=0) {
            let queryString = buildQueryString(channel, id, id2, page, offset);
            return $http.get('/api/messages/array'+queryString).then(
                function (response) {
                    let messages = response.data;
                    return messages;
                }
            );
        };

        // let messageData = {
        //     campaignId: campaignId,
        //     channel: channel,
        //     message: messageText,
        //     userIdFrom: userId,
        //     userIdTo: userIdTo,
        //     raceIdFrom: raceIdFrom,
        //     raceIdTo: raceIdTo,
        //     identity: identity
        // };
        svc.buildMessageTemplate = function (campaignId, channel, messageText, userIdFrom=null, userIdTo=null, raceIdFrom=null, raceIdTo=null, negotiationId=null, encounterId=null) {
            let messageData = {
                campaignId: campaignId,
                channel: channel,
                message: messageText
            };
            if (userIdFrom) {
                messageData.userIdFrom = userIdFrom;
            }
            if (userIdTo) {
                messageData.userIdTo = userIdTo;
            }
            if (raceIdFrom) {
                messageData.raceIdFrom = raceIdFrom;
            }
            if (raceIdTo) {
                messageData.raceIdTo = raceIdTo;
            }
            if (negotiationId) {
                messageData.negotiationId = negotiationId;
            }
            if (encounterId) {
                messageData.encounterId = encounterId;
            }
            return messageData;
        };

        svc.sendMessage = function (messageData) {
            WebSocketSvc.sendMessage(messageData);
        };

        svc.saveMessage = function (messageData) {
            return $http.post('/api/messages/' + messageData.userIdFrom + '/' + messageData.campaignId + '/' + messageData.channel, messageData).then(
                function (response) {
                    let message = response.data;
                    return message;
                }
            );
        };
    }
]);
