angular.module('app').component('turnSelector', {
    // AMK: For testing, I needed the Treasury & Budget navLeftRaceMenu to drop down when turn is higher than 1, so it included document.getElementById("_Treasury & Budget_").open = true; to the ctrl.nextMonth function

    template: '<button type="button" class="btn btn-default" ng-click="ctrl.prevMonth()" ng-disabled="ctrl.session.editTurn<=ctrl.minTurn">Earlier</button>{{"  Edit turn: "+ctrl.session.editTurn+"  "}}<button type="button" class="btn btn-default" ng-click="ctrl.nextMonth()" ng-disabled="ctrl.session.editTurn>=ctrl.session.maxTurn">Later</button>',
    bindings: {
        onTurnChange: '&',
        altMinTurn: '<?'
    },
    controllerAs: 'ctrl',
    controller: ['DataStoreSvc', '$timeout', function RaceMenuController(DataStoreSvc, $timeout) {
        let ctrl = this;
        ctrl.session = DataStoreSvc;

        ctrl.$onInit = function () {
            ctrl.minTurn = ctrl.altMinTurn || ctrl.session.minTurn;
        };

        ctrl.nextMonth = function () {
            let nextTurn = ctrl.session.editTurn + 1;
            ctrl.session.setEditTurn(nextTurn);
            ctrl.onTurnChange({ 'turn': nextTurn });
            ctrl.session.raceMenu.isTreasuryOpen = !ctrl.session.isFirstTurn;
        };

        ctrl.prevMonth = function () {
            let nextTurn = ctrl.session.editTurn - 1;
            ctrl.session.setEditTurn(nextTurn);
            ctrl.onTurnChange({ 'turn': nextTurn });
            ctrl.session.raceMenu.isStartupEcoOpen = ctrl.session.isFirstTurn;
        };
    }]
});
