/* globals angular localStorage */
angular.module('app').controller('TradeIncomeCtrl', [
    'DataStoreSvc',
    function (DataStoreSvc) {
        'use strict';

        var tic = this;
        var localData = {};
        tic.tradeIncomeData = localData;
        tic.session = DataStoreSvc;

        var init = function () {
            localData.editNextLevel = tic.session.user.role === 'Master' || tic.session.user.role === 'Marshal';
            localData.race = tic.session.getRace();
        };

        init();
    }
]);
