angular.module('app').controller('RegCtrl', [
    '$scope', '$location', '$timeout', 'UserSvc',
    function ($scope, $location, $timeout, UserSvc) {
        'use strict';
        var rc = this;
        var localData = {
            first: null,
            last: null,
            email: null,
            password: null
        };
        rc.regData = localData;

        rc.createUser = function (first, last, email, password) {
            delete localData.error;
            UserSvc.createUser(first, last, email, password).then(
                function (user) {
                    $scope.$emit('login', user);
                    $location.path('/');
                },
                function (err) {
                    localData.error = err.data.errmsg || 'Unable to save, please contact admin.';
                    if (err.data.errmsg && err.data.errmsg.indexOf('duplicate key') > -1) {
                        localData.error = 'Email already registered.';
                    }
                    $timeout( function () {
                        var elmnt = document.getElementById("error");
                        elmnt.scrollIntoView();
                    });
                }
            );
        };
    }
]);
