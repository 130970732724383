angular.module('app').service('EncounterSvc', [
    '$http',
    function ($http) {
        'use strict';

        var svc = this;
        // var localData = {};

        svc.getEncounterScales = function () {
            return $http.get('/api/encounter/scales').then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getEncounterStates = function () {
            return $http.get('/api/encounter/states').then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getRaceEncounterStates = function () {
            return $http.get('/api/encounter/raceEncounter/states').then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getEncountersForCampaign = function (campaign) {
            return $http.get('/api/encounter/all/' + campaign._id).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getEncountersForRaceId = function (raceId) {
            return $http.get('/api/encounter/race/' + raceId).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getEncounterForId = function (encounterId) {
            return $http.get('/api/encounter/id/' + encounterId).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getEncounterForRaceEncounterId = function (reid) {
            return $http.get('/api/encounter/reid/' + reid).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getEncounterStatusForRaceEncounterId = function (reid) {
            return $http.get('/api/encounter/status/reid/' + reid).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.setStatusToSetupForRaceEncounterId = function (reid) {
            return $http.put('/api/encounter/setup/reid/'+reid).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.setStatusToReadyForRaceEncounterId = function (reid) {
            return $http.put('/api/encounter/ready/reid/'+reid).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.saveNewEncounter = function (encounter) {
            return $http.post('/api/encounter', encounter).then(
                function (response) {
                    return response.data;
                }
            );
        };
    
        svc.saveEncounter = function (encounter) {
            if (encounter._id != null) {
                return $http.put('/api/encounter/id/' + encounter._id, encounter).then(
                    function (response) {
                        return response.data;
                    }
                );
            } else {
                return svc.saveNewEncounter(encounter);
            }
        };
    
        svc.resetEncounter = function (encounter) {
            if (encounter._id != null) {
                return $http.put('/api/encounter/' + encounter._id + '/reset').then(
                    function (response) {
                        return response.data;
                    }
                );
            }
        };
    
        svc.terminateEncounter = function (encounter) {
            if (encounter._id != null) {
                return $http.put('/api/encounter/' + encounter._id + '/terminate').then(
                    function (response) {
                        return response.data;
                    }
                );
            }
        };
    
        svc.deleteEncounter = function (encounter) {
            if (encounter._id != null) {
                return $http.delete('/api/encounter/' + encounter._id).then(
                    function (response) {
                        return response.data;
                    }
                );
            }
        };
    }
]);
