angular.module('app').component('whirlySpinner', {
    template: '<div ng-show="$ctrl.visibility"><span class="whirly-loader"></span></div>',
    bindings: {
        visibility: '<'
    },
    controller: function StarfireImageController() {
        var ctrl = this;

        ctrl.$onInit = function() {
        };
    }
});

