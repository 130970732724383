angular.module('app').controller('AreYouSureCtrl', function ($ismModalInstance, model, message, objectToDisplay, displayTemplate) {
    var ctrl = this;
    ctrl.model = model;
    ctrl.message = message;
    ctrl.objectToDisplay = objectToDisplay;
    ctrl.displayTemplate = displayTemplate;

    ctrl.ok = function () {
      $ismModalInstance.close(ctrl.objectToDisplay._id);
    };
  
    ctrl.cancel = function () {
      $ismModalInstance.dismiss('cancel');
    };
});
