/* globals angular localStorage */
angular.module('app').controller('MessageCtrl', [
    '$scope', '$timeout', 'DataStoreSvc', 'MessageSvc', 'UserSvc', 'InformationRaceSvc', 'EncounterSvc', 'NegotiationSvc', 'title', 'channel',
    function ($scope, $timeout, DataStoreSvc, MessageSvc, UserSvc, InformationRaceSvc, EncounterSvc, NegotiationSvc, title, channel) {
        'use strict';

        var mc = this;
        var localData = {
            title: title,
            channel: channel,
            page: 1,
            externallyReceived: 0,
            messages: [],
            getMore: false,
            racesToDisplay: [],
            friendlyRaces: {},
            bigger: {}
        };
        mc.pageData = localData;
        mc.session = DataStoreSvc;

        var init = function () {
            mc.session.iAmBusy();
            let dataPromises = [];
            console.log('channel: ', localData.channel);
            localData.displayUsers = localData.channel === "Player";
            localData.displayRaces = localData.channel === "Race" ||
                localData.channel === "Negotiation" || 
                localData.channel === "Encounter";

            // find users for left selection and message prefix
            if (localData.displayUsers || localData.channel === "All") {
                dataPromises.push(UserSvc.getAllUsers().then( function (users) {
                    localData.users = users;
                    localData.userHash = users.reduce((hash, user) => (hash[user._id]=user, hash), {});
                }));
            }

            // find all Races for display and selection
            localData.allRaces = {};
            if (localData.displayRaces) {
                dataPromises.push(InformationRaceSvc.getAllInformationRaceForRace(mc.session.race._id).then( function (informationRaces) {
                    // localData.allRaces = races;
                    localData.informationRaces = {};
                    localData.friendlyRaces = {};
                    for (const informationRace of informationRaces) {
                        let race = informationRace.aboutRace;
                        localData.allRaces[race._id] = race;
                        localData.informationRaces[race._id] = informationRace;
                        if (informationRace.politicalStateNumber > 3) {
                            localData.friendlyRaces[race._id] = race;
                        }
                    }
                }));
            }
            localData.allRaces[mc.session.race._id] = mc.session.race;

            // find unsettled "Negotiation"s for display and selection
            if (localData.channel === "Negotiation") {
                localData.displayRaces = false;
                localData.displayNegotiations = true;
                dataPromises.push(NegotiationSvc.getNegotiationsForCampaign(mc.session.campaign).then(
                    function (negotiations) {
                        localData.currentNegotiations = [];
                        localData.oldNegotiations = [];
                        for (const negotiation of negotiations) {
                            if (negotiation.status === "Complete") {
                                localData.oldNegotiations.push(negotiation);
                            }
                            else {
                                localData.currentNegotiations.push(negotiation);
                            }
                        }
                    }
                ));
            }

            // find unsettled "Encounter"s for display and selection
            if (localData.channel === "Encounter") {
                localData.displayRaces = false;
                localData.displayEncounters = true;
                dataPromises.push(EncounterSvc.getEncountersForRaceId(mc.session.race._id).then(
                    function (encounters) {
                        localData.currentEncounters = [];
                        localData.oldEncounters = [];
                        for (const encounter of encounters) {
                            if (encounter.status === "Complete") {
                                localData.oldEncounters.push(encounter);
                            }
                            else {
                                localData.currentEncounters.push(encounter);
                            }
                        }
                    }
                ));
            }

            localData.displayMessages = (localData.channel === "Notification" || localData.channel === "All");
            if (localData.displayMessages) {
                dataPromises.push(MessageSvc.getMessages(localData.channel, mc.session.race._id, null, localData.page, localData.externallyReceived).then(
                    function (messages) {
                        localData.messages = messages;
                    }
                ));
            }

            localData.bigger = (localData.displayUsers || localData.displayRaces) ? {} : { "flex": "3"};

            Promise.all(dataPromises).then(
                function (results) {
                    if (localData.displayRaces) {
                        localData.racesToDisplay = Object.values(localData.friendlyRaces).map( race => race._id )
                    }
                    mc.session.iAmNotBusy();
                    $timeout();
                }
            );
        }

        // let messageData = {
        //     campaignId: campaignId,
        //     channel: channel,
        //     message: messageText,
        //     userIdFrom: userId,
        //     raceIdFrom: raceIdFrom,
        //     raceIdTo: raceIdTo,
        //     priority: priority,
        //     userIdTo: userIdTo,
        //     identity: identity
        // };
        mc.addMessage = function () {
            let newMessage = MessageSvc.buildMessageTemplate(
                mc.session.campaign._id,
                localData.channel,
                localData.messageText,
                mc.session.user._id,
                localData.selectedUserId,
                mc.session.race._id,
                localData.selectedRaceId,
                localData.selectedNegotiationId,
                localData.selectedEncounterId
            );
            MessageSvc.saveMessage(newMessage).then(
                function (savedMessage) {
                    localData.savedMessageId = savedMessage._id;
                    MessageSvc.sendMessage(savedMessage);
                    localData.displayMessages = true;
                }
            );
        };

        let resetMessageDisplay = function () {
            localData.page = 1;
            localData.externallyReceived = 0;
            localData.messages = [];
            localData.getMore = false;
        };

        let getMessages = function (channel, id1, id2) {
            console.log('Getting messages for channel: %s, id1: %s, id2: %s', channel, id1, id2)
            mc.session.iAmBusy();
            return MessageSvc.getMessages(channel, id1, id2, localData.page, localData.externallyReceived).then(
                function (messages) {
                    localData.getMore = messages.length === 30;
                    if (localData.getMore) {
                        localData.page++;
                    }
                    localData.messages.push( ...messages);
                    localData.displayMessages = true;
                    mc.session.iAmNotBusy();
                    $timeout(
                        function () {
                            return "Done!"
                        }
                    );
                }
            )
        };

        mc.getUserMessages = function (otherUserId) {
            resetMessageDisplay();
            localData.selectedUserId = otherUserId;
            return getMessages('Player', mc.session.user._id, otherUserId);
        };

        mc.getRaceMessages = function (otherRaceId) {
            resetMessageDisplay();
            localData.selectedRaceId = otherRaceId;
            return getMessages('Race', mc.session.race._id, otherRaceId);
        };

        mc.getNegotiationMessages = function (negotiationId) {
            resetMessageDisplay();
            localData.selectedNegotiationId = negotiationId;
            return getMessages('Negotiation', negotiationId);
        };

        mc.getEncounterMessages = function (encounterId) {
            resetMessageDisplay();
            localData.selectedEncounterId = encounterId;
            return getMessages('Encounter', encounterId);
        };

        // message sent over webSocket:
        // args = {
        //     campaignId: '610d998d9c02a207c6719748',
        //     channel: 'All',
        //     message: 'This is a general test!',
        //     userIdFrom: '5cf423ed10248f25c9122750',
        //     raceIdFrom: '610dc6a09c02a207c6719bb3'
        // }
        $scope.$on(localData.channel, function(event, sentMessage) {
            console.log('recieving:', sentMessage); // args is really the message
            localData.messages.push(sentMessage);
            localData.externallyReceived++;
            if (localData.savedMessageId === sentMessage._id) {
                // clear data to acknowlege receipt of message
                localData.savedMessageId = null;
                localData.messageText = null;
            }
            $timeout(
                function () {
                    return "Done!"
                }
            );
        });

        init();
    }
]);
