/* global angular, localStorage */
angular.module('app').service('UtilitySvc', [
    '$location', '$anchorScroll', '$http', 'DataStoreSvc', '$ismModal',
    function ($location, $anchorScroll, $http, DataStoreSvc, $ismModal) {
        'use strict';

        let svc = this;
        let localData = {};
        svc.session = DataStoreSvc;

        let init = function () {
            return "Done!";
        };

        svc.scrollToTop = function (offset=0) {
            svc.scrollToHash('_index-TOP_', offset);
        };

        svc.scrollToHash = function (hash, offset=0) {
            $location.hash(hash);
            $anchorScroll.yOffset = offset;
            $anchorScroll();
        };

        svc.sumObjectsByKey = function (...objs) {
            return objs.reduce((a, b) => {
                for (let key in b) {
                    if (b.hasOwnProperty(key))
                        a[key] = (a[key] || 0) + b[key];
                }
                return a;
            }, {});
        };

        svc.getBackendVersion = function () {
            return $http.get('/api/session/version').then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.areYouSureBase = function (size, parentElem, modelName, message, displayTemplate, toDelete) {
            return $ismModal.open({
                animation: true,
                templateUrl: 'templates/areYouSureDialog.html',
                controller: 'AreYouSureCtrl',
                controllerAs: 'ctrl',
                size: size,
                appendTo: parentElem,
                resolve: {
                    model: function () { return modelName },
                    message: function () { return message },
                    displayTemplate: function () { return displayTemplate },
                    objectToDisplay: function () { return toDelete }
                }
            });
        };

        svc.calcHexDist = function (fromHex, destinationHex) {
            let hex1 = Number.parseInt(fromHex);
            let hex2 = Number.parseInt(destinationHex);
            let x1 = Math.floor(hex1 / 100);
            let y1 = hex1 % 100;
            let x2 = Math.floor(hex2 / 100);
            let y2 = hex2 % 100;
        
            // make hex1 the "lower" hex - i.e y value greater
            let thisHex = hex1;
            if (y2 > y1) {
                thisHex = hex2;
        
                let t = x1;
                x1 = x2;
                x2 = t;
        
                t = y1;
                y1 = y2;
                y2 = t;
            }
        
            let dy = Math.abs(y1 - y2);
            if (x1 == x2) {
                // col has no effect
                return dy;
            }
            let dx = Math.abs(x1 - x2);
            if (dy <= Math.floor((dx) / 2)) {
                // row has no effect
                return dx;
            }
        
            let direction = 2;
            if (x1 > x2) {
                direction = 6;
            }
            for (let w = 1; w < 36; w++) {
                let newHex = Number.parseInt(svc.calculateNewHex(thisHex, 1, direction));
                // reuse x1 & x2
                x1 = Math.floor(newHex / 100);
                y1 = newHex % 100;
                if (x1 == x2) {
                    return (w + y1 - y2);
                }
                thisHex = newHex;
            }
        
            // ERROR condition
            throw new Error('Unable to calculate hex distance!');
        };

        svc.calculateNewHex = function (hexBase, hexesMoved, direction) {
            // counter.hex, 1, direction
            let hex = Number.parseInt(hexBase);
            let hexY = hex % 100;
            let hexX = (hex - hexY) / 100;
            // console.log('hexX: %d, hexY: %d', hexX, hexY);
            let xIsEven = ((hexX % 2) == 0);
            let yMoveBase = hexesMoved / 2;
            let yHasRemainder = ((hexesMoved % 2) != 0);
            if (yHasRemainder) {
                yMoveBase = Math.floor(yMoveBase);
            }
            // console.log('xIsEven: %d, yMoveBase: %d, yHasRemainder: %d', xIsEven, yMoveBase, yHasRemainder);
            switch (direction) {
                case 0:
                    break;
                case 1:
                    hexY = hexY - hexesMoved;
                    break;
                case 2:
                    hexX = hexX + hexesMoved;
                    hexY = hexY - yMoveBase;
                    if (!xIsEven && yHasRemainder) {
                        hexY--;
                    }
                    break;
                case 3:
                    hexX = hexX + hexesMoved;
                    hexY = hexY + yMoveBase;
                    if (xIsEven && yHasRemainder) {
                        hexY++;
                    }
                    break;
                case 4:
                    hexY = hexY + hexesMoved;
                    break;
                case 5:
                    hexX = hexX - hexesMoved;
                    hexY = hexY + yMoveBase;
                    if (xIsEven && yHasRemainder) {
                        hexY++;
                    }
                    break;
                case 6:
                    hexX = hexX - hexesMoved;
                    hexY = hexY - yMoveBase;
                    if (!xIsEven && yHasRemainder) {
                        hexY--;
                    }
                    break;
            }
            
            // console.log('hexX: %d, hexY: %d', hexX, hexY);
            let hexXString = "0" + hexX;
            let hexYString = "0" + hexY ;
            // console.log('hexX: %s, hexY: %s', hexXString, hexYString);
            return hexXString.substring(hexXString.length - 2) + hexYString.substring(hexYString.length - 2);
        };

        svc.timeToTravel = function (fromHex, destinationHex, speed) {
            let hours = svc.calcHexDist(fromHex, destinationHex) / (speed / 12);
            return hours;
        };

        init();
    }
]);
