/* global angular localStorage */
angular.module('app').service('ProjectSvc', function ($http, $location) {
    'use strict';

    var localData = {};
    var svc = this;

    svc.getProjectById = function (projectId) {
        return $http.get('/api/project/id/' + projectId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getProjectByRaceAndCode = function (raceId, code) {
        return $http.get('/api/project/race/' + raceId + '/code/' + code).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getProjectItemsForRace = function (raceId) {
        return $http.get('/api/project/items/race/' + raceId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getProjectLevelsForRace = function (raceId) {
        return $http.get('/api/project/levels/race/' + raceId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getOutfitProjectsForRace = function (raceId) {
        return $http.get('/api/project/outfit/race/' + raceId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.updateProjectById = function (projectId, update) {
        return $http.put('/api/project/'+projectId, update).then(
            function (response) {
                return response.data;
            }
        );
    };
});
