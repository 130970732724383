/* global angular localStorage */
angular.module('app').service('FleetSvc', function ($http, $location) {
    'use strict';

    var svc = this;
    var localData = {};

    svc.getFleetForId = function (fleetId) {
        return $http.get('/api/fleet/id/' + fleetId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getFleetsForRace = function (race) {
        return svc.getFleetsForRaceId(race._id);
    };

    svc.getFleetsForRaceId = function (raceId) {
        return $http.get('/api/fleet/race/' + raceId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getActiveFleetsForRace = function (raceId) {
        return $http.get('/api/fleet/race/' + raceId + '/active').then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getFreighterOverflowFleetsForRace = function (raceId) {
        return $http.get('/api/fleet/race/' + raceId + '/freighterOverflow').then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.saveFleet = function (fleet) {
        if (fleet._id != null) {
            return $http.put('/api/fleet/' + fleet._id, fleet).then(
                function (response) {
                    return response.data;
                }
            );
        } else {
            return svc.saveNewFleet(fleet);
        }
    };

    svc.getNewFleet = function (starSystemId, locationHex) {
        var newFleet = {
            campaignId: localData.campaign._id,
            raceId: localData.race._id,
            fleetName: 'New Fleet',
            instanceIdentifier: 0,
            // commandShipId: 0000,
            turnMode: 4,
            status: 'active',
            starSystemId: starSystemId,
            locationHex: locationHex,
            heading: 0,
            speed: 1,
            screenDistance: 0,
            turnActivated: 0,
            turnDeactivated: 0
        };
        return newFleet;
    };

    svc.saveNewFleet = function (fleet) {
        return $http.post('/api/fleet', fleet).then(
            function (response) {
                return response.data;
            }
        );
    };
});