/* globals angular localStorage */
angular.module('app').controller('StartUpShipsCtrl', [
    '$timeout', 'DataStoreSvc', 'EconomySvc', 'ShipClassSvc', 'FleetSvc', '$ismModal', '$document', 'title', 'filter',
    function ($timeout, DataStoreSvc, EconomySvc, ShipClassSvc, FleetSvc, $ismModal, $document, title, filter) {
        'use strict';

        var ssc = this;
        var localData = {
            startUpTitle: title
        };
        ssc.pageData = localData;
        ssc.session = DataStoreSvc;

        var init = function () {
            ssc.session.iAmBusy();
            localData.race = ssc.session.getRace();
            localData.campaign = ssc.session.getCampaign();
            localData.currentTurn = localData.campaign.turn;
            localData.readOnly = localData.race.turnActivated <= localData.race.readyForEconomicsTurn;
    
            let get1 = ShipClassSvc.getFilteredShipClassesForRace(localData.race, filter).then(
                function (shipClasses) {
                    localData.shipClasses = shipClasses.reduce(function(dictionary, shipClass) {
                        dictionary[shipClass._id] = shipClass;
                        return dictionary;
                    }, {});
                }
            );

            let get2 = EconomySvc.getOrBuildTransactions(localData.race, localData.race.turnActivated).then(
                function (transactions) {
                    localData.transactions = transactions[localData.startUpTitle] || {};
                    localData.total = transactions[localData.startUpTitle + 'Total'] ||{ amount: 0 };
                }
            );

            let get3 = FleetSvc.getFleetsForRace(localData.race).then(
                function (fleets) {
                    localData.hashFleets = fleets.reduce(function(dictionary, fleet) {
                        dictionary[fleet._id] = fleet;
                        return dictionary;
                    }, {});
                }
            );

            Promise.all([get1, get2, get3]).then(
                function (results) {
                    $timeout( function () {
                        ssc.session.iAmNotBusy();
                    });
                }
            );

        };

        ssc.newTransaction = function () {
            ssc.pageData.transactionToEdit = {
                campaignId: ssc.session.getCampaign()._id,
                raceId: ssc.session.getRace()._id,
                type: localData.startUpTitle,
                turn: localData.race.turnActivated,
                amount: 0
            };
            ssc.open();
        };

        ssc.editTransaction = function (transaction) {
            localData.transactionToEdit = angular.copy(transaction);
            ssc.open();
        };

        ssc.deleteTransaction = function (transaction) {
            delete localData.transactions[transaction._id];
            return EconomySvc.deleteTransaction(transaction);
        };

        ssc.saveTransaction = function (transaction) {
            return EconomySvc.saveTransaction(transaction).then(
                function (transaction) {
                    localData.transactions[transaction._id] = transaction;
                }
            );
        };

        localData.animationsEnabled = true;
        
        ssc.open = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.startUpShips ' + parentSelector)) : undefined;

            var modalInstance = $ismModal.open({
                animation: localData.animationsEnabled,
                templateUrl: 'templates/suShipTransactionEdit.html',
                controller: 'ShipTransactionEditCtrl',
                controllerAs: 'tec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    shipClasses: function () {
                        return ssc.pageData.shipClasses;
                    },
                    transaction: function () {
                        return localData.transactionToEdit;
                    },
                    fleetHash: function () {
                        return localData.hashFleets;
                    },
                    title: function () {
                        return localData.startUpTitle;
                    }
                }
            });

            modalInstance.result.then(
                function (transaction) {
                    ssc.saveTransaction(transaction);
                }
            ).catch(
                function (err) {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        init();
    }
]).controller('ShipTransactionEditCtrl', function ($ismModalInstance, shipClasses, fleetHash, transaction, title) {
    var tec = this;
    tec.shipClasses = shipClasses;
    tec.transaction = transaction;
    tec.fleetHash = fleetHash;
    tec.startUpTitle = title;

    tec.calculateTotal = function() {
        if (tec.transaction.quantity && tec.transaction.shipClassId) {
            tec.transaction.amount = tec.transaction.quantity * tec.shipClasses[tec.transaction.shipClassId].buildCost;
        }
    };

    tec.ok = function () {
      $ismModalInstance.close(tec.transaction);
    };
  
    tec.cancel = function () {
      $ismModalInstance.dismiss('cancel');
    };
});
