/* globals angular localStorage */
angular.module('app').controller('AppCtrl', [
    '$scope', '$timeout', '$location', '$http', '$cookies', 'UserSvc', 'DataStoreSvc', 'UtilitySvc',
    function ($scope, $timeout, $location, $http, $cookies, UserSvc, DataStoreSvc, UtilitySvc) {
        'use strict';

        let ac = this;  //controllerAs ac - for consistency
        let localData = {
            showPriv: true,
            showMobileCenterMenu: false,
            nodeVersion: '0.0.0'
        };
        ac.appData = localData;
        ac.session = DataStoreSvc;

        let init = function () {
            let parser = new UAParser();
            let myDevice = parser.getDevice();
            ac.session.isMobileDevice = myDevice.type === 'mobile' || myDevice.type === 'tablet';
            ac.session.showLeftMenu = !ac.session.isMobileDevice;
        
            UtilitySvc.getBackendVersion().then(
                function (version) {
                    localData.nodeVersion = version;
                    console.log('controller: ac: ', ac);
                }
            );
        };

        let authenticate = function (userId) {
            UserSvc.validateLoggedInUser(userId).then(
                function (user) {
                    ac.session.setUser(user);
                }
            ).catch(
                function () {
                    redirectToLogin();
                }
            );
        };

        let redirectToLogin = function () {
            $location.path('/login');
        };

        $scope.$on('$routeChangeStart', function (event, newUrl, current) {
            if (newUrl !== undefined && (current === undefined || newUrl.$$route.originalPath !== current.$$route.originalPath)) {
                let secure = newUrl.$$route.secure;

                // undefined (or null) implies that the page is actually secure
                if (secure == null) {
                    secure = true;
                }

                if (secure) {
                    let user = ac.session.getUser();

                    if (user == null) {
                        let userId = $cookies.get('uid');

                        // if no userId - user must login
                        if (userId == null) {
                            redirectToLogin();
                        }
                        else {
                            // User needs to be authenticated
                            authenticate(userId);
                        }
                    }
                }
            }
        });

        ac.logout = function () {
            $http.delete('/api/session').then(
                function (val) {
                    ac.session.clearUserData();
                    $cookies.remove('uid', { path: "/" });
                    redirectToLogin();
                }
            )
            .catch(function (err) {
                console.log('There was an error logging out! Error: ', err);
            });
        };

        ac.newCampaign = function () {
            DataStoreSvc.setCampaign(null);
        };

        ac.editUser = function (user) {
            DataStoreSvc.setEditUser(user);
        };

        ac.gotoEncounterManager = function () { };

        ac.toggleMobileLeftMenu = function () {
            let x = document.getElementById("myTopnav");
            if (x.className === "topnav") {
                x.className += " mobile";
            } else {
                x.className = "topnav";
            }
        }

        ac.toggleMobileCenterMenu = function () {
            localData.showMobileCenterMenu = !localData.showMobileCenterMenu;
        }

        ac.scrollToTop = function () {
            $timeout(function () {
                UtilitySvc.scrollToTop(50);
            });
        };

        init();
    }
]);
