angular.module('app').service('TableSvc', [
    '$http', '$q', 'DataStoreSvc',
    function ($http, $q, DataStoreSvc) {
        'use strict';

        let svc = this;
        let localData = {
            governmentData: {}
        };
        svc.session = DataStoreSvc;

        let setGovernment = function (government) {
            localData.governmentData[government.governmentId] = government;
        };
        let getGovernment = function (id) {
            return localData.governmentData[id];
        };

        svc.processTableData = function (data) {
            let actualTable;
            if (Array.isArray(data)) {
                actualTable = data;
            }
            else {
                actualTable = data.tableData;
            }
            return actualTable;
        };

        svc.getGovernmentById = function (governmentId) {
            let government = getGovernment(governmentId);
            if (government) {
                return $q.when(government);
            }
            return svc.getTableDataForType('governments').then(
                function (response) {
                    let governments = response;
                    for (const gov of governments) {
                        setGovernment(gov);
                    }
                    return getGovernment(governmentId);
                }
            );
        };

        svc.getTableDataForType = function (tableType) {
            let tableData = svc.session.getTableDataForTableType(tableType);
            if (tableData) {
                return Promise.resolve(tableData);
            }
            else {
                return $http.get('/api/table/' + tableType + '/cid/' + svc.session.campaign._id).then(
                    function (response) {
                        let table = svc.processTableData(response.data);
                        svc.session.setTableDataForTableType(table, tableType);
                        return table;
                    }
                );
            }
        };

        svc.getTableType = function (tableType) {
            return $http.get('/api/table/' + tableType + '/cid/0').then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.saveTableDataForType = function (tableData, tableType) {
            svc.session.setTableDataForTableType(tableData, tableType);
            return $http.put('/api/table/' + tableType + '/cid/' + svc.session.campaign._id, tableData).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.deleteTableDataForType = function (tableType) {
            svc.session.setTableDataForTableType(null, tableType);
            return $http.delete('/api/table/' + tableType + '/cid/' + svc.session.campaign._id).then(
                function (response) {
                    return svc.getTableDataForType(tableType);
                }
            );
        };

        svc.clearTableData = function () {
            svc.session.clearTableData();
        };
    }
]);
