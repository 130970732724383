angular.module('app').service('NegotiationSvc', [
    '$http',
    function ($http) {
        'use strict';

        var svc = this;
        // var localData = {};

        svc.getNegotiationStates = function () {
            return $http.get('/api/negotiations/states').then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getNegotiationsForCampaign = function (campaign) {
            return $http.get('/api/negotiations/all/' + campaign._id).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getNegotiationsForRaceId = function (raceId) {
            return $http.get('/api/negotiations/race/' + raceId).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getNegotiationForId = function (negotiationId) {
            return $http.get('/api/negotiations/' + negotiationId).then(
                function (response) {
                    return response.data;
                }
            );
        };

        // svc.getNegotiationStatusForRaceNegotiationId = function (reid) {
        //     return $http.get('/api/negotiations/status/reid/' + reid).then(
        //         function (response) {
        //             return response.data;
        //         }
        //     );
        // };

        // svc.setStatusToSetupForRaceNegotiationId = function (reid) {
        //     return $http.put('/api/negotiations/setup/reid/'+reid).then(
        //         function (response) {
        //             return response.data;
        //         }
        //     );
        // };

        // svc.setStatusToReadyForRaceNegotiationId = function (reid) {
        //     return $http.put('/api/negotiations/ready/reid/'+reid).then(
        //         function (response) {
        //             return response.data;
        //         }
        //     );
        // };

        // svc.saveNewNegotiation = function (Negotiation) {
        //     return $http.post('/api/negotiations', Negotiation).then(
        //         function (response) {
        //             return response.data;
        //         }
        //     );
        // };
    
        // svc.saveNegotiation = function (Negotiation) {
        //     if (Negotiation._id != null) {
        //         return $http.put('/api/negotiations/id/' + Negotiation._id, Negotiation).then(
        //             function (response) {
        //                 return response.data;
        //             }
        //         );
        //     } else {
        //         return svc.saveNewNegotiation(Negotiation);
        //     }
        // };
    
        // svc.resetNegotiation = function (Negotiation) {
        //     if (Negotiation._id != null) {
        //         return $http.put('/api/negotiations/' + Negotiation._id + '/reset').then(
        //             function (response) {
        //                 return response.data;
        //             }
        //         );
        //     }
        // };
    
        // svc.terminateNegotiation = function (Negotiation) {
        //     if (Negotiation._id != null) {
        //         return $http.put('/api/negotiations/' + Negotiation._id + '/terminate').then(
        //             function (response) {
        //                 return response.data;
        //             }
        //         );
        //     }
        // };
    
        svc.deleteNegotiation = function (negotiation) {
            if (negotiation._id != null) {
                return $http.delete('/api/negotiations/' + negotiation._id).then(
                    function (response) {
                        return response.data;
                    }
                );
            }
        };
    }
]);
