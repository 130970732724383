/* global angular localStorage */
angular.module('app').service('FleetOrderSvc', function ($http, $location) {
    'use strict';

    var svc = this;
    var localData = {};

    svc.getFleetOrdersForRaceId = function (raceId) {
        return $http.get('/api/fleetOrder/race/' + raceId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getAllFleetOrdersForRaceId = function (raceId) {
        return $http.get('/api/fleetOrder/race/' + raceId + '/all').then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getFleetOrdersForFleetId = function (fleetId) {
        return $http.get('/api/fleetOrder/fleet/' + fleetId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.getFleetOrderForId = function (fleetOrderId) {
        return $http.get('/api/fleetOrder/' + fleetOrderId).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.saveFleetOrder = function (fleetOrder) {
        if (fleetOrder._id != null) {
            return $http.put('/api/fleetOrder/' + fleetOrder._id, fleetOrder).then(
                function (response) {
                    return response.data;
                }
            );
        } else {
            return svc.saveNewFleetOrder(fleetOrder);
        }
    };

    svc.saveNewFleetOrder = function (fleetOrder) {
        return $http.post('/api/fleetOrder', fleetOrder).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.deleteFleetOrder = function (fleetOrder) {
        return $http.delete('/api/fleetOrder/' + fleetOrder._id).then(
            function (response) {
                return response.data;
            }
        );
    };
});