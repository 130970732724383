angular.module('app').component('starfireImage', {
    template: '<img ng-src="{{$ctrl.fullPath}}"/>',
    bindings: {
        imageName: '@',
        folder: '@'
    },
    controller: function StarfireImageController() {
        var ctrl = this;

        ctrl.$onInit = function() {
            ctrl.imageName = ctrl.imageName.replace(/\s/g, '_');
            ctrl.fullPath = "/images/set_high-res/" + (ctrl.folder ? ctrl.folder + '/' : "") + ctrl.imageName + ".png"
        };
    }
});
