/* globals angular localStorage */
angular.module('app').controller('MainCtrl', [
    '$location', '$timeout', '$anchorScroll', '$q', 'UserSvc', 'CampaignSvc', 'StarSystemSvc', 'RaceSvc', 'DataStoreSvc', 'TableSvc', 'UtilitySvc',
    function ($location, $timeout, $anchorScroll, $q, UserSvc, CampaignSvc, StarSystemSvc, RaceSvc, DataStoreSvc, TableSvc, UtilitySvc) {
        'use strict';

        var mc = this;
        var localData = {
        };
        mc.mainData = localData;
        mc.session = DataStoreSvc;
        mc.animationsEnabled = false;

        var init = function () {
            return UserSvc.validateLoggedInUser().then( function (user) {
                var controlledCampaigns;
                var controlledRaces;

                if (!user) {
                    return;
                }

                // get campaigns that are managed by the user
                if (user.role === 'Master') {
                    controlledCampaigns = CampaignSvc.getAllCampaigns().then(
                        function (campaigns) {
                            return campaigns;
                        },
                        function (err) {
                            localData.error = 'Could not get all campaigns.';
                            $location.hash('#error');
                            $anchorScroll();
                        }
                    );
                }
                else {
                    controlledCampaigns = CampaignSvc.getAuthorizedCampaigns().then(
                        function (campaigns) {
                            return campaigns;
                        },
                        function (err) {
                            localData.error = 'Could not get authorized campaigns.';
                            $location.hash('#error');
                            $anchorScroll();
                        }
                    );
                }

                // Get races that are controlled by the user
                controlledRaces = RaceSvc.getControlledRaces(user);

                $q.all([controlledCampaigns, controlledRaces]).then(
                    function (results) {
                        var uncontrolledCampaigns_promise = [];
                        localData.campaigns = results[0];
                        localData.campaignIds = [];
                        for (var i = 0; i < localData.campaigns.length; i++) {
                            localData.campaignIds.push(localData.campaigns[i]._id);
                        }

                        localData.races = {};
                        var races = results[1];
                        for (i = 0; i < races.length; i++) {
                            var race = races[i];
                            var racesForCampaignId = localData.races[race.campaignId] || [];
                            racesForCampaignId.push(race);
                            localData.races[race.campaignId] = racesForCampaignId;
                        }
                        // results[1].groupBy(results[1], 'campaignId');
                        localData.raceCampaignIds = Object.keys(localData.races);

                        for (i = 0; i < localData.raceCampaignIds.length; i++) {
                            var campaignId = localData.raceCampaignIds[i];
                            if (localData.campaignIds.indexOf(campaignId) === -1) {
                                uncontrolledCampaigns_promise.push(CampaignSvc.getCampaignWithId(campaignId));
                            }
                        }
                        return $q.all(uncontrolledCampaigns_promise);
                    }
                ).then(
                    function (uncontrolledCampaigns) {
                        localData.campaigns = localData.campaigns.concat(uncontrolledCampaigns);
                        console.log('MainCtrl: ', mc);
                    }
                );
            }).catch(
                function () {
                    $location.path('/login');
                }
            );
        };

        var preloadData = function (campaign) {
            localStorage.clear();
            let promises = [];
            if (campaign) {
                promises = [
                    StarSystemSvc.getFullStarSystems(campaign),
                    RaceSvc.getAllRacesForCampaign(campaign),
                    TableSvc.getTableDataForType('projectItems')
                ];
            }
            return Promise.all(promises).then(
                function (results) {
                    console.log('data is cached.');
                    return "Done!";
                }
            );
        };

        mc.accordionClick = function (index, $event) {
            mc.session.mainAccordion = index;
        };

        mc.scrollToTop = function () {
            mc.scrollToHash('top');
        };

        mc.scrollToHash = function (hash) {
            $location.hash(hash);
            $anchorScroll();
        };

        mc.editCampaign = function (campaign) {
            mc.session.setCampaign(campaign);
        };

        mc.ownedRaces = function(campaign) {
            return localData.races[campaign._id];
        };

        mc.campaignDisplay = function (campaign) {
            mc.session.setCampaign(campaign);
            return preloadData(campaign);
        };

        mc.gotoRace = function (campaign, race) {
            mc.session.setCampaignAndRace(campaign, race);
            return preloadData(campaign);
        };

        mc.manageCampaign = function (campaign) {
            mc.session.setCampaign(campaign);
            return preloadData(campaign);
        };

        mc.deleteCampaign = function (campaign) {
            localData.selectedCampaign = campaign;
            mc.openAreYouSure();
        };

        mc.openAreYouSure = function (size, parentSelector) {
            let parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.mainPage ' + parentSelector)) : undefined;

            let modalInstance = UtilitySvc.areYouSureBase(size, parentElem, 'Campaign', 'This action cannot be reversed!', '/pageParts/campaignSnapshot.html', localData.selectedCampaign);

            modalInstance.result.then(
                function (campaignId) {
                    mc.session.iAmBusy();
                    return CampaignSvc.deleteCampaign(campaignId);
                }
            ).then(
                function (result) {
                    localData.campaigns = _.filter(localData.campaigns, campaign => campaign._id != localData.selectedCampaign._id);
                    mc.session.setCampaign(null);
                    localData.selectedCampaign = null;
                }
            ).catch(
                function (error) {
                    if (error != 'cancel') {
                        console.error('Error: ', error);
                    }
                }
            ).finally(
                function () {
                    $timeout( function () {
                        mc.session.iAmNotBusy();
                    });
                }
            );
        };

        mc.scrollToTop = function () {
            $timeout(function () {
                UtilitySvc.scrollToTop(50);
            });
        };

        init();
    }
]);
