/* globals angular localStorage */
angular.module('app').controller('SimpleListCtrl', [
    '$timeout', 'DataStoreSvc', 'EconomySvc', 'ShipSvc', 'title', 'listKey',
    function ($timeout, DataStoreSvc, EconomySvc, ShipSvc, title, listKey) {
        'use strict';

        var slc = this;
        var localData = {
            heading: title,
            shipNames: {}
        };
        slc.pageData = localData;
        slc.session = DataStoreSvc;

        var init = function () {
            localData.editNextLevel = slc.session.user.role === 'Master' || slc.session.user.role === 'Marshal';
            localData.race = slc.session.getRace();
            slc.session.iAmBusy();
            localData.displayType = 'Ship';
            if (listKey === 'popIncome') {
                localData.displayType = 'Body';
            }
            let promises = [
                EconomySvc.getOrBuildTransactions(localData.race, slc.session.editTurn).then(processData)
            ];

            if (localData.displayType === 'Ship') {
                promises.push(ShipSvc.getShipsForRace(localData.race));
            }

            return Promise.all(promises).then(
                function(results) {
                    if (results.length > 1) {
                        let shipData = results[1];
                        localData.shipNames = shipData.reduce(
                            function (names, ship) {
                                names[ship._id] = ship.shipName;
                                return names;
                            }, {}
                        )
                    }
                    $timeout( function () {
                        slc.session.iAmNotBusy();
                    });
                    return "Done";
                }
            );
        };

        let processData = function (result) {
            localData.listData = result[listKey];
            localData.totalData = result[listKey + 'Total'];
        };

        init();
    }
]);
