/* global angular localStorage */
angular.module('app').service('DataStoreSvc', [ 'WebSocketSvc',
    function (WebSocketSvc) {
        'use strict';
        var svc = this;

        var init = function () {
            svc.busy = false;
            svc.user = null;
            svc.campaign = null;
            svc.race = null;
            svc.starSystemId = null;
            svc.starSystem = null;
            svc.economicData = {};
            svc.editTurn = null;
            svc.disableBurger = true;
            svc.showLeftMenu = false;
            svc.showMobileMiddleMenu = false;
            svc.showMobileRightUsrMenu = false;
            svc.tableCache = {};
            console.log('dataStore initialized...');
            svc.raceMenu = {
                isRaceDetailsOpen: true
            }
        };

        svc.setBusy = function (newBusy) {
            svc.busy = newBusy;
        };
        svc.iAmBusy = function () {
            svc.setBusy(true);
        };
        svc.iAmNotBusy = function () {
            svc.setBusy(false);
        };

        svc.setCampaignAndRace = function (campaign, race) {
            svc.setCampaign(campaign);
            svc.setRace(race);
            svc.setEditTurn(campaign.turn);
        };

        svc.clearUserData = function () {
            svc.user = null;
            svc.clearData();
        };

        svc.clearData = function () {
            svc.campaign = null;
            svc.race = null;
            svc.starSystemId = null;
            svc.starSystem = null;
            svc.economicData = {};
            svc.editTurn = null;
            svc.tableCache = {};
        };

        svc.setUser = function (user) {
            svc.user = user;
            svc.editNextLevel = user.role === 'Master' || user.role === 'Marshal';
            svc.topDog = user.role === 'Master';

            WebSocketSvc.registerUser(user._id);
        };
        svc.getUser = function () {
            return svc.user;
        };

        svc.setEditUser = function (user) {
            svc.editUser = Object.assign({}, user);
        };
        svc.getEditUser = function () {
            return svc.editUser;
        };

        svc.setCampaign = function (campaign) {
            if (svc.campaign != campaign) {
                svc.clearData();
            }
            svc.campaign = campaign;
            if (campaign) {
                svc.currentTurn = campaign.turn;
            }
        };
        svc.getCampaign = function () {
            return svc.campaign;
        };

        svc.setRace = function (race) {
            svc.race = race;
            svc.economicData = {};
            svc.setEditTurn(svc.campaign.turn);
            svc.setTurnData(race);

            WebSocketSvc.registerRace(race._id);
        };
        svc.getRace = function () {
            return svc.race;
        };
        svc.getRaceById = function (raceId) {
            return JSON.parse(localStorage.getItem(raceId));
        };

        svc.setStarSystemId = function (starSystemId) {
            svc.starSystemId = starSystemId;
        };
        svc.getStarSystemId = function () {
            return svc.starSystemId;
        };

        svc.setStarSystem = function (starSystem) {
            svc.starSystem = starSystem;
        };
        svc.getStarSystem = function () {
            return svc.starSystem;
        };

        svc.setEditTurn = function (turn) {
            svc.editTurn = turn;
            svc.isFirstTurn = (svc.currentTurn === turn);
        };
        svc.getEditTurn = function () {
            return svc.editTurn;
        };

        svc.setEconomicData = function (turn, data) {
            svc.economicData[turn] = data;
        };
        svc.getEconomicData = function (turn) {
            return svc.economicData[turn];
        };

        svc.setTableDataForTableType = function (tableData, tableType) {
            svc.tableCache[tableType] = tableData;
        };
        svc.getTableDataForTableType = function (tableType) {
            return svc.tableCache[tableType];
        };
        svc.clearTableData = function () {
            svc.tableCache = {};
        };

        svc.setTurnData = function (race) {
            let turnActivated = race.turnActivated;
            svc.isEconomicsActive = (turnActivated <= svc.currentTurn);
            svc.minTurn = svc.currentTurn - 6;
            if (svc.minTurn < 0 || svc.minTurn <= turnActivated) {
                svc.minTurn = turnActivated;
            }
            svc.maxTurn = svc.currentTurn + 6;
        };

        svc.setMessageAndType = function (message, type="infoMessage") {
            svc[type] = message;
            console.log("successMessage, infoMessage, warningMessage, dangerMessage", svc.successMessage, svc.infoMessage, svc.warningMessage, svc.dangerMessage);
        };

        svc.closeAlert = function () {
            svc.successMessage = null;
            svc.infoMessage = null;
            svc.warningMessage = null;
            svc.dangerMessage = null;
        };

        init();
    }
]);
