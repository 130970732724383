/*global angular */
angular.module('app').config([
    '$routeProvider', '$locationProvider', 'StartUpOptionsProvider', '$compileProvider',
    function ($routeProvider, $locationProvider, StartUpOptionsProvider, $compileProvider) {
        'use strict';
        $routeProvider
            .when('/',                  {controller: 'MainCtrl', controllerAs: 'mc', templateUrl: 'templates/main.html', secure: true})
            .when('/register',          {controller: 'RegCtrl', controllerAs: 'rc', templateUrl: 'templates/register.html', secure: false})
            .when('/resetPassword',     {controller: 'ResetPasswordCtrl', controllerAs: 'rpc', templateUrl: 'templates/resetPassword.html', secure: false})
            .when('/login',             {controller: 'LoginCtrl', controllerAs: 'lc', templateUrl: 'templates/login.html', secure: false})
            .when('/user',              {controller: 'UserCtrl', controllerAs: 'uc', templateUrl: 'templates/user.html'})
            .when('/campaignEdit',      {controller: 'CampaignCtrl', controllerAs: 'cc', templateUrl: 'templates/campaign.html'})
            .when('/campaignDisplay',   {controller: 'CampaignDisplayCtrl', controllerAs: 'cdc', templateUrl: 'templates/campaignDisplay.html'})
            .when('/campaignManagement',{controller: 'CampaignManagementCtrl', controllerAs: 'cmc', templateUrl: 'templates/campaignManagement.html'})
            .when('/starSystemDisplay', {controller: 'StarSystemDisplayCtrl', controllerAs: 'ssdc', templateUrl: 'templates/starSystemDisplay.html'})
            .when('/raceHome',          {controller: 'RaceHomeCtrl', controllerAs: 'rhc', templateUrl: 'templates/raceHome.html'})
            .when('/raceDisplay',       {controller: 'RaceDisplayCtrl', controllerAs: 'rdc', templateUrl: 'templates/raceDisplay.html'})
            .when('/sharedDesigns',     {controller: 'ShipClassSharedCtrl', controllerAs: 'scsc', templateUrl: 'templates/shipClassShared.html'})
            .when('/classDesign',       {controller: 'ClassDesignCtrl', controllerAs: 'scdc', templateUrl: 'templates/classDesign.html'})
            .when('/shipYard',          {controller: 'ShipYardCtrl', controllerAs: 'syc', templateUrl: 'templates/shipYard.html'})
            .when('/fleets',            {controller: 'FleetCtrl', controllerAs: 'fc', templateUrl: 'templates/fleets.html'})
            .when('/fleetOrders',       {controller: 'FleetOrdersCtrl', controllerAs: 'foc', templateUrl: 'templates/fleetOrders.html'})
             
            .when('/economicSummary',   {controller: 'EconomicSummaryCtrl', controllerAs: 'esc', templateUrl: 'templates/economicSummary.html'})
            .when('/populationIncome',  {controller: 'SimpleListCtrl', controllerAs: 'slc', templateUrl: 'templates/simpleList.html',
                                         resolve: {
                                            title: function () {
                                                return 'Population Income';
                                            },
                                            listKey: function () {
                                                return 'popIncome';
                                            },
                                         }
                                        })
            .when('/tradeIncome',       {controller: 'TradeIncomeCtrl', controllerAs: 'tic', templateUrl: 'templates/tradeIncome.html'})
            .when('/leaseIncome',       {controller: 'SimpleListCtrl', controllerAs: 'slc', templateUrl: 'templates/simpleList.html',
                                         resolve: {
                                            title: function () {
                                                return 'Lease Income';
                                            },
                                            listKey: function () {
                                                return 'leaseIncome';
                                            },
                                         }
                                        })
            .when('/saleIncome',        {controller: 'SimpleListCtrl', controllerAs: 'slc', templateUrl: 'templates/simpleList.html',
                                         resolve: {
                                            title: function () {
                                                return 'Ship Sale Income';
                                            },
                                            listKey: function () {
                                                return 'shipSale';
                                            },
                                         }
                                        })
            .when('/otherIncome',       {controller: 'OtherIncomeCtrl', controllerAs: 'oic', templateUrl: 'templates/otherIncome.html'})
            .when('/maintenance',       {controller: 'SimpleListCtrl', controllerAs: 'slc', templateUrl: 'templates/simpleList.html',
                                         resolve: {
                                            title: function () {
                                                return 'Maintenance';
                                            },
                                            listKey: function () {
                                                return 'maintenance';
                                            },
                                         }
                                        })
            .when('/construction',      {controller: 'ConstructionExpenseCtrl', controllerAs: 'cec', templateUrl: 'templates/construction.html'})
            .when('/industrialExpansion',   {controller: 'IndustrialExpansionCtrl', controllerAs: 'iec', templateUrl: 'templates/industrialExpansion.html'})
            .when('/colonization',      {controller: 'ColonizationExpenseCtrl', controllerAs: 'cec', templateUrl: 'templates/colonization.html'})
            .when('/troopRecruiting',   {controller: 'TroopPurchaseCtrl', controllerAs: 'tpc', templateUrl: 'templates/troopPurchase.html'})
            .when('/techLevelResearch', {controller: 'TechLevelResearchCtrl', controllerAs: 'tlrc', templateUrl: 'templates/techLevelResearch.html'})
            .when('/techItemResearch',  {controller: 'TechItemResearchCtrl', controllerAs: 'tirc', templateUrl: 'templates/techItemResearch.html'})
            .when('/otherExpense',      {controller: 'OtherExpenseCtrl', controllerAs: 'oec', templateUrl: 'templates/otherExpense.html'})

            .when('/raceEncounters',    {controller: 'RaceEncounterCtrl', controllerAs: 'rec', templateUrl: 'templates/raceEncounters.html'})
            .when('/warships',          {controller: 'StartUpShipsCtrl', controllerAs: 'ssc', templateUrl: 'templates/startUpShips.html',
                                         resolve: {
                                            title: function () {
                                                return StartUpOptionsProvider.warships;
                                            },
                                            filter: function () {
                                                return StartUpOptionsProvider.warshipFilter;
                                            }
                                         }
                                        })
            .when('/defenses',          {controller: 'StartUpShipsCtrl', controllerAs: 'ssc', templateUrl: 'templates/startUpShips.html',
                                         resolve: {
                                            title: function () {
                                                return StartUpOptionsProvider.defenses;
                                            },
                                            filter: function () {
                                                return StartUpOptionsProvider.defenseFilter;
                                            }
                                         }
                                        })
            .when('/freighters',        {controller: 'StartUpShipsCtrl', controllerAs: 'ssc', templateUrl: 'templates/startUpShips.html',
                                         resolve: {
                                            title: function () {
                                                return StartUpOptionsProvider.freighters;
                                            },
                                            filter: function () {
                                                return StartUpOptionsProvider.freighterFilter;
                                            }
                                         }
                                        })
            .when('/population',        {controller: 'PopulationCtrl', controllerAs: 'pc', templateUrl: 'templates/startUpPopulation.html'})
            .when('/troops',            {controller: 'TroopCtrl', controllerAs: 'tc', templateUrl: 'templates/startUpTroops.html'})

            .when('/prelimEM',            {controller: 'EncounterManagerCtrl', controllerAs: 'pemc', templateUrl: 'templates/prelimEncounterManager.html', reloadOnSearch: false})
            .when('/users',               {controller: 'UserManagerCtrl', controllerAs: 'umc', templateUrl: 'templates/users.html'})
            .when('/encounterResolution', {controller: 'EncounterResolutionCtrl', controllerAs: 'erc', templateUrl: 'templates/encounterResolution.html'})
            .when('/encounterTest',       {controller: 'EncounterTestCtrl', controllerAs: 'etc', templateUrl: 'templates/encounterTest.html'})

            .when('/openMessages',      {controller: 'MessageCtrl', controllerAs: 'mc', templateUrl: 'templates/messages.html', reloadOnSearch: false,
                                         resolve: {
                                            title: function () { return 'Messages to All'; },
                                            channel: function () { return 'All'; },
                                         }
                                        })
            .when('/playerMessages',    {controller: 'MessageCtrl', controllerAs: 'mc', templateUrl: 'templates/messages.html', reloadOnSearch: false,
                                         resolve: {
                                            title: function () { return 'Player to Player Messages'; },
                                            channel: function () { return 'Player'; },
                                         }
                                        })
            .when('/raceMessages',      {controller: 'MessageCtrl', controllerAs: 'mc', templateUrl: 'templates/messages.html', reloadOnSearch: false,
                                         resolve: {
                                            title: function () { return 'Race to Race Messages'; },
                                            channel: function () { return 'Race'; },
                                         }
                                        })
            .when('/notifications',     {controller: 'MessageCtrl', controllerAs: 'mc', templateUrl: 'templates/messages.html', reloadOnSearch: false,
                                         resolve: {
                                            title: function () { return 'Notifications'; },
                                            channel: function () { return 'Notification'; },
                                         }
                                        })
            .when('/negotiations',      {controller: 'MessageCtrl', controllerAs: 'mc', templateUrl: 'templates/messages.html', reloadOnSearch: false,
                                         resolve: {
                                            title: function () { return 'Negotiations'; },
                                            channel: function () { return 'Negotiation'; },
                                         }
                                        })
            .when('/encounters',        {controller: 'MessageCtrl', controllerAs: 'mc', templateUrl: 'templates/messages.html', reloadOnSearch: false,
                                         resolve: {
                                            title: function () { return 'Encounter Trashtalk'; },
                                            channel: function () { return 'Encounter'; },
                                         }
                                        })

            .when('/stateCommunication', {controller: 'CommunicationCtrl', controllerAs: 'cc', templateUrl: 'templates/stateCommunication.html'})
            .when('/stateNegotiation',   {controller: 'NegotiationCtrl', controllerAs: 'nc', templateUrl: 'templates/stateNegotiation.html'})

            .when('/tutorGeneral',       {controller: 'TutorialCtrl', controllerAs: 'tc', templateUrl: 'templates/tutorGeneral.html', secure: false})
            .when('/tutorStartup',       {controller: 'TutorialCtrl', controllerAs: 'tc', templateUrl: 'templates/tutorStartup.html', secure: false})
            .when('/tutorTurnEconomics', {controller: 'TutorialCtrl', controllerAs: 'tc', templateUrl: 'templates/tutorTurnEconomics.html', secure: false})
            .when('/tutorTurnOrders',    {controller: 'TutorialCtrl', controllerAs: 'tc', templateUrl: 'templates/tutorTurnOrders.html', secure: false})
            .when('/tutorBattleManager', {controller: 'TutorialCtrl', controllerAs: 'tc', templateUrl: 'templates/tutorBattleManager.html', secure: false})
            .when('/tutorWarpMap',       {controller: 'TutorialCtrl', controllerAs: 'tc', templateUrl: 'templates/tutorWarpMap.html', secure: false})
            .when('/credits', {
                // controller: 'CreditsCtrl', controllerAs: 'cc',
                templateUrl: 'templates/credits.html', secure: false})
            ;

        $locationProvider.html5Mode(true).hashPrefix('!');

        // $compileProvider.debugInfoEnabled(false);
    }
]);
