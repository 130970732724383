/* globals angular localStorage */
angular.module('app').controller('OtherIncomeCtrl', [
    'DataStoreSvc',
    function (DataStoreSvc) {
        'use strict';

        var oic = this;
        var localData = {};
        oic.pageData = localData;
        oic.session = DataStoreSvc;

        var init = function () {
            localData.editNextLevel = oic.session.user.role === 'Master' || oic.session.user.role === 'Marshal';
            localData.race = oic.session.getRace();
        };

        init();
    }
]);
