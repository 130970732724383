/* globals angular localStorage */
angular.module('app').controller('RaceHomeCtrl', [
    '$timeout', 'DataStoreSvc', 'StarSystemSvc', 'InformationPopulationSvc', 'InformationStarSystemSvc', 'InformationPlanetSvc', 'InformationWarpPointSvc',
    function ($timeout, DataStoreSvc, StarSystemSvc, InformationPopulationSvc, InformationStarSystemSvc, InformationPlanetSvc, InformationWarpPointSvc) {
        'use strict';

        var rhc = this;
        var localData = {};
        rhc.raceHomeData = localData;
        rhc.session = DataStoreSvc;

        var init = function () {
            localData.editNextLevel = rhc.session.user.role === 'Master' || rhc.session.user.role === 'Marshal';
            localData.race = rhc.session.getRace();
            localData.starSystemId = localData.race.starSystemId;
            let p1 = localStarSystem(localData.starSystemId);
            // let p1_1 = StarSystemSvc.getStarSystemsAndLinks(localData.race._id).then(
            //     function (results) {
            //         localData.starSystemAndLinks = results;
            //     }
            // );
            let p2 = getRaceInfoPop(localData.race._id);
            let p3 = getOtherRaceInfoPop(localData.race._id);
            let p4 = getInfoStarSystem(localData.race._id);
            let p5 = getInfoPlanets(localData.race._id);
            let p6 = getInfoWarpPoints(localData.race._id);
            Promise.all([p1,p2,p3,p4,p5,p6]).then(
                function () {
                    $timeout(function () {
                        console.log('controller: rhc: ', rhc);
                    });
                }
            );
        };

        let localStarSystem = function (starSystemId) {
            return StarSystemSvc.getStarSystem(starSystemId).then(
                function (results) {
                    localData.starSystem = results;
                    return "Done";
                }
            );
        };

        let getRaceInfoPop = function (raceId) {
            return InformationPopulationSvc.getAllInformationPopulationForRace(raceId).then(
                function (infoPops) {
                    localData.myPopulationInfo = infoPops;
                    localData.myPIMap = infoPops.reduce(function (ipMap, data) {
                        ipMap[data.planetId] = data;
                        return ipMap;
                    }, {});
                    return "Done";
                }
            );
        };

        let getOtherRaceInfoPop = function (raceId) {
            return InformationPopulationSvc.getAllInformationPopulationForOtherRaces(raceId).then(
                function (infoPops) {
                    localData.otherPopulationInfo = infoPops;
                    localData.otherPIMap = infoPops.reduce(function (ipMap, data) {
                        ipMap[data.planetId] = data;
                        return ipMap;
                    }, {});
                }
            );
        };

        let getInfoStarSystem = function ( raceId ) {
            return InformationStarSystemSvc.getInformationStarSystemForRaceId(raceId).then(
                function (results) {
                    localData.infoStarSystems = results;
                    localData.infoStarSystemsHash = localData.infoStarSystems.reduce(
                        (hash, iss) => (hash[iss.starSystemId] = iss, hash), {}
                    );
                }
            );
        };

        let getInfoPlanets = function (raceId) {
            return InformationPlanetSvc.getAllInformationPlanetForRace(raceId).then(
                function (infoPlanets) {
                    localData.infoPlanets = infoPlanets.reduce(function (hash, data) {
                        hash[data.bodyId] = data;
                        return hash;
                    }, {});
                }
            );
        };

        let pushWarpPoint = function (infoWarpPoint) {
            if (!localData.warpPointsByStarSystemId) {
                localData.warpPointsByStarSystemId = {};
            }
            let starSystemWarpPoints = localData.warpPointsByStarSystemId[infoWarpPoint.starSystemId] || [];
            if (starSystemWarpPoints.length === 0) {
                localData.warpPointsByStarSystemId[infoWarpPoint.starSystemId] = starSystemWarpPoints;
            }
            starSystemWarpPoints.push(infoWarpPoint.warpPointId);
        };

        let getInfoWarpPoints = function (raceId) {
            return InformationWarpPointSvc.getLeanInformationWarpPointForRaceId(raceId).then(
                function (infoWarpPoints) {
                    localData.infoWarpPoints = infoWarpPoints.reduce(function (hash, infoWarpPoint) {
                        hash[infoWarpPoint.warpPointId] = infoWarpPoint;
                        pushWarpPoint(infoWarpPoint);
                        return hash;
                    }, {});
                }
            );
        };

        rhc.changeStarSystemId = function (starSystemId) {
            localData.starSystemId = starSystemId;
            let p1 = localStarSystem(starSystemId);
            Promise.all([p1]).then(
                function () {
                    $timeout(function () {
                        console.log('controller: rhc: ', rhc);
                    });
                }
            )
        };

        init();
    }
]);
