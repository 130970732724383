angular.module('app')
.directive('ismChevron', function () {
    return {
        controller: function () {
        },
        restrict: 'A',
        scope: {
            isOpen: '='
        },
        transclude: true,
        replace: true,
        templateUrl: 'templates/_directives/ismChevron/ismChevron.html',
    };
})
