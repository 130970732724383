/* globals angular localStorage */
angular.module('app').controller('CampaignDisplayCtrl', [
    '$q', 'DataStoreSvc', 'RaceSvc', 'StarSystemSvc', '$ismModal', '$document', '$location', 'UtilitySvc',
    function ($q, DataStoreSvc, RaceSvc, StarSystemSvc, $ismModal, $document, $location, UtilitySvc) {
        'use strict';

        var cdc = this;
        var pageData = {
            actionName: 'Create StarSystem'
        };
        cdc.campaignDisplayData = pageData;
        cdc.session = DataStoreSvc;

        var init = function () {
            pageData.campaign = DataStoreSvc.getCampaign();
            let races = JSON.parse(localStorage.getItem('races'));
            let racesPromise = (races) ? Promise.resolve(races) : RaceSvc.getAllRacesForCampaign(pageData.campaign);
            var allStarSystems = StarSystemSvc.getAllStarSystems(pageData.campaign);
            $q.all([racesPromise, allStarSystems]).then(
                function (results) {
                    pageData.allRaces = results[0];
                    pageData.allStarSystems = results[1];
                    pageData.starSystemLookup = pageData.allStarSystems.reduce(
                        function (lookup, ss) {
                            lookup[ss._id] = ss;
                            return lookup;
                        }, {}
                    );
                    console.log('CampaignDisplayCtrl', cdc);
                }
            );
        };

        cdc.ownedRaces = function() {
            return RaceSvc.getRacesInCampaignForUser(pageData.campaign, pageData.user);
        };

        var starSystemsFoundByRace = function (race) {
            return StarSystemSvc.getStarSystemsFoundByRace(race);
        };

        cdc.newStarSystem = function (campaign, race, starSystemNumber, overrides) {
            DataStoreSvc.setStarSystemId(null);
            let starSystemPromise = StarSystemSvc.newStarSystem(campaign, race, starSystemNumber, overrides).then(
                function (response) {
                    pageData.newStarSystem = response.data;
                    DataStoreSvc.setStarSystemId(pageData.newStarSystem._id);
                    return pageData.newStarSystem;
                }
            );
            DataStoreSvc.setStarSystem(starSystemPromise);
        };

        cdc.gotoStarSystem = function (id) {
            DataStoreSvc.setStarSystemId(id);
            DataStoreSvc.setStarSystem(null);
        };

        cdc.gotoRace = function (race) {
            DataStoreSvc.setRace(race);
        };

        cdc.animationsEnabled = true;

        cdc.open = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.campaignDisplay ' + parentSelector)) : undefined;

            var modalInstance = $ismModal.open({
                animation: cdc.animationsEnabled,
                templateUrl: 'templates/overrides.html',
                controller: 'OverridesCtrl',
                controllerAs: 'oc',
                size: size,
                appendTo: parentElem,
                resolve: {
                    actionName: function () {
                        return pageData.actionName;
                    }
                }
            });

            modalInstance.result.then(
                function (overrides) {
                    $location.path('/starSystemDisplay');
                    cdc.newStarSystem(pageData.campaign, null, null, overrides);
                }
            ).catch(
                function () {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        cdc.deleteRace = function (race, index) {
            pageData.raceId = race._id;
            pageData.index = index;
            pageData.raceToDelete = race;
            cdc.openAreYouSure();
        };

        cdc.openAreYouSure = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.campaignDisplay ' + parentSelector)) : undefined;

            let modalInstance = UtilitySvc.areYouSureBase(size, parentElem, 'Race', 'This action cannot be reversed!', null, pageData.raceToDelete);

            modalInstance.result.then(
                function (raceId) {
                    cdc.session.iAmBusy();
                    return RaceSvc.deleteRace(raceId);
                }
            ).then(
                function () {
                    pageData.allRaces.splice(pageData.index, 1);
                    $timeout( function () {
                        cdc.session.iAmNotBusy();
                    });
                }
            ).catch(
                function (error) {
                    if (error != 'cancel') {
                        console.error('Error: ', error);
                    }
                    $timeout( function () {
                        cdc.session.iAmNotBusy();
                    });
                }
            );
        };

        init();
    }
]);
