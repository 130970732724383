angular.module('app').service('CShipSvc', function ($http) {
    'use strict';

    var svc = this;
    var localData = {};

    svc.getShipsForRaceEncounterId = function (reid) {
        return $http.get('/api/cship/raceEncounterId/' + reid).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.saveShip = function (ship) {
        return $http.put('/api/cship/' + ship._id, ship).then(
            function (response) {
                return response.data;
            }
        );
    };

    svc.destroyShips = function (shipIds) {
        return $http.put('/api/cship/destroy', {shipIds: shipIds}).then(
            function (response) {
                return response.data;
            }
        );
    };
});
