/* global angular, localStorage */
angular.module('app').service('SsdParserSvc', [
    'DataStoreSvc', 'TableSvc',
    function (DataStoreSvc, TableSvc) {
        'use strict';

        let svc = this;
        let localData = {};
        svc.session = DataStoreSvc;

        let init = function () {
            return TableSvc.getTableDataForType('projectItems').then(function (results) {
                localData.projectItems = results;
                localData.items = localData.projectItems.map(projectItem => projectItem.symbol);
                return "Done!";
            });
        };

        let multiplier = function (ssd, index) {
            // indicates that this is NOT a multiplier
            let mult = -1;
            let c = ssd.charAt(index);

            // If c is a number, it is a multiplier
            if (!isNaN(c)) {
                // Get the number string
                let number = '';
                while (!isNaN(c) && index < ssd.length) {
                    number = number.concat(c);
                    index++;
                    c = ssd.charAt(index);
                }
                mult = parseInt(number, 10);
            }

            return mult;
        };

        let findPairedCharIndexFromCurrentIndexInString = function (startChar, endChar, thisIndex, items) {
            let nextStartCharIndex = items.indexOf(startChar, thisIndex + 1);
            let nextEndCharIndex = items.indexOf(endChar, thisIndex + 1);
            while ((nextEndCharIndex > nextStartCharIndex) &&
                (nextStartCharIndex != -1)) {
                nextStartCharIndex = items.indexOf(startChar, nextStartCharIndex + 1);
                nextEndCharIndex = items.indexOf(endChar, nextEndCharIndex + 1);
            }
            return nextEndCharIndex;
        };

        let isEngine = function (item) {
            return (item === 'I') || (item === 'i') ||
                (item === 'Ic') || (item === 'ic') ||
                (item === 'J') || (item === 'j') ||
                (item === 'Jc1') || (item === 'jc1') ||
                (item === 'Jc2') || (item === 'jc2') ||
                (item === 'Jc3') || (item === 'jc3');
        }

        let hasMultipleCapitals = function (groupString) {
            let characters = groupString.split('');
            let capitalCount = characters.reduce(function (total, character) {
                if (character === character.toUpperCase()) {
                    total++;
                }
                return total;
            }, 0);
            return (capitalCount > 1);
        };

        /**
         * A group is a series of one or more system items that *might* be repeated.
         * A "raw" group is indicated by surrounding it with either "(" & ")" or "[" & "]".
         * Rules:
         * A multisystem group must be surrounded by "[" & "]" and will be passed WITHOUT them
         * An "isolated" system or engine room will be surrounded by "(" & ")" and will be passed WITH them
         * An "isolated" system is a single system item that is represented with multiple capital letters making 
         *      it an exception to the standard system item representation rules/techniques.
         */
         svc.getGroupItemString = function ( groupItemString ) {
            let startIndex = 0;
            let endIndex = 0;
        
            let c = groupItemString.charAt(startIndex);
            let closingChar;
        
            // Is this a standard group?
            if ('[' === c) {
                closingChar = ']';
            }
            else if ('(' === c) {
                closingChar = ')';
            }
            endIndex = findPairedCharIndexFromCurrentIndexInString(c, closingChar, startIndex, groupItemString);
            if (endIndex <= -1) {
                endIndex = groupItemString.length;
            }
        
            // establish the group string without its "wrappers"
            let groupString = groupItemString.substring(startIndex+1, endIndex);
        
            return groupString;
        };
        
        svc.parseGroupItemString = function ( groupString ) {
            let reAddParens = false;
            let groupItems = [];

            // Is this an isolated system item?
            // An isolated system item requires its wrappers, put 'em back on!
            if ((groupString.length < 5) &&
                (localData.items.indexOf(groupString) > -1) &&
                (hasMultipleCapitals(groupString))) {
                groupItems.push(groupString);
                reAddParens = true;
            }
            else {
                groupItems = svc.parseSsd(groupString);
                reAddParens = groupItems.every(isEngine);
            }
            if (reAddParens) {
                groupItems.unshift('(');
                groupItems.push(')');
            }

            return groupItems;
        };

        svc.parseSsd = function (ssdString) {
            let items = [];
            let groupItems = [];
            let index = 0;

            let c = ssdString.charAt(index);
            let item = '';
            while (index < ssdString.length) {
                // This is a multiplier
                if (('*' === c) || ('x' === c)) {
                    // point the index after the multiplier indicator
                    index++;
                    // How many **additional** times should group (or item) be appended?
                    let mult = multiplier(ssdString, index);
                    // point the index after the multiplier
                    index += mult.toString().length;
                    // if this is a multipler (!= -1) and not a "joke" (*0 or *1)
                    if (mult > 0) {
                        if (item.length > 0) {
                            groupItems = [item];
                            item = '';
                        }
                        else {
                            // Decrement the multiplier because the grouped items have
                            // already been added, the single item has not!
                            mult--;
                        }

                        // If there really are items in the (revised) groupItems array
                        if (groupItems.length > 0) {
                            for (let i = 0; i < mult; i++) {
                                items = items.concat(groupItems);
                            }
                        }
                    }
                }
                else {
                    // What "triggers" the start of the NEXT system item?
                    if ((c === c.toUpperCase()) || ('?' === c) || ('!' === c) ||
                        ('@' === c) || ('[' === c) || ('(' === c) || ('/' === c) ||
                        (('Y' === c) && (item != 'S')) ||
                        (('i' === c) && (item != 'A' && item != 'B' && item != 'D' && item != 'K' && item != 'T' && item != 'fL2'))) {
                        // Add single item from last pass to item array and Clear the single item buffer
                        if (item.length > 0) {
                            items.push(item.toString());
                            item = '';
                        }
                    }
                    // Is this a grouping?
                    if (('[' === c) || ('(' === c)) {
                        let indexPlus = 2;
                        let groupItemString = svc.getGroupItemString(ssdString.substring(index));
                        groupItems = svc.parseGroupItemString(groupItemString);
                        // console.log('debug', '2 first add groupItems: %s', groupItems);
                        items = items.concat(groupItems);
                        index = index + indexPlus + groupItemString.length;
                        item = '';
                    }
                    // it must be a character from an item
                    else {
                        item = item.concat(c);
                        index++;
                    }
                }
                // get the next character
                c = ssdString.charAt(index);
            }
            // Add the last, waiting item to the array
            if (item.length > 0) {
                items.push(item.toString());
            }
            // console.log('debug', 'All Items: %s', items.join(','));
            return items;
        };

        init().then(
            function (results) {
                console.log('SsdParserSvc is initialized:', svc);
            }
        );
    }
]);
