/* global angular, localStorage */
angular.module('app').service('InformationWarpPointSvc', [
    '$http', function ($http) {
        'use strict';
        let svc = this;

        svc.getInformationWarpPointForWarpPointIdAndRaceId = function (warpPointId, raceId) {
            return $http.get('/api/informationWarpPoint/warpPointId/' + warpPointId + '/raceId/:raceId' + raceId).then(
                function (response) {
                    return response.data;
                }
            );
        };
        
        svc.getInformationWarpPointForRaceIdAndStarSystemId = function (raceId, starSystemId) {
            return $http.get('/api/informationWarpPoint/raceId/' + raceId + '/starSystemId/' + starSystemId).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getLeanInformationWarpPointForRaceId = function (raceId) {
            return $http.get('/api/informationWarpPoint/raceId/' + raceId).then(
                function (response) {
                    return response.data;
                }
            );
        };
    }
]);
