angular.module('app').service('EncounterStatusSvc', [
    '$http', '$location',
    function ($http, $location) {
        'use strict';

        var svc = this;
        var localData = {};

        svc.getEncounterStatusForEncounterId = function (eid) {
            return $http.get('/api/encounterStatus/state/eid/' + eid).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getEncounterStatusForRaceEncounterId = function (reid) {
            return $http.get('/api/encounterStatus/reid/'+reid).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.setStatusToInitiativeForRaceEncounterId = function (reid) {
            return $http.put('/api/encounterStatus/initiative/reid/'+reid).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getInitiativeForRaceEncounterId = function (reid) {
            return $http.get('/api/encounterStatus/initiative/reid/'+reid).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.setStatusToInitiativeDoneForRaceEncounterId = function (reid) {
            return $http.put('/api/encounterStatus/initiativeDone/reid/'+reid).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.setStatusToMoveDoneForRaceEncounterId = function (reid) {
            return $http.put('/api/encounterStatus/moveDone/reid/'+reid).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.setStatusToFireDoneForRaceEncounterId = function (reid) {
            return $http.put('/api/encounterStatus/fireDone/reid/'+reid).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.setStatusToTractorDoneForRaceEncounterId = function (reid) {
            return $http.put('/api/encounterStatus/tractorDone/reid/'+reid).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.setStatusToEndTurnForRaceEncounterId = function (reid) {
            return $http.put('/api/encounterStatus/endTurn/reid/'+reid).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.setStatusToEndTurnForRaceEncounterId = function (reid, orderNumber) {
            return $http.put('/api/encounterStatus/order/reid/' + reid + '/' + orderNumber).then(
                function (response) {
                    return response.data;
                }
            );
        };
    }
]);
