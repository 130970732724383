/* global angular, localStorage */
angular.module('app').service('CampaignSvc', [
    '$http', '$location',
    function ($http, $location) {
        'use strict';

        var localData = {
        };
        var svc = this;

        svc.getAllCampaigns = function () {
            return $http.get('/api/campaign/all').then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getAuthorizedCampaigns = function () {
            return $http.get('/api/campaign/skyMarshal').then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.getCampaignWithId = function (id) {
            return $http.get('/api/campaign/id/' + id).then(
                function (response) {
                    return response.data;
                }
            );
        };

        svc.createCampaign = function (name, skyMarshals, turn, growthTurn, systemSize,
            playerIncome, playerMultiplier, freeSyPerMc, nprIncome, nprMultiplier, perTurns, maximum) {
            return $http.post('/api/campaign', {
                name: name,
                skyMarshals: skyMarshals,
                turn: turn,
                growthTurn: growthTurn,
                systemSize: systemSize,
                playerIncome: playerIncome,
                playerMultiplier: playerMultiplier,
                freeSyPerMc: freeSyPerMc,
                nprIncome: nprIncome,
                nprMultiplier: nprMultiplier,
                perTurns: perTurns,
                maximum: maximum
            });
        };

        svc.updateCampaign = function (id, name, skyMarshals, turn, growthTurn, systemSize,
            playerIncome, playerMultiplier, freeSyPerMc, nprIncome, nprMultiplier, perTurns, maximum) {
            return $http.put('/api/campaign/' + id, {
                name: name,
                skyMarshals: skyMarshals,
                turn: turn,
                growthTurn: growthTurn,
                systemSize: systemSize,
                playerIncome: playerIncome,
                playerMultiplier: playerMultiplier,
                freeSyPerMc: freeSyPerMc,
                nprIncome: nprIncome,
                nprMultiplier: nprMultiplier,
                perTurns: perTurns,
                maximum: maximum
            });
        };

        svc.deleteCampaign = function (id) {
            return $http.delete('/api/campaign/' + id).then(
                function (response) {
                    return "Deleted!";
                }
            );
        };
    }
]);
