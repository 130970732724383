angular.module('app').controller('ClassDesignCtrl', [
    '$document', '$timeout', 'DataStoreSvc', 'ShipClassSvc', 'ProjectSvc', 'SsdParserSvc', '$ismModal', 'UtilitySvc',
    function ($document, $timeout, DataStoreSvc, ShipClassSvc, ProjectSvc, SsdParserSvc, $ismModal, UtilitySvc) {
        'use strict';

        var scdc = this;
        var pageData = {};
        scdc.pageData = pageData;
        scdc.session = DataStoreSvc;

        var init = function () {
            var user = scdc.session.getUser();
            pageData.editNextLevel = user.role === 'Master' || user.role === 'Marshal';
            let p1 = ShipClassSvc.getShipClassesForRaceWithCount(scdc.session.getRace()).then(
                function (classes) {
                    classes.sort( function (a, b) {
                        var x = a.className.toLowerCase();
                        var y = b.className.toLowerCase();
                        return (x < y) ? -1 : (x > y) ? 1 : 0;
                    });
                    pageData.shipClasses = classes;
                }
            );

            let p2 = ProjectSvc.getOutfitProjectsForRace(scdc.session.getRace()._id).then(
                function(outfitProjects) {
                    pageData.projects = outfitProjects['standard'];
                    pageData.smallCraftProjects = outfitProjects['smallCraft'];
                    pageData.fighterProjects = outfitProjects['fighter'];
                    pageData.xoProjects = [];
                    pageData.projectHash = {};
                    for (const project of pageData.projects) {
                        pageData.projectHash[project.symbol] = project;
                        if (project.xoMountPoints > 0 && project.notations.indexOf('tFO') == -1) {
                            pageData.xoProjects.push(project);
                        }
                    }
                    for (const project of pageData.smallCraftProjects) {
                        pageData.projectHash[project.symbol] = project;
                    }
                    for (const project of pageData.fighterProjects) {
                        pageData.projectHash[project.symbol] = project;
                    }
                }
            )

            Promise.all([p1, p2]).then(
                function () {
                    console.log('ClassDesignCtrl', scdc);
                }
            );
        };

        scdc.newShipClass = function () {
            pageData.shipClassToEdit = { inactive: 1 };
            pageData.index = pageData.shipClasses.length;
            scdc.open();
        };

        scdc.editShipClass = function (shipClass, index) {
            pageData.shipClassToEdit = angular.copy(shipClass);
            pageData.index = index;
            scdc.open();
        };

        scdc.deleteShipClass = function (shipClassId, index) {
            pageData.shipClassId = shipClassId;
            pageData.index = index;
            pageData.shipClassToDelete = pageData.shipClasses[index];
            scdc.openAreYouSure();
        };

        let updateItemCounts = function (shipClass) {
            let items = SsdParserSvc.parseSsd(shipClass.ssd);
            let xTotal = 0;
            let syTotal = 0;
            let vTotal = 0;
            let mgTotal = 0;
            let boatBayPoints = 0;
            for (let j = 0; j < items.length; j++) {
                let item = items[j];
                if ('X' === item) { xTotal++; }
                else if ('SY' ===item) { syTotal++; }
                else if ('V' === item) { vTotal++; }
                else if ('Mg' === item) { mgTotal++; }
                else if ('B' === item[0] && 'b' === item[1]) {
                    if ('S' === item[2]) {
                        boatBayPoints += 2;
                    }
                    else if ('M' === item[2]) {
                        boatBayPoints += 4;
                    }
                    else if ('L' === item[2]) {
                        boatBayPoints += 6;
                    }
                }
            }
            shipClass.xCount = xTotal;
            shipClass.syCount = syTotal;
            shipClass.vCount = vTotal;
            shipClass.mgCount = mgTotal;
            shipClass.boatBayPoints = boatBayPoints;

            return shipClass;
        }

        scdc.editShipClassOutfit = function (shipClass, index) {
            pageData.shipClassToEdit = angular.copy(shipClass);
            pageData.index = index;
            pageData.outfitToEdit = {};
            pageData.xoToEdit = {};
            let selections = JSON.parse(pageData.shipClassToEdit.outfit || '{}');
            for (const symbol in selections) {
                if (Object.hasOwnProperty.call(selections, symbol)) {
                    const count = selections[symbol];
                    if (symbol === 'XO') {
                        // count is actually a substructure, not a count
                        pageData.xoToEdit = count;
                    }
                    else {
                        pageData.outfitToEdit[symbol] = count;
                    }
                }
            }
            scdc.openOutfit();
        };

        scdc.editShipClassFighterOutfit = function (shipClass, index) {
            pageData.shipClassToEdit = angular.copy(shipClass);
            pageData.index = index;
            pageData.outfitToEdit = {};
            pageData.xoToEdit = 0;
            let selections = JSON.parse(pageData.shipClassToEdit.fighterOutfit || '{}');
            for (const symbol in selections) {
                if (Object.hasOwnProperty.call(selections, symbol)) {
                    const count = selections[symbol];
                    pageData.outfitToEdit[symbol] = count;
                }
            }
            scdc.openFighterOutfit();
        };

        scdc.processSavedShipClass = function ( savedShipClass ) {
            savedShipClass = updateItemCounts(savedShipClass);
            // New shipClass will be added to the array
            // Edited shipClass will be updated in the array
            pageData.shipClasses[pageData.index] = savedShipClass;
            $timeout( function () {
                scdc.session.iAmNotBusy();
            });
        };

        scdc.animationsEnabled = true;

        scdc.open = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.shipClassDesign ' + parentSelector)) : undefined;

            var modalInstance = $ismModal.open({
                animation: scdc.animationsEnabled,
                templateUrl: 'templates/shipClassEdit.html',
                controller: 'ShipClassEdit',
                controllerAs: 'scec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    shipClassToEdit: function () {
                        return pageData.shipClassToEdit;
                    }
                }
            });

            modalInstance.result.then(
                function (shipClass) {
                    scdc.session.iAmBusy();
                    
                    if (shipClass._id == null) {
                        shipClass.campaignId = scdc.session.getCampaign()._id;
                        shipClass.raceId = scdc.session.getRace()._id;
                        return ShipClassSvc.saveNewShipClass(shipClass);
                    } else {
                        return ShipClassSvc.saveShipClass(shipClass);
                    }
                }
            ).then(
                function( savedShipClass ) {
                    scdc.processSavedShipClass( savedShipClass );
                }
            ).catch(
                function () {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        scdc.openOutfit = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.shipClassDesign ' + parentSelector)) : undefined;

            var modalInstance = $ismModal.open({
                animation: scdc.animationsEnabled,
                templateUrl: 'templates/projectListEdit.html',
                controller: 'ProjectListEdit',
                controllerAs: 'plec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    projects: function () {
                        return pageData.projects;
                    },
                    smallCraft: function () {
                        return pageData.smallCraftProjects;
                    },
                    xoProjects: function () {
                        return pageData.xoProjects;
                    },
                    outfitToEdit: function () {
                        return pageData.outfitToEdit;
                    },
                    xoToEdit: function () {
                        return pageData.xoToEdit;
                    },
                    shipClass: function () {
                        return pageData.shipClassToEdit;
                    },
                    mode: function () {
                        return 'S';
                    }
                }
            });

            modalInstance.result.then(
                function (outfitToEdit) {
                    scdc.session.iAmBusy();

                    let missileFund = 0;
                    let fighterFund = 0;
                    let smallCraftCost = 0;
                    Object.keys(outfitToEdit).forEach(
                        function (key) {
                            if (key != 'XO') {
                                let project = pageData.projectHash[key];
                                if (outfitToEdit[key] <= 0) {
                                    delete outfitToEdit[key];
                                }
                                else if (project.notations.indexOf('tM') > -1) {
                                    missileFund += outfitToEdit[key] * project.cost;
                                }
                                else if (project.notations.indexOf('tSC') > -1) {
                                    smallCraftCost += outfitToEdit[key] * project.cost;
                                }
                            }
                        }
                    );
                      
                    pageData.shipClassToEdit.outfit = JSON.stringify(outfitToEdit);
                    pageData.shipClassToEdit.missileFund = missileFund;
                    pageData.shipClassToEdit.fighterFund = fighterFund;
                    pageData.shipClassToEdit.smallCraftCost = smallCraftCost;
                    return ShipClassSvc.saveShipClass(pageData.shipClassToEdit);
                }
            ).then(
                function( savedShipClass ) {
                    scdc.processSavedShipClass( savedShipClass );
                }
            ).catch(
                function () {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        scdc.openFighterOutfit = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.shipClassDesign ' + parentSelector)) : undefined;

            var modalInstance = $ismModal.open({
                animation: scdc.animationsEnabled,
                templateUrl: 'templates/projectListEdit.html',
                controller: 'ProjectListEdit',
                controllerAs: 'plec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    projects: function () {
                        return pageData.fighterProjects;
                    },
                    smallCraft: function () {
                        return [];
                    },
                    xoProjects: function () {
                        return [];
                    },
                    outfitToEdit: function () {
                        return pageData.outfitToEdit;
                    },
                    xoToEdit: function () {
                        return pageData.xoToEdit;
                    },
                    shipClass: function () {
                        return pageData.shipClassToEdit;
                    },
                    mode: function () {
                        return 'F';
                    }
                }
            });

            modalInstance.result.then(
                function (outfitToEdit) {
                    scdc.session.iAmBusy();

                    let fighterFund = 0;
                    Object.keys(outfitToEdit).forEach(
                        function (key) {
                            let project = pageData.projectHash[key];
                            if (outfitToEdit[key] <= 0) {
                                delete outfitToEdit[key];
                            }
                            else if (project.notations.indexOf('tF') > -1) {
                                fighterFund += outfitToEdit[key] * project.cost;
                            }
                        }
                    );
                      
                    pageData.shipClassToEdit.fighterOutfit = JSON.stringify(outfitToEdit);
                    pageData.shipClassToEdit.fighterFund = fighterFund;
                    return ShipClassSvc.saveShipClass(pageData.shipClassToEdit);
                }
            ).then(
                function( savedShipClass ) {
                    scdc.processSavedShipClass( savedShipClass );
                }
            ).catch(
                function () {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        scdc.openAreYouSure = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.shipClassDesign ' + parentSelector)) : undefined;

            let modalInstance = UtilitySvc.areYouSureBase(size, parentElem, 'ShipClass', 'This action cannot be reversed!', '/pageParts/shipClassSnapshot.html', pageData.shipClassToDelete);

            modalInstance.result.then(
                function (shipClassId) {
                    scdc.session.iAmBusy();
                    return ShipClassSvc.deleteShipClassForId(shipClassId);
                }
            ).then(
                function () {
                    pageData.shipClasses.splice(pageData.index, 1);
                    $timeout( function () {
                        scdc.session.iAmNotBusy();
                    });
                }
            ).catch(
                function (error) {
                    if (error != 'cancel') {
                        console.error('Error: ', error);
                    }
                    $timeout( function () {
                        scdc.session.iAmNotBusy();
                    });
                }
            );
        };

        init();
    }
]).controller('ShipClassEdit', function ($ismModalInstance, shipClassToEdit) {
        var scec = this;
        scec.shipClassToEdit = shipClassToEdit;

        scec.ok = function () {
          $ismModalInstance.close(scec.shipClassToEdit);
        };
      
        scec.cancel = function () {
          $ismModalInstance.dismiss('cancel');
        };
    }
);
