angular.module('app')
    .controller('IsmAlertController', ['$scope', '$element', '$attrs', '$interpolate', '$timeout', function ($scope, $element, $attrs, $interpolate, $timeout) {
        $scope.closeable = !!$attrs.close;
        $element.addClass('alert');
        $attrs.$set('role', 'alert');
        if ($scope.closeable) {
            $element.addClass('alert-dismissible');
        }

        var dismissOnTimeout = angular.isDefined($attrs.dismissOnTimeout) ?
            $interpolate($attrs.dismissOnTimeout)($scope.$parent) : null;

        if (dismissOnTimeout) {
            $timeout(function () {
                $scope.close();
            }, parseInt(dismissOnTimeout, 10));
        }
    }])

    .directive('ismAlert', function () {
        return {
            controller: 'IsmAlertController',
            controllerAs: 'alert',
            restrict: 'A',
            templateUrl: function (element, attrs) {
                return attrs.templateUrl || 'templates/_directives/ismAlert/alert.html';
            },
            transclude: true,
            scope: {
                close: '&'
            }
        };
    });
