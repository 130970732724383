angular.module('app').component('completionIndicator', {
    template: '<svg-component file-name="svgXCircle" class-string="\'iBTTiny:w-7 iBTTiny:h-7 lanPixel2xl:w-8 lanPixel2xl:h-8 flex items-center text-red-600\'" ng-if="!ci.points"></svg-component>\
    <svg-component file-name="svgCheckCircle" class-string="\'iBTTiny:w-7 iBTTiny:h-7 lanPixel2xl:w-8 lanPixel2xl:h-8 flex items-center text-green-600\'" ng-if="ci.points >= ci.pointsMax"></svg-component>\
    <svg-component file-name="svgHamburger" class-string="\'iBTTiny:w-7 iBTTiny:h-7 lanPixel2xl:w-8 lanPixel2xl:h-8 flex items-center text-yellow-600\'" ng-if="ci.points > 0 && ci.points < ci.pointsMax"></svg-component>',
    bindings: {
        points: '<',
        pointsMax: '<'
    },
    controllerAs: 'ci',
    controller: function CompletionIndicatorController() {
        let ci = this;
    }
});
