angular.module('app').controller('FleetOrdersCtrl', [
'UserSvc', 'FleetSvc', 'ShipSvc', 'DataStoreSvc', 'StarSystemSvc', 'InformationStarSystemSvc', '$ismModal', 'FleetOrderSvc', 'TableSvc', '$location', 'InformationWarpPointSvc', 'UtilitySvc',
    function (UserSvc, FleetSvc, ShipSvc, DataStoreSvc, StarSystemSvc, InformationStarSystemSvc, $ismModal, FleetOrderSvc, TableSvc, $location, InformationWarpPointSvc, UtilitySvc) {
        var foc = this;

        var localData = {
            oneAtATime: true,
            instructionsByFleetId: {},
            showHistory: false,
            informationWarpPointsHash: {},
            contextualFleets: [],
            contextualShips: []
        };
        foc.pageData = localData;
        foc.session = DataStoreSvc;

        var init = function () {
            localData.race = DataStoreSvc.getRace();
            UserSvc.validateLoggedInUser().then(
                function (user) {
                    localData.user = user;
                    localData.editNextLevel = user.role === 'Master' || user.role === 'Marshal';
                }
            ).catch(
                function () {
                    $location.path('/login');
                }
            );
            let p1 = FleetSvc.getActiveFleetsForRace(localData.race._id).then(
                function (fleets) {
                    // console.log('fleets.length: ' + fleets.length);
                    let systemSet = new Set();
                    localData.systemFleets = {};
                    localData.hashFleets = {};
                    for (var i = 0; i < fleets.length; i++) {
                        var fleet = fleets[i];
                        fleet.hasXXr = false;
                        fleet.XXr = 0;
                        fleet.XOnly = 0;
                        for (const ship of fleet.ships) {
                            let itemCounts = ShipSvc.countItems(ship, ['X', 'Xr']);
                            ship.hasXXr = (itemCounts.X > 0 && itemCounts.Xr > 0);
                            if (ship.XXr) {
                                fleet.XXr += 1
                            }
                            else if (itemCounts.X > 0) {
                                fleet.XOnly += 1;
                            }
                            fleet.hasXXr = fleet.hasXXr || ship.hasXXr;
                        }
                        localData.hashFleets[fleet._id] = fleet;
                        if (!systemSet.has(fleet.starSystemId)) {
                            localData.systemFleets[fleet.starSystemId] = [];
                            systemSet.add(fleet.starSystemId);
                        }
                        localData.systemFleets[fleet.starSystemId].push(fleet);
                    }
                }
            ).then(foc.getFleetOrdersForRace);
            StarSystemSvc.getStarSystemsForRace(localData.race._id).then(
                function (starSystems) {
                    // console.log('starSystems.length: ' + starSystems.length);
                    // localData.allKnownStarSystems = starSystems;
                    localData.hashStarSystems = {};
                    for (let i = 0; i < starSystems.length; i++) {
                        let starSystem = starSystems[i];
                        localData.hashStarSystems[starSystem._id] = starSystem;
                    }
                    // console.log('starSystems finished');
                }
            );
            TableSvc.getTableType('instructions').then(
                function (instructions) {
                    // console.log('instructions.length: ' + instructions.length);
                    localData.instructions = instructions;
                    localData.hashInstructions = {};
                    for (let i = 0; i < instructions.length; i++) {
                        let instruction = instructions[i];
                        localData.hashInstructions[instruction.code] = instruction;
                    }
                    // console.log('instructions finished');
                }
            );
            InformationWarpPointSvc.getLeanInformationWarpPointForRaceId(localData.race._id).then(
                function (informationWarpPoints) {
                    localData.informationWarpPointsHash = (informationWarpPoints || []).reduce(
                        function (hash, informationWarpPoint) {
                            if (informationWarpPoint.warpPoint) {
                                informationWarpPoint.strategicHex = informationWarpPoint.warpPoint.strategicHex;
                                informationWarpPoint.destinationSystemNumber = informationWarpPoint.warpPoint.destination;
                                informationWarpPoint.destinationHex = informationWarpPoint.warpPoint.destinationStrategicHex;
                                informationWarpPoint.destinationStarSystemId = informationWarpPoint.warpPoint.destinationStarSystem;
                                delete informationWarpPoint.warpPoint;
                            }
                            hash[informationWarpPoint.starSystemId+informationWarpPoint.strategicHex] = informationWarpPoint;
                            let iwpsForSsid = hash[informationWarpPoint.starSystemId] || [];
                            iwpsForSsid.push(informationWarpPoint);
                            hash[informationWarpPoint.starSystemId] = iwpsForSsid;
                            return hash;
                        }, {}
                    );
                }
            );
            InformationStarSystemSvc.getInformationStarSystemForRaceId(localData.race._id).then(
                function (informationStarSystems) {
                    localData.informationStarSystemsHash = informationStarSystems.reduce(
                        function (hash, informationStarSystem) {
                            hash[informationStarSystem.starSystemId] = informationStarSystem;
                            return hash;
                        }, {}
                    );
                }
            );
        };

        foc.getFleetOrdersForRace = function () {
            let getOrders;
            if (localData.showHistory) {
                getOrders = FleetOrderSvc.getAllFleetOrdersForRaceId(localData.race._id);
            }
            else {
                getOrders = FleetOrderSvc.getFleetOrdersForRaceId(localData.race._id);
            }
            getOrders.then(
                function (fleetOrders) {
                    // console.log('fleetOrders.length: ' + fleetOrders.length);
                    let fleetIds = new Set();
                    localData.instructionsByFleetId = {};
                    for (let i = 0; i < fleetOrders.length; i++) {
                        let fleetOrder = fleetOrders[i];
                        let fleetId = fleetOrder.fleetId.toString();
                        fleetIds.add(fleetId);
                        if (!localData.instructionsByFleetId[fleetId]) {
                            localData.instructionsByFleetId[fleetId] = [];
                        }
                        localData.instructionsByFleetId[fleetId].push(fleetOrder);
                    }
                    for (const fleetId of fleetIds) {
                        let fleet = localData.hashFleets[fleetId];
                        let orders = localData.instructionsByFleetId[fleetId];
                        let context = buildContext(fleet, orders);
                    }
                }
            );
        };

        foc.newDisabled = function (fleetId) {
            let disabled = false;
            let fleetInstructions = localData.instructionsByFleetId[fleetId];
            if (fleetInstructions && fleetInstructions.length) {
                let fleetOrder = fleetInstructions[fleetInstructions.length-1];
                disabled = (fleetOrder.code === 131 && fleetOrder.complete === 0);
            }
            return disabled;
        };

        let getInformationWarpPointHashForStarSystemID = function (starSystemId) {
            let informationWarpPoints = localData.informationWarpPointsHash[starSystemId] || [];
            let iwpHash = informationWarpPoints.reduce( function (hash, informationWarpPoint) {
                hash[informationWarpPoint.strategicHex] = informationWarpPoint;
                return hash;
            }, {});
            return iwpHash;
        };

        let buildContext = function (fleet, instructions) {
            let iwpHash = getInformationWarpPointHashForStarSystemID(fleet.starSystemId);
            let iwp = iwpHash[fleet.locationHex];
            let starSystemInfo = localData.informationStarSystemsHash[fleet.starSystemId];

            let context = {
                starSystemId: fleet.starSystemId,
                locationHex: fleet.locationHex,
                atWP: !!(iwp),
                hasXXr: !!(fleet.hasXXr)
            };
            for (const instruction of instructions) {
                if (instruction.complete > 0) {
                    continue;
                }

                let pointsNeeded = 50;
                let pointsEarned = 0;
                // instruction.estimate = UtilitySvc.timeToTravel(context.locationHex, instruction.hex, instruction.speed) + 2;

                switch (instruction.code) {
                    case 101:
                        instruction.estimate = UtilitySvc.timeToTravel(context.locationHex, instruction.hex, instruction.speed) + 2;
                        context.locationHex = instruction.hex;
                        iwp = iwpHash[instruction.hex];
                        context.atWP = !!(iwp);
                        break;
                    case 102:
                        instruction.estimate = UtilitySvc.timeToTravel(context.locationHex, instruction.hex, instruction.speed) + 2;
                        context.locationHex = instruction.hex;
                        context.atWP = false;
                        break;
                    case 103:
                        instruction.estimate = UtilitySvc.timeToTravel(context.locationHex, instruction.hex, instruction.speed) + 2;
                        context.locationHex = instruction.hex;
                        context.atWP = true;
                        break;
                    case 104:
                        instruction.estimate = UtilitySvc.timeToTravel(context.locationHex, instruction.hex, instruction.speed) + 2;
                        context.locationHex = instruction.hex;
                        context.atWP = false;
                        break;
                    case 111:
                        let targetFleet = localData.hashFleets[instruction.element]
                        if (targetFleet) {
                            instruction.estimate = UtilitySvc.timeToTravel(context.locationHex, targetFleet.locationHex, instruction.speed) + 2;
                            context.locationHex = targetFleet.locationHex;
                            iwp = iwpHash[context.locationHex];
                        }
                        context.atWP = !!(iwp);
                        break;
                    case 201:
                        instruction.estimate = 2;
                        let informationWP = localData.informationWarpPointsHash[context.starSystemId+context.locationHex];
                        // is the ship on an actual warp point and destination is known?
                        if (informationWP && informationWP.turnExplored > 0 
                            && informationWP.strategicHex === context.locationHex
                            && informationWP.destinationStarSystemId) {
                            // where does it lead, starSystemId-wise?
                            // what hex is the destination WP in?
                            context.starSystemId = informationWP.destinationStarSystemId;
                            context.locationHex = informationWP.destinationHex;
                            iwpHash = getInformationWarpPointHashForStarSystemID(context.starSystemId);
                        }
                        // if destination is NOT known...
                        else {
                            context.starSystemId = "UNKNOWN";
                            context.locationHex = "UNKNOWN";
                        }
                        context.atWP = true;
                        break;
                    // probe orders return to original location, so no change
                    case 171:
                        instruction.estimate = (30 / instruction.speed) * 24;
                        context.atWP = true;
                        break;
                    case 161:
                        if (instruction.stage < 3) {
                            let stmpUsed = instruction.option = 'Both' ? 3 : 2;
                            instruction.estimate = stmpUsed * (30 / instruction.speed) * 24;
                        }
                        else try {
                            let historyIndex = instruction.hex.indexOf('|');
                            if (historyIndex === -1) { historyIndex = instruction.hex.length; }
                            let destination = (instruction.hex).substring(0,historyIndex);
                            let destinationHexes = destination.split(',');
                            let hours = 0;
                            let fromHex = context.locationHex;
                            for (const destinationHex of destinationHexes) {
                                hours += UtilitySvc.timeToTravel(fromHex, destinationHex, instruction.speed);
                                fromHex = destinationHex;
                            }
                            instruction.estimate = hours + 2;
                        }
                        catch (error) {
                            instruction.estimate = null;
                        }
                        context.atWP = true;
                        break;
                    // split off ships probe orders return to original location, so no change
                    case 181:
                    case 191:
                        instruction.estimate = 2;
                    // same with split/merge
                    case 401:
                    case 411:
                        instruction.estimate = 2;
                    // same with wait orders
                    case 501:
                    case 511:
                        break;
                    // patrol and survey instructions change locationHex and
                    //   cannot predict if final location is at a warpPoint
                    case 301:
                        pointsPerStmp = fleet.XOnly + fleet.XXr;
                        pointsEarned = instruction.option === 'Rough' ? starSystemInfo.surveyWarpPass1 : starSystemInfo.surveyWarpPass2;
                        pointsNeeded = 200;
                    case 302:
                        if (instruction.code === 302) {
                            pointsPerStmp = fleet.XOnly;
                            pointsEarned = instruction.option === 'Primary' ? starSystemInfo.starSystemInfo.surveyPlanetHabitable : starSystemInfo.starSystemInfo.surveyPlanetHabitable2;
                        }
                    case 303:
                        if (instruction.code === 303) {
                            pointsPerStmp = fleet.XOnly;
                            pointsEarned = instruction.option === 'Primary' ? starSystemInfo.starSystemInfo.surveyPlanetNonHabitable : starSystemInfo.starSystemInfo.surveyPlanetNonHabitable2;
                        }
                    case 304:
                        if (instruction.code === 304) {
                            pointsPerStmp = fleet.XOnly;
                            pointsEarned = instruction.option === 'Primary' ? starSystemInfo.starSystemInfo.surveyAsteroid : starSystemInfo.starSystemInfo.surveyAsteroid2;
                        }
                        let travelTime = (instruction.stage === 2) ? 0 : UtilitySvc.timeToTravel(context.locationHex, '4232', instruction.speed) + 2;
                        let pointsLeft = pointsNeeded - pointsEarned;
                        let pointsPerPulse = pointsPerStmp / (30 / instruction.speed * 24 / 2);
                        instruction.estimate = travelTime + pointsLeft / pointsPerPulse * 2;
                    case 131:
                    default:
                        context.locationHex = "UNKNOWN";
                        context.atWP = false;
                        break;
                };
            }
            return context;
        };

        let updateChoicesWithContext = function (fleet, context) {
            let starSystemInfo = {};
            let starSystem = localData.hashStarSystems[context.starSystemId];
            let planetBreakdown = {
                h1: false,
                h2: false,
                n1: false,
                n2: false,
                a1: false,
                a2: false
            };
            if (starSystem) {
                starSystemInfo = localData.informationStarSystemsHash[context.starSystemId];
                if (starSystem.stars[0]) {
                    let star = starSystem.stars[0];
                    let moons = [];
                    for (const planet of star.planets) {
                        planetBreakdown.h1 = planetBreakdown.h1 || (planet.hi >= 1 && planet.hi <= 10);
                        planetBreakdown.n1 = planetBreakdown.n1 || (planet.hi >= 11 && planet.type != 'A');
                        planetBreakdown.a1 = planetBreakdown.a1 || (planet.hi >= 11 && planet.type === 'A');
                        if (!planetBreakdown.h1 || !planetBreakdown.n1) {
                            moons.push(...planet.moons);
                        }
                    }
                    if (!planetBreakdown.h1 || !planetBreakdown.n1) {
                        for (const moon of moons) {
                            planetBreakdown.h1 = planetBreakdown.h1 || (moon.hi >= 1 && moon.hi <= 10);
                            planetBreakdown.n1 = planetBreakdown.n1 || moon.hi >= 11;
                        }
                    }
                }
                if (starSystem.stars[1]) {
                    let star = starSystem.stars[1];
                    let moons = [];
                    for (const planet of star.planets) {
                        planetBreakdown.h2 = planetBreakdown.h2 || (planet.hi >= 1 && planet.hi <= 10);
                        planetBreakdown.n2 = planetBreakdown.n2 || (planet.hi >= 11 && planet.type != 'A');
                        planetBreakdown.a2 = planetBreakdown.a2 || (planet.hi >= 11 && planet.type === 'A');
                        if (!planetBreakdown.h2 || !planetBreakdown.n2) {
                            moons.push(...planet.moons);
                        }
                    }
                    if (!planetBreakdown.h2 || !planetBreakdown.n2) {
                        for (const moon of moons) {
                            planetBreakdown.h2 = planetBreakdown.h2 || (moon.hi >= 1 && moon.hi <= 10);
                            planetBreakdown.n2 = planetBreakdown.n2 || moon.hi >= 11;
                        }
                    }
                }
            }
            localData.contextualShips = fleet.ships;
            for (const instruction of localData.instructions) {
                if (instruction.good == null) {
                    instruction.good = true;
                }

                localData.contextualFleets = [].concat(localData.systemFleets[fleet.starSystemId] || []);
                let index = localData.contextualFleets.findIndex(f => f._id===fleet._id);
                if (index > -1) {
                    localData.contextualFleets.splice(index, 1);
                }

                switch (instruction.code) {
                    case 102:
                        instruction.good = !(context.starSystemId === "UNKNOWN");
                        break;
                    case 103:
                        instruction.good = false;
                        // do you know of any warp points? need to check (# of infoWP)
                        if (context.starSystemId != "UNKNOWN") {
                            let informationWarpPoints = localData.informationWarpPointsHash[context.starSystemId] || [];
                            instruction.good = (informationWarpPoints.length > 0);
                        }
                        break;
                    case 104:
                        instruction.good = !(context.starSystemId === "UNKNOWN");;
                        break;
                    case 111:
                        instruction.good = (localData.contextualFleets.length > 0);
                        break;
                    case 161:
                        instruction.optionGood = {
                            '1610': true,
                            '1611': true,
                            '1612': true
                        };
                        instruction.good = (context.atWP && context.hasXXr);
                        break;
                    case 171:
                        instruction.good = context.atWP;
                        break;
                    case 181:
                        instruction.optionGood = {
                            '1810': true,
                            '1811': true,
                            '1812': true
                        };
                        instruction.good = false;
                        // do you know of any warp points? need to check (# of infoWP)
                        if (context.starSystemId != "UNKNOWN") {
                            let informationWarpPoints = localData.informationWarpPointsHash[context.starSystemId] || [];
                            instruction.good = (informationWarpPoints.length > 0);
                        }
                        break;
                    case 191:
                        instruction.good = false;
                        // do you know of any warp points? need to check (# of infoWP)
                        if (context.starSystemId != "UNKNOWN") {
                            let informationWarpPoints = localData.informationWarpPointsHash[context.starSystemId] || [];
                            instruction.good = (informationWarpPoints.length > 0);
                        }
                        break;
                    case 201:
                        instruction.good = context.atWP;
                        break;
                    case 101:
                    case 131:
                        break;
                    case 301:
                        instruction.optionGood = {
                            '3010': (starSystemInfo.surveyWarpPass1 <= 200),
                            '3011': (starSystemInfo.surveyWarpPass2 <= 200)
                        };
                        instruction.good = (starSystemInfo.surveyWarpPass1 <= 200 || starSystemInfo.surveyWarpPass2 <= 200);
                        break;
                    case 302:
                        instruction.optionGood = {
                            '3020': (starSystemInfo.surveyPlanetHabitable <= 50 && planetBreakdown.h1),
                            '3021': (starSystemInfo.surveyPlanetHabitable2 <= 50 && planetBreakdown.h2)
                        };
                        instruction.good = (instruction.optionGood['3020'] || instruction.optionGood['3021']);
                        break;
                    case 303:
                        instruction.optionGood = {
                            '3030': (starSystemInfo.surveyPlanetNonHabitable <= 50 && planetBreakdown.n1),
                            '3031': (starSystemInfo.surveyPlanetNonHabitable2 <= 50 && planetBreakdown.n2)
                        };
                        instruction.good = (instruction.optionGood['3030'] || instruction.optionGood['3031']);
                        break;
                    case 304:
                        instruction.optionGood = {
                            '3040': (starSystemInfo.surveyAsteroid <= 50 && planetBreakdown.a1),
                            '3041': (starSystemInfo.surveyAsteroid2 <= 50 && planetBreakdown.a2)
                        };
                        instruction.good = (instruction.optionGood['3040'] || instruction.optionGood['3041']);
                        break;
                    case 411:
                        instruction.good = (localData.contextualFleets.length > 0);
                        break;
                    case 501:
                        instruction.good = (localData.contextualFleets.length > 0);
                        break;
                    case 511:
                        instruction.optionGood = {
                            '5110': true,
                            '5111': true
                        };
                        break;
                }
            }
            return localData.instructions
        };

        foc.newInstruction = function (fleet) {
            if (!localData.instructionsByFleetId[fleet._id]) {
                localData.instructionsByFleetId[fleet._id] = [];
            }
            let fleetInstructions = localData.instructionsByFleetId[fleet._id];

            let context = buildContext(fleet, fleetInstructions);

            updateChoicesWithContext(fleet, context);

            localData.fleetOrder = {
                campaignId: foc.session.getCampaign()._id,
                raceId: localData.race._id,
                fleetId: fleet._id,
                speed: fleet.speed,
                sequence: Date.now(),
                positionOriginal: fleet.locationHex,
                positionCurrent: fleet.locationHex,
                endStarSystemId: context.starSystemId,
                complete: 0,
                stage: 0,
                elapsed: 0
            };

            foc.open();
        };

        foc.editInstruction = function (fleetOrder) {
            localData.fleetOrder = fleetOrder;
            foc.open();
        };

        foc.interruptInstruction = function (fleetId) {
            let fleetInstructions = localData.instructionsByFleetId[fleetId];
            if (fleetInstructions) {
                let fleetOrder = fleetInstructions.find(order => order.complete === 0);
                if (fleetOrder) {
                    fleetOrder.complete = 2;
                    FleetOrderSvc.saveFleetOrder(fleetOrder);
                }
            }
        };

        foc.lastOrderActive = function (fleetId) {
            let active = false;
            let fleetInstructions = localData.instructionsByFleetId[fleetId];
            if (fleetInstructions && fleetInstructions.length) {
                let fleetOrder = fleetInstructions[fleetInstructions.length-1];
                active = (fleetOrder.complete === 0);
            }
            return active;
        };

        foc.deleteInstruction = function (fleetId) {
            let fleetInstructions = localData.instructionsByFleetId[fleetId];
            if (fleetInstructions) {
                let fleetOrder = fleetInstructions.pop();
                if (fleetOrder && fleetOrder.complete === 0) {
                    FleetOrderSvc.deleteFleetOrder(fleetOrder);
                }
                else if (fleetOrder && fleetOrder.complete === 1) {
                    // put it back!
                    fleetInstructions.push(fleetOrder);
                }
            }
        };

        foc.processSavedFleetOrder = function (savedFleetOrder) {
            let fleetOrders = localData.instructionsByFleetId[savedFleetOrder.fleetId];
            let index = fleetOrders.findIndex(order => order._id === savedFleetOrder._id);
            if (index > -1) {
                fleetOrders[index] = savedFleetOrder;
            }
            else {
                fleetOrders.push(savedFleetOrder);
            }
        };

        foc.animationsEnabled = true;
        
        foc.open = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.fleetOrders ' + parentSelector)) : undefined;

            var modalInstance = $ismModal.open({
                animation: foc.animationsEnabled,
                templateUrl: 'templates/fleetOrderEntry.html',
                controller: 'FleetOrderEntryCtrl',
                controllerAs: 'foec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    fleetOrder: function () {
                        return localData.fleetOrder;
                    },
                    instructions: function () {
                        return localData.instructions;
                    },
                    hashStarSystems: function () {
                        return localData.hashStarSystems;
                    },
                    informationWarpPoints: function () {
                        return localData.informationWarpPointsHash[localData.fleetOrder.endStarSystemId] || [];
                    },
                    ships: function () {
                        return localData.contextualShips;
                    },
                    fleets: function () {
                        return localData.contextualFleets;
                    }
                }
            });

            modalInstance.result.then(
                function (fleetOrder) {
                    if (fleetOrder._id == null) {
                        return FleetOrderSvc.saveNewFleetOrder(fleetOrder);
                    } else {
                        return FleetOrderSvc.saveFleetOrder(fleetOrder);
                    }
                }
            ).then(
                function( savedFleetOrder ) {
                    foc.processSavedFleetOrder( savedFleetOrder );
                }
            ).catch(
                function () {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        init();
    }
]).controller('FleetOrderEntryCtrl', function ($ismModalInstance, fleetOrder, instructions, hashStarSystems, informationWarpPoints, ships, fleets) {
    var foec = this;

    var init = function () {
        foec.fleetOrder = fleetOrder;
        foec.instructions = instructions;
        foec.hashStarSystems = hashStarSystems;
        let starSystem = foec.hashStarSystems[foec.fleetOrder.endStarSystemId] || {};
        foec.stars = starSystem.stars || [];
        foec.planets = [];
        if (foec.stars.length > 0) {
            foec.planets = foec.stars[0].planets;
            if (foec.stars.length > 1) {
                foec.planets = foec.planets.concat(foec.stars[1].planets);
            }
        }
        foec.informationWarpPoints = informationWarpPoints || [];
        for (const iwp of foec.informationWarpPoints) {
            iwp.destString = '';
            if (iwp.turnExplored >= iwp.turn) {
                iwp.destString = ' to ' + iwp.destinationSystemNumber + '@' + iwp.destinationHex;
            }
        }
        foec.ships = ships;
        foec.fleets = fleets;
        foec.options = {
            survey: ['Rough','Detailed'],
            binary: ['Primary','Secondary'],
            binaryPlus: ['Primary','Secondary','Both'],
            loiterType: ['End of Turn','Duration']
        }
        if (foec.fleetOrder.code) {
            foec.selectedInstruction = foec.instructions.find(instruction => instruction.code===foec.fleetOrder.code);
        }
    }

    foec.ok = function () {
        if (!foec.fleetOrder.code) {
            foec.fleetOrder.code = foec.instruction.code;
        }
        if (foec.element) {
            foec.fleetOrder.element = foec.element._id;
            foec.fleetOrder.hex = foec.element.strategicHex;
        }
        if (foec.fleetOrder.code === 161 && foec.fleetOrder.stage === 2 && foec.fleetOrder.hex.length >= 4) {
            foec.fleetOrder.stage = 3;
        }
        $ismModalInstance.close(foec.fleetOrder);
    };
  
    foec.cancel = function () {
        $ismModalInstance.dismiss('cancel');
    };

    init();
});
