angular.module('app').component('techLevel', {
    template: '<span>{{$ctrl.tl}}</span>',
    bindings: {
        techLevel: '<'
    },
    controller: function TechLevelController() {
        var ctrl = this;

        var transform = function(tl) {
            return tl == -2 ? "Pre-Ind" : tl == -1 ? "Ind-1" : tl == 0 ? "Ind-2" : tl;
        };

        ctrl.$onInit = function() {
            ctrl.tl = transform(ctrl.techLevel);
        };
    }
});