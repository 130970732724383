angular.module('app').component('starSystem', {
    templateUrl: 'templates/starSystemComponent.html',
    bindings: {
        starSystem: '<',
        warpPoints: '<',
        displayMoons: '<',
        playerPops: '<',
        otherPops: '<',
        infoPlanets: '<',
        infoWarpPoints: '<',
        infoStarSystem: '<',
        updateStarSystemId: '&'
    },
    controllerAs: 'ssc',
    controller: ['DataStoreSvc', 'UtilitySvc', '$timeout',
        function StarSystemController(DataStoreSvc, UtilitySvc, $timeout) {
        let ssc = this;
        ssc.session = DataStoreSvc;
        ssc.techLevel = ssc.session.race.techLevel;

        ssc.$onInit = function() {
        };

        ssc.$onChanges = function(changes) {
            if (hasStarSystemChanged(changes)) {
                $timeout( function () {
                    identifySystemSurveyElements(ssc.starSystem);
                });
            }
        };

        let baseSurveyElements = function () {
            return {
                h: false,   // has habitable planets to survey
                n: false,   // has non-habitable planets/moons to survey
                a: false    // has asteroids to survey
            };
        };

        function hasStarSystemChanged (changes) {
            return changes.starSystem && changes.starSystem.currentValue;
        };

        ssc.changeStarSystemId = function (ssid) {
            $timeout(function () {
                UtilitySvc.scrollToTop(50);
            });
            return ssc.updateStarSystemId({ssid: ssid});
        };

        function identifySystemSurveyElements (starSystem) {
            if (starSystem.stars && starSystem.stars.length > 0) {
                ssc.primarySurveyElements = identifyStarSurveyElements(starSystem.stars[0]);
            }
            else {
                ssc.primarySurveyElements = baseSurveyElements();
            }
            if (starSystem.stars && starSystem.stars.length == 2) {
                ssc.secondarySurveyElements = identifyStarSurveyElements(starSystem.stars[1]);
            }
            else {
                ssc.secondarySurveyElements = baseSurveyElements();
            }
        };

        function identifyStarSurveyElements (star) {
            let surveyElements = baseSurveyElements();
            if (!star) {
                return surveyElements;
            }
            for (const planet of star.planets) {
                if (surveyElements.h && surveyElements.n && surveyElements.a) {
                    break;
                }
                if (!surveyElements.a && planet.type === 'A') {
                    surveyElements.a = true;
                    continue;
                }
                if (!surveyElements.h && planet.hi > 0 && planet.hi <= 10) {
                    surveyElements.h = true;
                    continue;
                }
                if (!surveyElements.n && (planet.hi > 10 || (planet.moons && planet.moons.length > 0))) {
                    surveyElements.n = true;
                    continue;
                }
            }
            console.log('controller: ssc: ', ssc);
            return surveyElements;
        };
    }]
});
