/* globals angular localStorage */
angular.module('app').controller('ConstructionExpenseCtrl', [
    '$timeout', '$route', '$ismModal', 'DataStoreSvc', 'EconomySvc', 'ShipSvc', 'FleetSvc', 'ShipClassSvc',
    function ($timeout, $route, $ismModal, DataStoreSvc, EconomySvc, ShipSvc, FleetSvc, ShipClassSvc) {
        'use strict';

        var cec = this;
        var localData = {
            allShipYardKeys: {},
            nextShipYardKeys: {}
        };
        cec.pageData = localData;
        cec.session = DataStoreSvc;

        var init = function () {
            cec.session.iAmBusy();
            localData.editNextLevel = cec.session.user.role === 'Master' || cec.session.user.role === 'Marshal';
            localData.race = cec.session.getRace();
            localData.hullsPerMonth = 10 + (2 * localData.race.techLevel);

            console.log('ConstructionExpenseCtrl', cec);

            let promises = [
                EconomySvc.getConstructionData(localData.race._id).then(processData)
            ];

            promises.push(
                ShipSvc.getShipYardsForRace(localData.race).then(
                    function(shipData) {
                        localData.ships = shipData.reduce(
                            function (ships, ship) {
                                ships[ship._id] = ship;
                                return ships;
                            }, {}
                        )
                    }
                )
            );

            promises.push(
                FleetSvc.getFleetsForRace(localData.race).then(
                    function(fleets) {
                        localData.fleets = fleets;
                        localData.fleetHash = fleets.reduce(
                            function (hash, fleet) {
                                hash[fleet._id] = fleet;
                                return hash;
                            }, {}
                        );
                    }
                )
            );

            promises.push(
                ShipClassSvc.getShipClassesForRace(localData.race).then(
                    function(shipClasses) {
                        localData.shipClasses = shipClasses;
                        localData.shipClassHash = shipClasses.reduce(
                            function (hash, shipClass) {
                                hash[shipClass._id] = shipClass;
                                return hash;
                            }, {}
                        );
                    }
                )
            );

            return Promise.all(promises).then(
                function(results) {
                    $timeout( function () {
                        cec.session.iAmNotBusy();
                    });
                }
            );
        };

        let processData = function (result) {
            localData.hashEconomicData = result;
            localData.allShipYardKeys = {};
            localData.totalData = 0;
            let editTurn = cec.session.editTurn;
            let lastTurnComplete = cec.session.race.economicsCompleteTurn;
            let allKeys = Object.keys(result);
            for (const key of allKeys) {
                // console.log('0 - key: %s', key);
                let [startTurn, shipYardId, shipYardNumber, sequence] = key.split('-');
                // console.log('0.1 - startTurn: %s, shipYardId: %s, shipYardNumber: %s, sequence: %s', startTurn, shipYardId, shipYardNumber, sequence);
                let shipYardKey = shipYardId + '-' + shipYardNumber;
                let nextShipYardKey_base = editTurn + '-' + shipYardId + '-' + shipYardNumber + '-';
                let transaction = localData.hashEconomicData[key];
                let nextSequence = parseInt(sequence) + transaction.size - ((editTurn - Math.max(lastTurnComplete,startTurn)) * localData.hullsPerMonth);
                let shipYardKeys = localData.allShipYardKeys[shipYardKey] || [];
                // console.log('0.9 - nextSequence: %d', nextSequence);

                if (startTurn < editTurn) {
                    if (nextSequence > 1) {
                        shipYardKeys[0] = key;
                    }
                    if (nextSequence < 1) {
                        nextSequence = 1;
                    }
                    // console.log('1 - startTurn: %s, editTurn: %d, nextSequence: %d', startTurn, editTurn, nextSequence);
                }
                else if (startTurn == editTurn && sequence == 1) {
                    shipYardKeys[0] = key;
                    localData.totalData += transaction.amount;
                    nextSequence = transaction.sequence + transaction.size;
                    // console.log('2 - startTurn: %s, editTurn: %d, nextSequence: %d', startTurn, editTurn, nextSequence);
                }
                else if (startTurn == editTurn) {
                    shipYardKeys.push(key);
                    localData.totalData += transaction.amount;
                    nextSequence = transaction.sequence + transaction.size;
                    // console.log('3 - startTurn: %s, editTurn: %d, nextSequence: %d', startTurn, editTurn, nextSequence);
                }
                else {
                    //this is the top limiter - need the lowest of these...
                    nextSequence = 99;
                }

                localData.allShipYardKeys[shipYardKey] = shipYardKeys;

                if (nextSequence <= 0) {
                    nextSequence = 1;
                }
                if (nextSequence <= localData.hullsPerMonth) {
                    localData.nextShipYardKeys[shipYardKey] = nextShipYardKey_base + nextSequence;
                    // console.log('4 - nextShipYardKey: %s', localData.nextShipYardKeys[shipYardKey]);
                }
                else {
                    localData.nextShipYardKeys[shipYardKey] = 'notyet'
                }
                // console.log('5 - nextSequence: %d', nextSequence);
            }
        };

        cec.animationsEnabled = true;

        let createEconomicData = function (startTurn, id, syId, sequence) {
            return {
                campaignId: DataStoreSvc.getCampaign()._id,
                raceId: DataStoreSvc.getRace()._id,
                turn: startTurn,
                type: 'construction',
                quantity: 1,
                locator: id + '-' + syId,
                sequence: sequence
            };
        };

        cec.newOrEdit = function (key) {
            let [startTurn, shipYardId, shipYardNumber, sequence] = key.split('-');
            let shipYardFleet = localData.fleets.find(
                function (fleet) {
                    return fleet.shipIds.indexOf(shipYardId) > -1;
                }
            );

            localData.filteredFleets = localData.fleets.filter(
                function (fleet) {
                    return ((fleet.starSystemId === shipYardFleet.starSystemId) && (fleet.locationHex === shipYardFleet.locationHex));
                }
            );

            localData.selectedEconomicData = localData.hashEconomicData[key];
            if (!localData.selectedEconomicData) {
                localData.economicDataToEdit = createEconomicData(startTurn, shipYardId, shipYardNumber, sequence);
            }
            else {
                localData.economicDataToEdit = angular.copy(localData.selectedEconomicData);
            }
            cec.open();
        };

        cec.processSavedData = function (savedEconomicData) {
            let key = savedEconomicData.turn + '-' + savedEconomicData.locator + '-' + savedEconomicData.sequence;
            localData.hashEconomicData[key] = savedEconomicData;
            $route.reload();
        };

        cec.delete = function (key) {
            cec.session.iAmBusy();
            let economicData = localData.hashEconomicData[key];
            return EconomySvc.deleteTransaction(economicData).then(
                function (result) {
                    cec.processDeletedData(key);
                }
            );
        };

        cec.processDeletedData = function (key) {
            delete localData.hashEconomicData[key];
            $route.reload();
        };

        cec.open = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.construction ' + parentSelector)) : undefined;

            var modalInstance = $ismModal.open({
                animation: cec.animationsEnabled,
                templateUrl: 'templates/constructionEdit.html',
                controller: 'ConstructionEdit',
                controllerAs: 'ceec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    economicDataToEdit: function () {
                        return localData.economicDataToEdit;
                    },
                    shipClasses: function () {
                        return localData.shipClasses;
                    },
                    fleets: function () {
                        return localData.filteredFleets;
                    },
                    shipYardInfo: function () {
                        let [shipId, shipYardNumber] = localData.economicDataToEdit.locator.split('-');
                        let ship = localData.ships[shipId];
                        return {
                            ship: ship,
                            shipYardNumber: shipYardNumber
                        }
                    }
                }
            });

            modalInstance.result.then(
                function (economicData) {
                    cec.session.iAmBusy();
                    return EconomySvc.saveTransaction(economicData);
                }
            ).then(
                function( savedEconomicData ) {
                    cec.processSavedData( savedEconomicData );
                    $timeout( function () {
                        cec.session.iAmNotBusy();
                    });
                }
            ).catch(
                function (err) {
                    console.log('err: ' + err.stack);
                }
            );
        };

        init();
    }
]).controller('ConstructionEdit', function ($ismModalInstance, economicDataToEdit, shipClasses, fleets, shipYardInfo) {
    var ceec = this;

    ceec.economicDataToEdit = economicDataToEdit;
    ceec.shipClasses = shipClasses;
    ceec.fleets = fleets;
    ceec.shipYardInfo = shipYardInfo;

    var init = function() {
        if (ceec.economicDataToEdit.shipClassId) {
            ceec.shipClass = ceec.shipClasses.find(
                function (shipClass) {
                    return shipClass._id === ceec.economicDataToEdit.shipClassId;
                }
            );
            ceec.disabled = (ceec.economicDataToEdit.size < ceec.shipClass.actualSize);
        }
    };

    ceec.setShipClass = function () {
        ceec.economicDataToEdit.shipClassId = ceec.shipClass._id;
        ceec.economicDataToEdit.amount = ceec.shipClass.buildCost;
        ceec.economicDataToEdit.size = ceec.shipClass.actualSize;
    };

    ceec.ok = function () {
      $ismModalInstance.close(ceec.economicDataToEdit);
    };
  
    ceec.cancel = function () {
      $ismModalInstance.dismiss('cancel');
    };

    init();
});
