angular.module('app').controller('EncounterManagerCtrl', [
    '$route', '$location', '$anchorScroll', '$timeout', 'EncounterSvc', 'RaceSvc', 'StarSystemSvc', '$q', 'DataStoreSvc', '$ismModal',
    function ($route, $location, $anchorScroll, $timeout, EncounterSvc, RaceSvc, StarSystemSvc, $q, DataStoreSvc, $ismModal) {
        'use strict';

        var pemc = this;
        var localData = {
            animationsEnabled: true
        };

        pemc.encounterData = localData;
        pemc.session = DataStoreSvc;

        var init = function () {
            pemc.session.iAmBusy()

            let p1 = EncounterSvc.getEncountersForCampaign(DataStoreSvc.getCampaign()).then(function (encounters) {
                localData.hashEncounters = {};
                for (var i = 0; i < encounters.length; i++) {
                    var encounter = encounters[i];
                    localData.hashEncounters[encounter._id] = encounter;
                }
            });

            localData.allRaces = JSON.parse(localStorage.getItem('races'));
            let p2;
            if (!localData.allRaces) {
                p2 = RaceSvc.getAllRacesForCampaign(DataStoreSvc.campaign).then(
                    function (results) {
                        localData.allRaces = results;
                        localData.hashRaces = localData.allRaces.reduce((hash,race)=>(hash[race._id] = race, hash), {})
                        console.log("localData.hashRaces:",localData.hashRaces);
                    }
                );
            }
            else {
                localData.hashRaces = localData.allRaces.reduce((hash,race)=>(hash[race._id] = race, hash), {})
                console.log("localData.hashRaces:",localData.hashRaces);
                p2 = Promise.resolve(true);
            }

            let p3 = StarSystemSvc.getAllStarSystems(DataStoreSvc.campaign).then(
                function (results) {
                    localData.allStarSystems = results;
                    localData.hashStarSystems = results.reduce((hash,starSystem)=>(hash[starSystem._id] = starSystem, hash), {})
                }
            );

            Promise.all([p1, p2, p3]).then(
                function (results) {
                    $timeout(
                        function (results) {
                            pemc.session.iAmNotBusy();
                            console.log('pemc: ', pemc);
                        }
                    );
                }
            );
        };

        pemc.createEncounter = function () {
            localData.encounterToEdit = {
                campaignId: DataStoreSvc.getCampaign()._id,
                turn: DataStoreSvc.getCampaign().turn || 0,
                starSystemId: null,
                systemHex: '0101',
                interceptionHex: '0101',
                scale: 'System',
                status: 'New'
            };
            pemc.open();
        };

        pemc.editEncounter = function (encounter) {
            localData.encounterToEdit = angular.copy(encounter);
            pemc.open('lg');
        };

        pemc.deleteEncounter = function (encounter) {
            pemc.session.iAmBusy();
            return EncounterSvc.deleteEncounter(encounter).then(
                function (result) {
                    if (result.code === 0) {
                        delete localData.hashEncounters[encounter._id];
                        $route.reload();
                    }
                    else if (result.code === 1) {
                        localData.alertMessage = result.message;
                    }
                    else {
                        localData.alertMessage = result.message;
                    }
                    console.log('Reset message: ' + result.message);
                    $timeout( function () {
                        pemc.session.iAmNotBusy();
                    });
                }
            )
            .catch(
                function (result) {
                    let error = result.data;
                    console.error('Error in deleteEncounter: ', error.message);
                    let alertMessage = error.message || "Error! Check console.";
                    pemc.scrollToId(encounter._id, alertMessage);
                }
            );
        };

        pemc.resolveEncounter = function (encounter) {
            pemc.session.selectedEncounter = encounter;
            $location.path("/encounterResolution");
        };

        pemc.testEncounter = function (encounter) {
            pemc.session.selectedEncounter = encounter;
            $location.path("/encounterTest");
        };

        pemc.resetEncounter = function (encounter) {
            pemc.session.iAmBusy();
            return EncounterSvc.resetEncounter(encounter).then(
                pemc.processSavedEncounter
            ).then(
                function () {
                    $timeout( function () {
                        pemc.session.iAmNotBusy();
                        pemc.scrollToId(encounter._id);
                    });
                }
            ).catch(
                function (result) {
                    let error = result.data;
                    console.error('Error in resetEncounter: ', error);
                    pemc.scrollToId(encounter._id, error);
                }
            );
        };

        pemc.scrollToId = function (encounterId, message) {
            localData.alertMessage = message;
            $location.hash(encounterId);
            $anchorScroll();
        };

        pemc.processSavedEncounter = function (savedEncounter) {
            localData.hashEncounters[savedEncounter._id] = savedEncounter;
            return "Done";
        };

        pemc.open = function (size, parentSelector) {
            var parentElem = parentSelector ? 
                angular.element($document[0].querySelector('.encounter ' + parentSelector)) : undefined;

            var modalInstance = $ismModal.open({
                animation: localData.animationsEnabled,
                templateUrl: 'templates/encounterEdit.html',
                controller: 'EncounterEdit',
                controllerAs: 'eec',
                size: size,
                appendTo: parentElem,
                resolve: {
                    encounterToEdit: function () {
                        return localData.encounterToEdit;
                    },
                    allRaces: function () {
                        return localData.allRaces;
                    },
                    allStarSystems: function () {
                        return localData.allStarSystems;
                    }
                }
            });

            modalInstance.result.then(
                function (encounter) {
                    if (encounter._id == null) {
                        return EncounterSvc.saveEncounter(encounter);
                    } else if (encounter._id <= 10) {
                        return $q.when(encounter);
                    } else {
                        return EncounterSvc.saveEncounter(encounter);
                    }
                }
            ).then(
                function( savedEncounter ) {
                    pemc.processSavedEncounter( savedEncounter );
                    pemc.scrollToId(savedEncounter._id);
                }
            ).catch(
                function () {
                    // console.log('Modal dismissed at: ' + new Date());
                }
            );
        };

        init();
    }
]).controller('EncounterEdit', [
    '$scope', 'DataStoreSvc', 'EncounterSvc', 'FleetSvc', '$ismModalInstance', 'encounterToEdit', 'allRaces', 'allStarSystems',
    function ($scope, DataStoreSvc, EncounterSvc, FleetSvc, $ismModalInstance, encounterToEdit, allRaces, allStarSystems) {
        var eec = this;
        var localData = {
            fleets: []
        };
        eec.encounterEditData = localData;
        eec.encounterToEdit = encounterToEdit;

        eec.raceIds = function () {
            var raceIds = (eec.encounterToEdit.raceEncounters || []).map(
                function(re) {
                    return re.raceId;
                }
            );
            return raceIds;
        };

        var getFleetsForRaceId = function (raceId) {
            return FleetSvc.getFleetsForRaceId(raceId).then(
                function (fleets) {
                    return fleets;
                }
            );
        };

        var init = function() {
            localData.allRaces = allRaces;
            localData.allStarSystems = allStarSystems;
            EncounterSvc.getEncounterScales().then(
                function (results) {
                    localData.scales = results;
                }
            );
            EncounterSvc.getEncounterStates().then(
                function (results) {
                    localData.states = results;
                }
            );
            EncounterSvc.getRaceEncounterStates().then(
                function (results) {
                    localData.raceStates = results;
                }
            );
            var raceIds = eec.raceIds();
            raceIds.forEach(function (raceId) {
                if (raceId != null) {
                    getFleetsForRaceId(raceId).then(
                        function (fleets) {
                            localData.fleets[raceId] = fleets;
                        }
                    );
                }
            });
        };

        $scope.$watch(
            // what am I watching? - the race
            eec.raceIds,
            function(newValue, oldValue) {
                var keys = Object.keys(localData.fleets || []);
                (newValue || []).forEach(function (raceId) {
                    if (raceId != null && (oldValue || []).indexOf(raceId) === -1) {
                        // console.log('new raceId: ', raceId);
                        getFleetsForRaceId(raceId).then(
                            function (fleets) {
                                localData.fleets[raceId] = fleets;
                            }
                        );
                    }
                });
            },
            true
        );

        eec.newRaceEncounter = function () {
            return {
                pulseStrategic: 0,
                pulseSystem: 0,
                pulseInterception: 0,
                turnTactical: 0,
                pulseTactical: 0,
                edge: 1,
                active: 0,
                initiative: 1,
                status: "New"
            };
        };

        eec.addRaceEncounter = function () {
            eec.encounterToEdit.raceEncounters = eec.encounterToEdit.raceEncounters || [];
            eec.encounterToEdit.raceEncounters.push(eec.newRaceEncounter()); 
        };

        eec.deleteRaceEncounter = function ($index) {
            eec.encounterToEdit.raceEncounters.splice($index, 1);
        };

        eec.ok = function () {
            $ismModalInstance.close(eec.encounterToEdit);
        };
    
        eec.cancel = function () {
            $ismModalInstance.dismiss('cancel');
        };

        init();
    }
]);
